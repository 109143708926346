import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { Paper, Tabs, Tab } from "@material-ui/core";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { getClassSiteList } from "../../Settings/_redux/franchises/franchisesCrud";
import { newOrderCollectionValue } from "../orderUtils";
import { ContainerModalCompoent } from "../NewOrder/components/ContainerModalCompoent";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import {
  collectionExtraChage,
  containerDateUpdate,
  containerExtraChage,
  extraMiles,
  getAddress,
} from "../_redux/order/orderCrud";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";
import { useIntl } from "react-intl";
import {
  calculateExtraPrice,
  convertKilometersToMiles,
  getInputClasses,
} from "../../../utils/commonFunction";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
  capitalizeFirstLetter,
  divideByHundred,
} from "../../../utils/canvaCloseFunction";
import CustomeOrderSaveDialog from "../../../../_metronic/_partials/widgets/alert/CustomeOrderSaveDialog";
import { fetchMyOrderDetailCard } from "../_redux/order/orderAction";
import { useDispatch } from "react-redux";

export function OrderContainerCollectionModal({
  dispatchReducer,
  state,
  show,
  setShow,
  initialValues,
  franchise,
  keyC,
  check,
  orderDetail,
}) {
  const intl = useIntl();
  const [value, setValue] = React.useState({});
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [dropDown, setDropDown] = useState({ address: [] });
  const [dialogSave, setDialogSave] = useState({ type: false, discard: false });
  const [returnData, setReturnData] = useState(null);
  const [franchiseContainer, setFranchiseContainer] = useState(null);

  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClassList = async (id) => {
    await getClassSiteList(id).then((res) => {
      dispatchReducer({ type: "classSiteList", value: res.data.data });
    });
  };
  useEffect(() => {
    if (state?.siteSelect?.id) {
      handleClassList(state?.siteSelect?.id);
    }
  }, [state?.siteSelect?.id]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    if (keyC === "moveOut" && formik.values?.return_date === "") {
      setSnack({
        call: true,
        type: "error",
        message: "Select Date",
      });
      setIsLoading(false);
      return;
    }

    // console.log("check-82", keyC);
    // if (keyC === "moveIn" && franchiseContainer.length) {
    //   const filteredAvailData = franchiseContainer.slice(0, +orderDetail?.pod);
    //   values.franchise_container_id = filteredAvailData.map((e) => e.id);
    // }

    if (keyC === "moveOut" && selectedItems.pod_price) {
      values.is_return_cost = 1;
      values.pod_price = value.pod_price;
    } else if (keyC === "return" && selectedItems.extra_return_fee_price) {
      values.is_return_cost = 1;
    }
    if (keyC === "return" && selectedItems?.is_extra_miles) {
      values.is_extra_miles = 1;
      values.extra_miles_id = returnData?.extraMiles?.id;
      values.extra_miles_quantity = returnData?.extraMiles?.qty;
      values.extra_miles_charge = returnData?.extraMiles?.totalPrice;
    }

    containerDateUpdate(values)
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(values?.order_id));
        setTimeout(() => {
          setShow(false);
        }, 2000);
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message || "",
        });
      });
    // if (collectionData?.collection_date === "") {
    //   setSnack({
    //     call: true,
    //     type: "error",
    //     message: "Please Select Move In Date",
    //   });
    //   return;
    // }
    // dispatchReducer({
    //   type: "collectionRetrun",
    //   value: collectionData,
    // });
  };
  useEffect(() => {
    if (snack.call) {
      setTimeout(() => {
        setSnack({ call: false, type: "", message: "" });
      }, 4000);
    }
  }, [snack.call]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });
  const handleManualUpdate = () => {
    formik.setFieldValue("is_manual", 1);
  };
  useEffect(() => {
    if (
      (keyC === "return" && formik?.values?.container_return_date) ||
      (keyC === "moveOut" && formik?.values?.return_date)
    ) {
      const data = {
        id: orderDetail?.id || "",
        type: keyC === "moveOut" ? "move_out" : "return",
        is_student_return: 0,
        return_date:
          keyC === "moveOut"
            ? formik?.values?.return_date
            : formik?.values?.container_return_date || "",
        container_size: orderDetail?.container_size,
      };
      containerExtraChage(data).then((res) => {
        setValue(res.data.data);
      });
    }
  }, [
    keyC,
    formik?.values?.return_date,
    formik?.values?.container_return_date,
  ]);

  const handleCheckboxChange = (itemName) => {
    setSelectedItems({
      ...selectedItems,
      [itemName]: !selectedItems[itemName],
    });
  };

  const getTotalPrice = () => {
    let total = 0;
    for (const itemName in selectedItems) {
      if (selectedItems[itemName]) {
        total += value[itemName];
      }
    }
    console.log("total", +total);

    return Number(total); // Round to 2 decimal places
  };

  const handleGetAddress = async (return_postcode, check) => {
    if (!return_postcode) return;
    formik.setFieldValue("is_address_manual", "0");
    getAddress(return_postcode)
      .then((res) => {
        setDropDown({ address: res.data.addresses });
        formik.setFieldValue("city", res.data.addresses[0].town_or_city);
        if (check !== "onMount") {
          setSnack({
            call: true,
            type: "success",
            message: "Postcode found successfully",
          });
        }
      })
      .catch((error) => {
        formik.setFieldValue("street1", "");
        formik.setFieldValue("city", "");
        setSnack({
          call: true,
          type: "error",
          message: "Cannot find Post Code",
        });
      });
  };
  useEffect(() => {
    if (
      formik?.values?.return_postcode &&
      formik.values?.is_address_manual !== 1
    ) {
      handleGetAddress(formik?.values?.return_postcode, "onMount");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.return_postcode]);
  const handleResponse = (response) => {
    setIsLoading(true);
    if (
      orderDetail?.is_return === 1 ||
      formik?.values?.is_student_return === 1
    ) {
      setIsLoading(false);
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin:
          orderDetail?.collection_address_line +
          "," +
          orderDetail?.collection_address_city +
          "," +
          orderDetail?.collection_postcode,
        destination:
          formik?.values?.return_address_line +
          "," +
          formik?.values?.return_address_city +
          "," +
          formik?.values?.return_postcode,
        travelMode: window.google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(Date.now()), // for the time N milliseconds from now.
          trafficModel: "pessimistic",
        },
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          extraMiles(orderDetail?.franchise_id).then((res) => {
            const response = res.data.data;
            console.log("response", response);

            const price = divideByHundred(
              response?.product_prices[0]?.unit_amount
            );
            let miles =
              (result?.routes.length > -1 &&
                result?.routes[0]?.legs.length > -1 &&
                convertKilometersToMiles(
                  result?.routes[0]?.legs[0]?.distance.text
                ) - 10) ||
              "-";
            let actualMiles = +miles > 0 ? +miles : 0;
            let Totalprice = calculateExtraPrice(
              Math.round(actualMiles),
              price
            );
            setReturnData((prevReturnData) => ({
              ...prevReturnData,
              extraMiles: {
                id: response.id,
                totalPrice:
                  orderDetail?.booking_reference === "254-060223-47721"
                    ? "60"
                    : Totalprice,
                qty: Math.round(miles),
              },
            }));
            setValue({ ...value, is_extra_miles: Totalprice });
            setIsLoading(false);
          });
        } else {
          console.error(`error fetching directions ${result}`);
          setIsLoading(false);
        }
      }
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
        contentClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <Paper square>
              <div className="row" style={{ padding: "12px" }}>
                <div className="col-12">
                  <ContainerModalCompoent
                    formik={formik}
                    keyC={keyC}
                    check={check}
                    orderDetail={orderDetail}
                    setFranchiseContainer={setFranchiseContainer}
                  />
                </div>

                {keyC === "return" && (
                  <div
                    key={`custom`}
                    className=" col-12 checkbox mb-3"
                    style={{ padding: "2%" }}
                  >
                    <Form.Check
                      className="mb-2"
                      custom
                      name="Checkboxes4"
                      checked={
                        keyC === "return" &&
                        formik?.values?.is_return_address == "0"
                          ? true
                          : false
                      }
                      type="checkbox"
                      id={`custom`}
                      label={`${capitalizeFirstLetter(
                        keyC
                      )} address same as ${"Collection"} address`}
                      onClick={() => {
                        if (formik.values.is_return_address !== 1) {
                          formik.setValues({
                            ...formik?.values,
                            is_return_address: 1,
                            return_postcode:
                              orderDetail?.collection_postcode || "",
                            return_address_line:
                              orderDetail?.collection_address_line || "",
                            return_address_city:
                              orderDetail?.collection_address_city,
                          });
                        } else {
                          formik.setValues({
                            ...formik?.values,
                            is_return_address: "0",
                          });
                        }
                      }}
                    />
                  </div>
                )}
                {formik.values.is_return_address == 1 && keyC === "return" && (
                  <div className="row col-12" style={{ padding: "2%" }}>
                    <div className="col-md-12 mb-5">
                      <label>{intl.formatMessage({ id: "Post.Code" })}</label>
                      <div className="row align-items-center">
                        <div className="col-6 d-flex justify-content-between align-items-center ">
                          <div className="form-groupB w-100">
                            <input
                              type="text"
                              className={`form-control  ${getInputClasses(
                                formik,
                                "return_postcode"
                              )}`}
                              placeholder={intl.formatMessage({
                                id: "Post.Code",
                              })}
                              name="return_postcode"
                              value={formik.values?.return_postcode}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                // Remove any non-alphanumeric characters from the input value
                                const alphanumericValue = inputValue.replace(
                                  /\W/g,
                                  ""
                                );

                                // Use regex to format the input value as a UK return_postcode
                                const formattedValue = alphanumericValue.replace(
                                  /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i,
                                  "$1 $2"
                                );
                                formik.setFieldValue(
                                  "return_postcode",
                                  formattedValue
                                );
                              }}
                              // {...formik.getFieldProps("return_postcode")}
                            />
                          </div>
                          <div className="mb-2">
                            <span className="symbol-label ml-2">
                              <span
                                className="svg-icon svg-icon-lg svg-icon-primary"
                                onClick={() =>
                                  handleGetAddress(
                                    formik.values.return_postcode
                                  )
                                }
                              >
                                <SVG
                                  className="h-25 align-self-end"
                                  style={{ cursor: "pointer" }}
                                  title=" "
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Search.svg"
                                  )}
                                ></SVG>
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2 123">
                          <span
                            style={{
                              color: "#ff9f60",
                              cursor: "pointer",
                            }}
                            onClick={handleManualUpdate}
                          >
                            {intl.formatMessage({ id: "Enter.Manually" })}
                          </span>
                        </div>
                        {formik.touched.return_postcode &&
                        formik.errors.return_postcode ? (
                          <div className=" ml-4 mb-4 fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik?.errors?.return_postcode}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <label>Address</label>
                      <div className="form-groupB">
                        {formik.values.is_manual === 1 ? (
                          <input
                            type="text"
                            className={`form-control  ${getInputClasses(
                              formik,
                              "return_address_line"
                            )}`}
                            placeholder="address"
                            name="return_address_line"
                            {...formik.getFieldProps("return_address_line")}
                          />
                        ) : (
                          <FormControl fullWidth>
                            <Select
                              name="return_address_line"
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              className={`form-control  ${getInputClasses(
                                formik,
                                "return_address_line"
                              )}`}
                              IconComponent={(props) => (
                                <i {...props}>
                                  <KeyboardArrowDownIcon />
                                </i>
                              )}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              placeholder="Address"
                              displayEmpty
                              {...formik.getFieldProps("return_address_line")}
                            >
                              <MenuItem value="">
                                {intl.formatMessage({
                                  id: "return_address_line",
                                })}
                              </MenuItem>
                              {dropDown?.address && dropDown?.address.length > 0
                                ? dropDown?.address.map((data, index) => {
                                    let addressData = data?.formatted_address.toString();
                                    addressData = addressData?.replaceAll(
                                      ", ",
                                      ","
                                    );
                                    addressData = addressData?.replaceAll(
                                      ",, ",
                                      ","
                                    );
                                    addressData = addressData?.replaceAll(
                                      ",,,",
                                      ","
                                    );

                                    return (
                                      <MenuItem value={addressData} key={index}>
                                        {addressData}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                            </Select>
                          </FormControl>
                        )}

                        {formik.touched.return_address_line &&
                        formik.errors.return_address_line ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.return_address_line}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <label>{intl.formatMessage({ id: "CITY" })}</label>
                      <div className="form-groupB">
                        <input
                          type="text"
                          className={`form-control  ${getInputClasses(
                            formik,
                            "return_address_city"
                          )}`}
                          placeholder={intl.formatMessage({ id: "CITY" })}
                          name="return_address_city"
                          {...formik.getFieldProps("return_address_city")}
                        />
                        {formik.touched.return_address_city &&
                        formik.errors.return_address_city ? (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.return_address_city}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {orderDetail?.is_return != 1 && (
                      <div className="col-12">
                        <Button
                          variant="primary"
                          type="button"
                          onClick={handleResponse}
                        >
                          Get Extra Miles
                          {isLoading && (
                            <span className="ml-2 mr-2 spinner spinner-white"></span>
                          )}
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                <br />

                {keyC === "moveOut" &&
                orderDetail?.return_date &&
                formik.values.return_date &&
                new Date(orderDetail?.return_date).getTime() <
                  new Date(formik.values.return_date).getTime() ? (
                  <>
                    <div className="d-flex col-12">
                      <div>
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            checked={
                              formik.values.is_penalty_charge == 1
                                ? true
                                : false
                            }
                            onChange={() =>
                              formik.setFieldValue(
                                "is_penalty_charge",
                                formik.values.is_penalty_charge == 1 ? 0 : 1
                              )
                            }
                          />
                          <span class="mr-2"></span>
                        </label>
                      </div>
                      <label>Apply Penalty Charge</label>
                    </div>
                    {formik.values.is_penalty_charge == 1 && (
                      <div className="col-12">
                        <label>Penalty(&pound;)</label>
                        <div className="form-groupB">
                          <input
                            type="text"
                            className={`form-control  ${getInputClasses(
                              formik,
                              "penalty_charge"
                            )}`}
                            placeholder={intl.formatMessage({
                              id: "penalty_charge",
                            })}
                            name="penalty_charge"
                            {...formik.getFieldProps("penalty_charge")}
                          />
                          {formik.touched.penalty_charge &&
                          formik.errors.penalty_charge ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.penalty_charge}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {(keyC === "return" || keyC === "moveOut") && (
                  <div style={{ padding: "2%" }}>
                    {Object.keys(value).map((itemName, index) => {
                      if (itemName === "invoice_duration_line_items") {
                        return;
                      }

                      return (
                        <div key={index} className="d-flex">
                          <div>
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                checked={selectedItems[itemName]}
                                onChange={() => handleCheckboxChange(itemName)}
                              />
                              <span class="mr-2"></span>
                            </label>
                          </div>
                          <label>
                            {itemName === "pod_price"
                              ? "Storage Cost"
                              : itemName === "extra_return_fee_price"
                              ? "Return Fee"
                              : itemName === "is_extra_miles"
                              ? "Extra miles"
                              : itemName}
                            : &pound; {Number(value[itemName]).toFixed(2)}
                          </label>
                        </div>
                      );
                    })}
                    <div>Total: &pound;{getTotalPrice()}</div>
                  </div>
                )}
              </div>
            </Paper>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => setDialogSave({ type: true })}
          >
            Save Changes{" "}
            {isLoading && (
              <span className="ml-2 mr-2 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Footer>
        {snack.call ? <SnackBarTool {...snack} /> : ""}
        <CustomeOrderSaveDialog
          header={intl.formatMessage({ id: "Save.Changes" })}
          mainText={intl.formatMessage({ id: "Do.you.changes.booking" })}
          dialog={dialogSave}
          setDialog={setDialogSave}
          type={"order"}
          formik={formik}
          check={keyC === "return" || keyC === "moveOut" ? "order" : ""}
          showCheck={"order"}
          dis={"no"}
          dialogConditionCheck={true}
        />
      </Modal>
    </>
  );
}
