import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { EditOrderTableAction } from "../components/EditOrderComponents/EditOrderTableAction";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { EditOrderTableButtons } from "../components/EditOrderComponents/EditOrderTableButtons";
import BillingCycleEditOrderModal from "../components/EditOrderComponents/Modal/BillingCycleEditOrderModal";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MenuItem, Select } from "@material-ui/core";
import ChangePlanModal from "../components/EditOrderComponents/Modal/ChangePlanModal";
import { InputGroup } from "react-bootstrap";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  calculatePriceAccToProduct,
  calculatePriceAccToProductNon,
} from "./EditOrderReleaseUtils";
import { EditOrderTableReleaseButtons } from "./EditOrderTableReleaseButtons";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner";

export const EditOrderReleaseList = ({
  editOrderDetail,
  orderUIContext,
  state,
  dispatchReducer,
  phases,
  setCallUpComing,
  orderSummaryListStripe,
}) => {
  const [dialog, setDialog] = useState({ check: false });
  const handleUnitPrice = (value, index, check) => {
    console.log("value-123", value, check, state);

    // Create a shallow copy of the items array
    // const arr = phase.items.map((item, i) =>
    //   i === index ? { ...item, unit_price: +value } : item
    // );
    const arr = [...state.items.data];
    arr[index].price.unit_amount = +value;
    // Update the state immutably
    console.log("arr", arr);

    if (check) {
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          insurance_price: value,
        },
      });
      return;
    }
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        items: { ...state.items, data: arr },
      },
    });
  };

  // const savePrice = async () => {
  //   try {
  //     const price = await calculatePriceAccToProduct(
  //       orderSummaryListStripe?.allProducts,
  //       process.env.REACT_APP_PRODUCT_FIRST_CLASS,
  //       12
  //     );
  //     console.log("price", price); // Use the price variable as needed
  //     // You can now use `price` for further logic
  //   } catch (error) {
  //     console.error("Error calculating price:", error);
  //   }
  // };

  // savePrice();

  const handleInsurance = (targetValue) => {
    const podProtect =
      orderSummaryListStripe &&
      orderSummaryListStripe.allProducts &&
      orderSummaryListStripe.allProducts.find(
        (e) => e.stripe_product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT
      );
    const unitAmount = podProtect && podProtect.product_prices[0].unit_amount;
    const value = targetValue / 1000;
    const price = unitAmount * value;
    console.log("kholi-0", price);

    const stateData = JSON.parse(JSON.stringify(state));
    const updatedItems = state.items.data.map((e) => {
      if (e.plan.product === process.env.REACT_APP_PRODUCT_POD_PROTECT) {
        // Update the price.unit_price only for the matching item
        return {
          ...e,
          price: {
            ...e.price,
            unit_amount: price,
          },
        };
      }
      return e; // Return the original item if it doesn't match
    });
    console.log("item-90", updatedItems);

    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        items: { ...state.items, data: updatedItems },
        insurance_cover: targetValue,
        insurance_price: price,
        is_call_upcoming: true,
      },
    });
  };

  const handleUpdateQuantity = (value, index, productId, priceDefault) => {
    const price = calculatePriceAccToProduct(
      orderSummaryListStripe?.allProducts,
      productId,
      value,
      priceDefault
    );
    // const diviPrice = price/value
    const actualPrice = price / value;

    // Create a shallow copy of the items array
    // const arr = phase.items.map((item, i) =>
    //   i === index
    //     ? { ...item, quantity: +value, unit_price: +actualPrice.toFixed(2) }
    //     : item
    // );
    const arr = [...state.items.data];
    arr[index].price.unit_amount = +actualPrice.toFixed(2);
    arr[index].quantity = +value;

    // Update the state immutably
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        items: { ...state.items, data: arr },
      },
    });
  };
  const items = Array.from({ length: 51 }, (_, index) => index * 1000);
  const handleCouponRemoval = (phases, id, index) => {
    // Map through each phase
    // const updatedPhases = phases.map((phase) => {
    //   if (phase.is_edit) {
    //     // Filter out the discount where the coupon matches the provided id
    //     const updatedDiscounts = phase.discounts.filter(
    //       (discount) => discount.coupon !== id
    //     );
    //     // Return the updated phase with the filtered discounts
    //     return {
    //       ...phase,
    //       discounts: updatedDiscounts,
    //     };
    //   }
    //   return phase;
    // });
    const arr = [...state.discounts];
    arr.splice(index, 1);
    // Dispatch updated phases to the reducer
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        discounts: arr,
        is_call_upcoming: true,
      },
    });
  };

  return (
    <div className="card-body pt-0">
      <div className="py-4">
        {" "}
        {!state?.items?.data?.length && !state?.items?.data?.length && (
          <LoadingSpinner />
        )}
        <div>
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Pricing</span>
          </h3>
        </div>
        <Table responsive className="summerytable customColor">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>PRODUCT</th>

              <th className="text-right w-25">PRICE</th>
              <th style={{ width: "15%" }}>QTY</th>
              <th className="text-right w-25">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {state?.items?.data?.length > 0 &&
              state?.items?.data?.map((item, index) => {
                if (item?.deleted) return;
                return (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">{item?.plan?.product_name}</div>
                      {item?.service_period_days
                        ? "On subscription creation"
                        : ""}
                      <br />
                      {item?.service_period_days
                        ? "Count " + item?.service_period_days
                        : ""}
                    </td>
                    <td className="text-right">
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={item?.price?.unit_amount}
                        onChange={
                          (e) =>
                            handleUnitPrice(
                              e.target.value,
                              index,
                              item?.product_id ===
                                process.env.REACT_APP_PRODUCT_POD_PROTECT
                            )
                          // handleUpdateProduct(e.target.value, "price", item.id)
                        }
                        onBlur={() =>
                          dispatchReducer({
                            type: "SET_DATA",
                            data: {
                              ...state,
                              is_call_upcoming: true,
                            },
                          })
                        }
                      />
                    </td>
                    <td>
                      {item?.plan?.product ===
                        process.env.REACT_APP_PRODUCT_POD_PROTECT ||
                      item?.plan?.product ===
                        process.env.REACT_APP_PRODUCT_STORE_PROTECT ? (
                        <span className="w-80 d-flex ml-3">
                          <Select
                            name="address"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            className={`form-control marginCustom11  width116 `}
                            IconComponent={(props) => (
                              <i {...props}>
                                <KeyboardArrowDownIcon />
                              </i>
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            value={state?.insurance_cover}
                            onChange={(e) => {
                              handleInsurance(e.target.value);
                            }}
                          >
                            {items.map((item) => {
                              if (item === 0) return;
                              return (
                                <MenuItem key={item} value={item}>
                                  &pound;
                                  {item.toLocaleString("en-IN")}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </span>
                      ) : (
                        <input
                          type="number"
                          className="form-control removeArrow"
                          value={
                            item?.quantity
                              ? String(item?.quantity).replace(/^0+/, "")
                              : 0
                          }
                          onChange={
                            (e) => {
                              handleUpdateQuantity(
                                e.target.value,
                                index,
                                item?.plan?.product,
                                item?.price?.unit_amount
                              );
                            }
                            // handleUpdateProduct(
                            //   e.target.value,
                            //   "quantity",
                            //   item.id,
                            //   item?.pricing_model
                            // )
                          }
                          onBlur={() =>
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                is_call_upcoming: true,
                              },
                            })
                          }
                        />
                      )}
                    </td>
                    <td className="text-right">
                      <input
                        type="number"
                        className="form-control removeArrow"
                        value={Number(
                          Number(item?.price?.unit_amount || 0) * item?.quantity
                        )?.toFixed(2)}
                        disabled
                        // onChange={(e) =>
                        //   handleUpdateProduct(e.target.value, "price", item.id)
                        // }
                        // onBlur={() =>
                        //   orderUIContext?.handleBreakDownApiCall(
                        //     orderUIContext?.state
                        //   )
                        // }
                      />
                    </td>
                    <td>
                      <EditOrderTableAction
                        setDialog={setDialog}
                        subItem={item}
                        orderUIContext={orderUIContext}
                        editOrderDetail={editOrderDetail}
                        state={state}
                        dispatchReducer={dispatchReducer}
                        index={index}
                        pageOn={"release"}
                        // value={value}
                      />
                    </td>
                  </tr>
                );
              })}

            {state?.discounts?.map((item, index) => (
              <tr style={{ backgroundColor: "#ecf9ee" }}>
                <td>
                  <div className="mb-2  d-flex">
                    <LocalOfferIcon className="flipIconFont15 mt-1" />
                    <strong className="ml-2">
                      {item?.coupon?.name || item?.coupon}
                    </strong>
                  </div>
                  <div className="ml-8">
                    {/* <p className="mb-1">{item?.id}</p> */}
                    <p className="mb-1">applied</p>
                    {/* <p className="mb-1">Applicable for 3 months on lineitems</p> */}
                  </div>
                </td>

                <td className="d-flex"></td>
                <td></td>

                <td>
                  <DeleteIcon
                    className="flipIconFont15 mb-1 "
                    onClick={() =>
                      handleCouponRemoval(state, item?.coupon, index)
                    }
                  />
                  {/* {item?.isRemove && ( */}

                  {/* {/* )} */}
                </td>
              </tr>
            ))}
            <EditOrderTableReleaseButtons
              orderUIContext={orderUIContext}
              state={state}
              editOrderDetail={editOrderDetail}
              dispatchReducer={dispatchReducer}
            />
          </tbody>
        </Table>
      </div>
      {dialog?.check && (
        <BillingCycleEditOrderModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.plan && (
        <ChangePlanModal
          state={state}
          dispatchReducer={dispatchReducer}
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
    </div>
  );
};
