/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import React, { useMemo, useRef, useState } from "react";
import {
  CollectionSchema,
  ReturnSchema,
  ReturnWizSchema,
} from "../order-form/validationSchema";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
import {
  accessUpdate,
  cancelReturnAccess,
  collectionExtraChage,
  extraMiles,
  orderDetailUpdate,
  returnExtraChage,
} from "../_redux/order/orderCrud";
import {
  fetchBillingCardDetail,
  fetchInvoiceList,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchNewFlags,
  fetchOrderSummary,
  fetchOrderSummaryListStripe,
  fetchPaymentInvoice,
} from "../_redux/order/orderAction";
import {
  newOrderCollectionValue,
  newOrderReturnValue,
  orderReturnValue,
} from "../orderUtils";

import { CalendarComponent } from "../../Calendar/calendar-component/CalendarComponent";
import CustomeDialog from "../../../../_metronic/_partials/widgets/alert/CustomeDialog";
import DatePicker from "react-datepicker";
import DiscardDialog from "../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { DropOff } from "../../Calendar/calendar-component/DropOff";
import { Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { calendarAccess, calendarTest } from "../../../pages/redux/commonCrud";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import { getAddress } from "../../ContactDeals/_redux/contact/contactCrud";
import {
  WeekCounter,
  calculateExtraPrice,
  getInputClasses,
  isWithinTwoWorkingDaysFromToday,
} from "../../../utils/commonFunction";
import moment from "moment";
import { operationCalendar } from "../../Calendar/redux/calendarCrud";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { LensTwoTone } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { divideByHundred } from "../../../utils/canvaCloseFunction";
import { convertKilometersToMiles } from "../../../utils/commonFunction";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import CustomeOrderSaveDialog from "../../../../_metronic/_partials/widgets/alert/CustomeOrderSaveDialog";
import { ReturnWizard } from "../components/ReturnWizard";
import {
  getWeekCountBetweenDates,
  isWeekend,
  transformDataToAddProduct,
  updateChargesForDayType,
  updateReturnChargesForDayType,
} from "../NewOrderStripe/NewOrderStripeUtils";

export function CollectionEditStripe({
  title,
  type,
  keys,
  setKeys,
  setDialog,
  dialog,
  dispatchReducer,
  depotList,
  franchise,
  state,
  value,
  initialValues,
  orderDetail,
  paramData,
}) {
  const dispatch = useDispatch();
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyCHkQzmrBUQV5Lc2no4PqVOI1AJsz-mNSI",
  // });
  const { cardDetail, extraPrice, orderSummaryListStripe } = useSelector(
    (state) => ({
      cardDetail: state.order.cardDetail,
      extraPrice: state.order.newFlagData,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );
  const intl = useIntl();
  const [calendarData, setCalendarData] = useState();
  const [activeEvent, setActiveEvent] = useState(null);
  const [actionButton, setActionButton] = useState({ type: "booking" });
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dropDown, setDropDown] = useState({ address: [] });
  const [isOverWrite, setIsOverWrite] = useState(false);
  const [dialogSave, setDialogSave] = useState({ type: false, discard: false });
  const [returnData, setReturnData] = useState();
  const [toggleCalendar, setToggleCalendar] = useState(true);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [returnChecks, setReturnChecks] = useState({
    is_return_cost: 1,
    is_return_congestion_charge: 1,
    is_extra_miles: 1,
  });
  const [accessAmount, setAccess] = useState(0);

  const handleSubmit = (values, { setSubmitting }) => {
    if (type === "new_order") {
      dispatchReducer({
        type: "deletedProduct",
        value: [],
      });
    }
    setSnack({
      call: false,
      type: "",
      message: "",
    });

    if (
      (keys === "collection" && values?.start_time_slot === "") ||
      (keys === "return" && values?.return_start_time_slot === "")
    ) {
      setSnack({
        call: true,
        type: "error",
        message: "Please Select Time Slot",
      });
      formik.setSubmitting(false);
      return;
    }
    const collectionData = newOrderCollectionValue(values, formik);
    const returnData = newOrderReturnValue(values, formik);

    formik.setSubmitting(false);
    console.log("checkWeek-1");

    const checkWeek = isWeekend(collectionData?.collection_date);
    if (checkWeek) {
      const product = updateChargesForDayType(
        orderSummaryListStripe?.pod?.charges,
        checkWeek,
        state.product_array
      );
      const depot = depotList.find(
        (e) => e.depot_id === Number(values?.depot_id)
      );
      if (keys === "return" && values.return_start_time_slot) {
        console.log(
          "collectionData?.return_dat",
          collectionData,
          collectionData?.return_date,
          returnData
        );

        const returncheckWeek = isWeekend(returnData?.return_date);
        const productReturn = updateReturnChargesForDayType(
          orderSummaryListStripe?.pod?.charges,
          returncheckWeek,
          state.product_array
        );
        dispatch(
          fetchOrderSummaryListStripe({
            franchise_id: state?.franchise_id,
            postcode: state?.postcode,
            return_postcode: values?.return_postcode,
          })
        );

        let productPlan = transformDataToAddProduct(
          orderSummaryListStripe?.pod?.col_ret
        );
        productPlan.type = "plan";
        // const weekPrice = (+productPlan?.cost*12)/52
        // const week = getWeekCountBetweenDates(values?.collection_date, values?.return_date)
        // const actualPrice = +weekPrice*Number(week)
        // productPlan.cost = actualPrice.toFixed(2)
        // productPlan.total = actualPrice.toFixed(2)

        const arr = [...productReturn];
        arr.shift();
        arr.unshift(productPlan);
        console.log("formik-2", formik);

        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            is_student_return: formik.values.is_student_return,
            collectionRetrun: { ...collectionData, ...returnData },
            product_array: arr,
            set: 7,
            depotDataForMap: depot,
          },
        });
      } else {
        console.log("formik-1", formik);
        const arr = [...product];
        console.log("domT", arr);

        if (formik.values.is_student == 1) {
          const filteredArr = arr.filter(
            (item) =>
              item.price_id !== "prod_6Oqikpw2ME5v" &&
              item.price_id !== "prod_3hh2QovzoQyr" &&
              item.price_id != process.env.REACT_APP_PRODUCT_WEEKEND_CHARGE
          );
          console.log("domT", arr, filteredArr);
          arr.length = 0;
          arr.push(...filteredArr);
        }
        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            is_student: formik.values.is_student,
            collectionRetrun: { ...collectionData, ...returnData },
            product_array: arr,
            set: 7,
            depotDataForMap: depot,
          },
        });
      }
    } else {
      console.log("formik-3", formik);

      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          collectionRetrun: keys === "collection" ? collectionData : returnData,
        },
      });
    }

    setDialog(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema:
      keys === "collection"
        ? CollectionSchema
        : keys === "return" && dialogSave?.returnWizard
        ? ReturnWizSchema
        : keys === "return"
        ? ReturnSchema
        : false,
    onSubmit: handleSubmit,
  });
  const handleApi = (check, emptyString) => {
    setLoading(true);
    if (check !== "overwrite") {
      setIsOverWrite(false);
    }
    let pod = "";

    if (type === "order" && value) {
      pod = value?.line_items.find((e) => e.type === "plan");
    } else {
      pod = state?.product_array.find((e) => e.type === "plan");
    }
    console.log("statestate", state, type, pod);

    const query = {
      id: franchise,
      date: selectedDate ? moment(selectedDate).format("yyyy-MM-DD") : "",
      pod: type === "order" ? orderDetail.pod : pod?.quantity || 1,
      is_overwrite: check === "overwrite" ? true : false,
    };
    if (cardDetail && cardDetail.length && cardDetail[0].postcode) {
      query.customer_postcode =
        cardDetail && cardDetail.length && cardDetail[0].postcode;
      query.depot_postcode = depotList?.find(
        (e) => e.depot_id == formik?.values?.depot_id
      )?.postcode;
    }
    if (type === "order") {
      query.customer_postcode = orderDetail?.collection_postcode;
      query.depot_postcode = depotList?.find(
        (e) => e.depot_id == (formik?.values?.depot_id || orderDetail?.depot_id)
      )?.postcode;
    }
    if (query.date === "Invalid date" && actionButton?.type === "booking")
      return;
    if (keys === "access") {
      query.is_access = 1;
      query.depot_id = formik?.values?.depot_id;
      if (orderDetail?.order_details?.pod_plan_type === "Economy") {
      }
    }
    if (actionButton?.type === "booking") {
      const calendarFunction =
        type === "order" && keys === "access" ? calendarAccess : calendarTest;
      if (type === "order" && keys === "access") {
        query.day = query?.date
          ? new Date(query.date).getDay() == 0
            ? "7"
            : new Date(query.date).getDay()
          : 1;
      }
      calendarFunction(query)
        .then((res) => {
          setLoading(false);
          setCalendarData(res.data);
          if (check === "overwrite") {
            setDialogSave({ type: false });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      operationCalendar(query)
        .then((res) => {
          setLoading(false);
          setCalendarData(res.data);
          if (check === "overwrite") {
            setDialogSave({ type: false });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  console.log("franchise-90", franchise);

  useEffect(() => {
    if (franchise && dialog) {
      handleApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    franchise,
    formik?.values?.collection_date,
    formik?.values?.return_date,
    formik?.values?.access_date,
    formik?.values?.depot_id,
    value,
    dialog,
    keys,
    actionButton,
    depotList?.length,
  ]);
  const handleCheck = (e) => {
    if (e.target.checked) {
      keys === "collection"
        ? formik.setValues({
            ...formik?.values,
            is_collection_address: "0",
            collection_postcode: formik?.initialValues?.collection_postcode,
            collection_address_line:
              formik?.initialValues?.collection_address_line,
            collection_address_city:
              formik?.initialValues?.collection_address_city,
          })
        : formik.setValues({
            ...formik?.values,
            is_return_address: "0",
            return_postcode: formik?.initialValues?.collection_postcode,
            return_address_line: formik?.initialValues?.collection_address_line,
            return_address_city: formik?.initialValues?.collection_address_city,
          });
    } else {
      formik.setErrors({});
      formik.resetForm();
      setDropDown({ ...dropDown, address: [] });
      keys === "collection"
        ? formik.setValues({
            ...formik.values,
            is_collection_address: 1,
            collection_postcode: "",
            collection_address_line: "",
            collection_address_city: "",
          })
        : formik.setValues({
            ...formik.values,
            is_return_address: 1,
            return_postcode: "",
            return_address_line: "",
            return_address_city: "",
          });
    }
  };
  const myRef = useRef(null);

  const handleManualUpdate = () => {
    formik.setFieldValue("is_return_address_manual", 1);
    formik.setFieldValue("return_address_line", "");
  };

  const handleGetAddress = async (postcode) => {
    if (!postcode) return;
    formik.setFieldValue(
      keys === "collection" ? "is_address_manual" : "is_return_address_manual",
      "0"
    );

    getAddress(postcode)
      .then((res) => {
        setDropDown({ address: res.data.addresses });
        formik.setFieldValue(
          keys === "return" ? "return_address_city" : "collection_address_city",
          res.data.addresses[0].town_or_city
        );
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: "Cannot find Post Code",
        });
      });
  };
  useEffect(() => {
    if (
      formik?.values?.collection_postcode ||
      formik?.values?.return_postcode
    ) {
      handleGetAddress(
        keys === "return"
          ? formik?.values?.return_postcode
          : formik?.values?.collection_postcode
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keys,
    formik?.values?.is_collection_address,
    formik?.values?.is_return_address,
  ]);

  useEffect(() => {
    if (Object.entries(formik.errors).length > 0 && formik.isSubmitting) {
      if (Object.entries(formik.errors).length > 0) {
        const [key, value] = Object.entries(formik.errors)[0];

        setSnack({
          call: true,
          type: "error",
          message: value,
        });
        return;
      }
    }
  }, [formik.errors, formik.isSubmitting]);
  useEffect(() => {
    if (snack.call) {
      setTimeout(() => {
        setSnack({
          call: false,
          type: "",
          message: "",
        });
      }, 2000);
    }
  }, [snack.call]);

  // handleClose is called when the form is closed
  const handleClose = () => {
    if (type === "new_order") {
      if (keys === "collection") {
        formik.setValues({
          ...formik.values,
          start_time_slot: "",
          end_time_slot: "",
        });
      } else if (keys === "return") {
        formik.setValues({
          ...formik.values,
          return_start_time_slot: "",
          return_end_time_slot: "",
        });
      }
      const collectionData = newOrderCollectionValue(formik?.values, formik);
      const returnData = newOrderReturnValue(formik?.values, formik);
      if (
        (keys === "collection" && state?.collectionRetrun?.start_time_slot) ||
        (keys === "return" && state?.collectionRetrun?.return_start_time_slot)
      ) {
        console.log("");

        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            collectionRetrun: {},
            defaultProduct: true,
          },
        });
      }
    }
    if (formik.dirty || Object.keys(formik.touched).length !== 0) {
      setDialogSave({ ...dialogSave, discard: true });
    } else {
      setDialog(false);
    }

    setToggleCalendar(true);
  };
  const handleCancel = () => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      order_id: formik?.values?.order_id,
      booking_type: capitalizeFirstLetter(keys),
    };
    cancelReturnAccess(data)
      .then((res) => {
        setDialog(false);
        setDialogSave({ type: false });
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const filterDisabledDays = (date) => {
    const dayOfWeek = date.getDay();
    const dayOfWeekString = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ][dayOfWeek];
    let show = true;
    if (calendarData?.data?.franchise_working_days) {
      show = calendarData?.data?.franchise_working_days[dayOfWeekString] === 1;
    }
    return show;
  };
  const handleSetCheck = (e, id, number) => {
    const fieldName =
      keys === "return" ? "return_pod_number" : "access_pod_number";
    let arr = [...formik.values[fieldName]];

    if (e.target.checked) {
      arr.push(id);
    } else {
      const index = arr.indexOf(id);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }

    formik.setFieldValue(fieldName, arr);
    const filterData = orderDetail?.pod_storage_details?.filter(
      (item) =>
        item.is_return === 0 &&
        item?.is_remove_by_driver === 0 &&
        item?.is_initial != 2
    );

    if (
      filterData.length !== arr.length &&
      arr.length > 0 &&
      keys === "return"
    ) {
      formik.setFieldValue("is_return", "0");
    } else if (filterData.length === arr.length) {
      formik.setFieldValue("is_return", "1");
    }
  };

  const selectedDate = useMemo(() => {
    switch (keys) {
      case "collection":
        return formik?.values?.collection_date || "";
      case "return":
        return formik?.values?.return_date || "";
      case "access":
        return formik?.values?.access_date || "";
      default:
        return "";
    }
  }, [formik?.values, keys]);
  const handleResponse = (response) => {
    if (formik?.values?.is_student_return === 1) return;

    const directionsService = new window.google.maps.DirectionsService();
    const value = formik.values;
    directionsService.route(
      {
        origin:
          value?.collection_address_line +
          "," +
          value?.collection_address_city +
          "," +
          value?.collection_postcode,
        destination:
          value?.return_address_line +
          "," +
          value?.return_address_city +
          "," +
          value?.return_postcode,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          extraMiles(franchise).then((res) => {
            const response = res.data.data;
            const price = divideByHundred(
              response?.prices[0]?.item_price?.price
            );
            let miles =
              (result?.routes.length > -1 &&
                result?.routes[0]?.legs.length > -1 &&
                convertKilometersToMiles(
                  result?.routes[0]?.legs[0]?.distance.text
                ) - 10) ||
              "-";
            let actualMiles = +miles > 0 ? +miles : 0;
            let Totalprice = calculateExtraPrice(
              Math.round(actualMiles),
              price
            );
            let check = extraPrice?.extra_miles_charge;
            let amount = check ? Totalprice - check : 0;
            let FinalAmount = amount > 0 ? amount : 0;
            // let count = orderDetail?.pod && Number(orderDetail?.pod) > 4 ? 1 : orderDetail?.pod && Number(orderDetail?.pod) > 7 ? 2 : orderDetail?.pod && Number(orderDetail?.pod) > 10 ? 3 : orderDetail?.pod && Number(orderDetail?.pod) > 13 ? 4 : orderDetail?.pod && Number(orderDetail?.pod) > 16 ? 5 : orderDetail?.pod && Number(orderDetail?.pod) > 19 ? 6 : 1
            let podNumber = Number(orderDetail?.pod);
            let count = 1; // Default value

            if (podNumber) {
              // Define the thresholds and corresponding counts
              const thresholds = [4, 7, 10, 13, 16, 19, 22, 25, 28, 31];

              // Loop through the thresholds to determine the count
              for (let i = 0; i < thresholds.length; i++) {
                if (podNumber > thresholds[i]) {
                  count = i + 2; // Since the count starts from 2 for thresholds > 4
                } else {
                  break; // Exit the loop once the correct count is found
                }
              }
            }
            setReturnData((prevReturnData) => ({
              ...prevReturnData,
              extraMiles: {
                id: response.id,
                totalPrice: check
                  ? +FinalAmount * +count
                  : orderDetail?.booking_reference === "254-060223-47721"
                  ? "60"
                  : +Totalprice * +count,
                qty: Math.round(miles),
              },
            }));
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(() => {
    const hideSnack = () => {
      setSnack({
        call: false,
        type: "",
        message: "",
      });
    };

    const timeoutId = setTimeout(hideSnack, 6000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Modal
        show={dialog}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
        dialogClassName="my-modal60"
        contentClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>{capitalizeFirstLetter(keys) + " Dates"}</Modal.Title>
          <div style={{ position: "absolute", top: "15px", right: "10px" }}>
            <span
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={() => handleClose()}
            >
              <i className="ki ki-close icon-sm text-muted"></i>
            </span>
          </div>
        </Modal.Header>

        {/*end::Header*/}
        <Modal.Body className="calendarModal">
          {loading && <LoadingCustome />}
          <form onSubmit={formik.handleSubmit}>
            {dialogSave?.returnWizard && keys === "return" ? (
              <div>
                <ReturnWizard
                  setDialogSave={setDialogSave}
                  extraData={
                    keys === "return" &&
                    // &&
                    // (formik?.values?.return_pod_number?.length === 0 ||
                    //   formik?.values?.return_pod_number?.length ===
                    //   +orderDetail?.pod)
                    dialogSave?.check !== "overwrite"
                  }
                  formik={formik}
                  returnData={returnData}
                  setReturnData={setReturnData}
                  loadingDialog={loadingDialog}
                  orderDetail={orderDetail}
                  setReturnChecks={setReturnChecks}
                  returnChecks={returnChecks}
                />
              </div>
            ) : (
              <div className=" px-10 ">
                {type !== "order" ? (
                  <>
                    <button
                      type="button"
                      className={`btn ${
                        keys === "collection" ? "btn-primaryCus" : "btn-dark"
                      } font-weight-bolder font-size-sm mr-3 `}
                      onClick={() => setKeys("collection")}
                    >
                      {intl.formatMessage({ id: "Collection" })}
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        keys === "return" ? "btn-primaryCus" : "btn-dark"
                      } font-weight-bolder font-size-sm mr-3 `}
                      onClick={() => setKeys("return")}
                      disabled={
                        !state?.collectionRetrun?.collection_date &&
                        type === "new_order"
                      }
                    >
                      {intl.formatMessage({ id: "Return" })}
                    </button>

                    {type !== "new_order" && (
                      <button
                        type="button"
                        className={`btn ${
                          keys === "access" ? "btn-primaryCus" : "btn-dark"
                        } font-weight-bolder font-size-sm mr-3 `}
                        onClick={() => setKeys("access")}
                        disabled={orderDetail?.is_collection_completed !== 1}
                      >
                        {intl.formatMessage({ id: "Access" })}
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className={`btn  ${
                    toggleCalendar === true ? "btn-primaryCus" : "btn-dark"
                  }  font-weight-bolder font-size-sm mr-3 `}
                  onClick={(e) => setToggleCalendar(!toggleCalendar)}
                >
                  <DateRangeIcon />
                </button>

                {toggleCalendar ? (
                  <div className="row">
                    <div className=" col-xxl-5 col-xl-6 col-sm-12 mt-2">
                      <div className="row">
                        <div className="col-sm-12 collection-date">
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-Y"
                            // excludeDates={[
                            //   new Date(
                            //     state?.collectionRetrun?.collection_date ||
                            //       formik?.values?.collection_date
                            //   ),
                            // ]}
                            selected={
                              selectedDate ? Date.parse(selectedDate) : ""
                            }
                            onChange={(date) => {
                              if (keys === "collection") {
                                formik.setFieldValue("start_time_slot", "");
                              } else if (keys === "return") {
                                formik.setFieldValue(
                                  "return_start_time_slot",
                                  ""
                                );
                              }
                              console.log(
                                "joke",
                                keys,
                                date,
                                moment(date).format("yyyy-MM-DD")
                              );

                              formik.setFieldValue(
                                keys === "collection"
                                  ? "collection_date"
                                  : keys === "return"
                                  ? "return_date"
                                  : keys === "access"
                                  ? "access_date"
                                  : "",
                                moment(date).format("yyyy-MM-DD")
                              );
                            }}
                            placeholderText="Select Collection Date"
                            inline
                            minDate={
                              keys === "return" || keys === "access"
                                ? Date.parse(
                                    type === "order"
                                      ? formik?.values?.collection_date
                                      : state?.collectionRetrun?.collection_date
                                  )
                                : new Date()
                              // moment()
                              //     .add(1, "days")
                              //     .toDate()
                            }
                            maxDate={
                              keys === "collection" &&
                              formik?.values?.return_date
                                ? Date.parse(
                                    moment(
                                      formik?.values?.return_date
                                    ).subtract(1, "days")
                                  )
                                : false
                            }
                            filterDate={filterDisabledDays}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="mt-2 mb-2">
                        {/* {type}
                    <div>
                      <span>
                        Note* please complete Access first
                        </span>
                    </div> */}
                        {keys === "return" ? (
                          <span>
                            {intl.formatMessage({ id: "Date.selected" })}:{" "}
                            {formik?.values?.return_date
                              ? moment(formik?.values?.return_date).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                            {formik?.values?.return_start_time_slot
                              ? "- " + formik?.values?.return_start_time_slot
                              : ""}
                            {formik?.values?.return_start_time_slot &&
                              formik?.values?.return_end_time_slot &&
                              formik?.values?.return_start_time_slot !==
                                moment(
                                  formik?.values?.return_date +
                                    "T" +
                                    formik?.values?.return_end_time_slot
                                )
                                  .subtract(1, "hours")
                                  .format("HH:mm") &&
                              "-" +
                                moment(
                                  formik?.values?.return_date +
                                    "T" +
                                    formik?.values?.return_end_time_slot
                                )
                                  .subtract(1, "hours")
                                  .format("HH:mm")}
                          </span>
                        ) : keys === "access" ? (
                          <span>
                            {intl.formatMessage({ id: "Date.selected" })}:{" "}
                            {formik?.values?.access_date
                              ? moment(formik?.values?.access_date).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}{" "}
                            {formik?.values?.access_start_time_slot
                              ? " - " + formik?.values?.access_start_time_slot
                              : ""}
                          </span>
                        ) : (
                          <span>
                            {intl.formatMessage({ id: "Date.selected" })}:{" "}
                            {formik?.values?.collection_date
                              ? moment(formik?.values?.collection_date).format(
                                  "DD-MM-YYYY"
                                )
                              : ""}
                            {formik?.values?.start_time_slot
                              ? "- " + formik?.values?.start_time_slot
                              : ""}
                            {formik?.values?.end_time_slot &&
                              formik?.values?.start_time_slot &&
                              formik?.values?.start_time_slot !==
                                moment(
                                  formik?.values?.collection_date +
                                    "T" +
                                    formik?.values?.end_time_slot
                                )
                                  .subtract(1, "hours")
                                  .format("HH:mm") &&
                              " - " +
                                moment(
                                  formik?.values?.collection_date +
                                    "T" +
                                    formik?.values?.end_time_slot
                                )
                                  .subtract(1, "hours")
                                  .format("HH:mm")}
                          </span>
                        )}
                      </div>
                      {keys !== "return" && (
                        <div className=" collection-form mt-2 ">
                          <div className="form-inline mb-5">
                            <span className="mr-2 ">
                              {" "}
                              {intl.formatMessage({ id: "Depot.Request" })}
                            </span>{" "}
                            <FormControl fullWidth>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                className="form-control w-100 "
                                IconComponent={(props) => (
                                  <i {...props}>
                                    <KeyboardArrowDownIcon />
                                  </i>
                                )}
                                inputProps={{ "aria-label": "Without label" }}
                                displayEmpty
                                disabled={keys === "access"}
                                {...formik.getFieldProps("depot_id")}
                              >
                                <MenuItem value="">
                                  {intl.formatMessage({ id: "Depot" })}
                                </MenuItem>
                                {depotList && depotList.length > 0
                                  ? depotList.map((item, index) => {
                                      if (item?.status !== 1) return;
                                      return (
                                        <MenuItem
                                          value={item?.depot_id}
                                          key={index}
                                        >
                                          {item?.name || ""}(Dis-{" "}
                                          {
                                            item?.distance_from_postcode
                                              ?.distance
                                          }{" "}
                                          {
                                            item?.distance_from_postcode
                                              ?.duration
                                          }
                                          )
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                              {formik.touched.depot_id &&
                              formik.errors.depot_id ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block" ref={myRef}>
                                    {formik.errors.depot_id}
                                  </div>
                                </div>
                              ) : null}
                            </FormControl>
                            <br />
                          </div>
                        </div>
                      )}

                      {keys === "access" ||
                      (keys === "return" &&
                        orderDetail?.is_return !== 1 &&
                        orderDetail?.is_collection_completed === 1 &&
                        Number(orderDetail?.pod) !== 1) ? (
                        <div className="col-xxl-5 col-md-8 mb-2 loader-dropdown d-flex ">
                          <label className="mt-2">
                            Select&nbsp;Pods:&nbsp;&nbsp;
                          </label>
                          <Dropdown
                            className="dropdown-inline"
                            drop="down"
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-toggle-top2"
                              variant="transparent"
                              className="btn btn-light   dropdown-toggle text-dark-75"
                              title={
                                <span>
                                  <i className="fad fa-newspaper"></i> Dropdown
                                </span>
                              }
                            >
                              Select pod
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                              <ul className="navi navi-hover">
                                {orderDetail?.pod_storage_details?.map(
                                  (item, index) => {
                                    if (
                                      (keys === "return" &&
                                        item?.is_return === 1) ||
                                      (keys === "return" &&
                                        item?.is_remove_by_driver === 1) ||
                                      (keys === "access" &&
                                        item?.is_initial == 2) ||
                                      (keys === "return" &&
                                        item?.is_initial == 2)
                                    )
                                      return;
                                    else {
                                      return (
                                        <li className="navi-item palav">
                                          <>
                                            <span className="navi-text">
                                              <label class="checkbox">
                                                <input
                                                  type="checkbox"
                                                  data-keys={
                                                    orderDetail?.is_return
                                                  }
                                                  // name={`loader_id[${index}]`}
                                                  checked={
                                                    keys === "return"
                                                      ? formik?.values
                                                          ?.return_pod_number &&
                                                        formik?.values
                                                          ?.return_pod_number
                                                          .length &&
                                                        formik?.values?.return_pod_number.some(
                                                          (e) => e === item?.id
                                                        )
                                                      : keys === "access"
                                                      ? formik?.values
                                                          ?.access_pod_number &&
                                                        formik?.values
                                                          ?.access_pod_number
                                                          .length &&
                                                        formik?.values?.access_pod_number.some(
                                                          (e) => e === item?.id
                                                        )
                                                      : ""
                                                  }
                                                  disabled={
                                                    keys === "return" &&
                                                    (Number(
                                                      orderDetail?.pod
                                                    ) === 1 ||
                                                      Number(
                                                        orderDetail?.is_return
                                                      ) === 1 ||
                                                      (item?.is_return === 1 &&
                                                        item?.is_remove_by_driver !==
                                                          0))
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      orderDetail?.is_return !==
                                                        1 &&
                                                      orderDetail.partial_return &&
                                                      orderDetail.partial_return
                                                        .length &&
                                                      keys === "return" &&
                                                      orderDetail.partial_return.some(
                                                        (e) =>
                                                          e.is_partial_return_completed !==
                                                          1
                                                      )
                                                    ) {
                                                      setSnack({
                                                        call: true,
                                                        type: "error",
                                                        message:
                                                          "Please complete the existing partial return before booking the next one.",
                                                      });
                                                      return;
                                                    }
                                                    handleSetCheck(
                                                      e,
                                                      item?.id,
                                                      item?.pod_number
                                                    );
                                                  }}
                                                />
                                                <span className="mr-2"></span>{" "}
                                                {item?.pod_number || "N/A"}
                                              </label>
                                            </span>
                                          </>
                                        </li>
                                      );
                                    }
                                  }
                                ) || ""}
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        ""
                      )}
                      {keys === "return" && (
                        <>
                          {["radio"].map((type) => (
                            <div
                              key={`custom-${type}`}
                              className=" checkbox mb-3"
                            >
                              <Form.Check
                                className="mb-2"
                                custom
                                name="Checkboxes4"
                                checked={
                                  keys === "collection" &&
                                  formik?.values?.is_collection_address === "0"
                                    ? true
                                    : keys === "return" &&
                                      formik?.values?.is_return_address === "0"
                                    ? true
                                    : false
                                }
                                type="checkbox"
                                id={`custom-${type}`}
                                label={`${capitalizeFirstLetter(
                                  keys
                                )} address same as ${
                                  keys === "collection"
                                    ? "Customer"
                                    : "Collection"
                                } address`}
                                onClick={handleCheck}
                              />
                            </div>
                          ))}
                        </>
                      )}
                      {((keys === "collection" &&
                        formik?.values?.is_collection_address === 1) ||
                        (keys === "return" &&
                          formik?.values?.is_return_address === 1)) && (
                        <div className="row">
                          <div className="col-12">
                            <label>
                              {intl.formatMessage({ id: "Post.Code" })}
                            </label>
                            <div className="row align-items-center">
                              <div className="col-6 d-flex justify-content-between align-items-center ">
                                <div className="form-groupB w-100">
                                  <input
                                    type="text"
                                    className={`form-control  ${getInputClasses(
                                      formik,
                                      keys === "return"
                                        ? "return_postcode"
                                        : "collection_postcode"
                                    )}`}
                                    placeholder={intl.formatMessage({
                                      id: "Post.Code",
                                    })}
                                    name={
                                      keys === "return"
                                        ? "return_postcode"
                                        : "collection_postcode"
                                    }
                                    {...formik.getFieldProps(
                                      keys === "return"
                                        ? "return_postcode"
                                        : "collection_postcode"
                                    )}
                                  />
                                </div>
                                <div className="mb-2">
                                  <span className="symbol-label ml-2">
                                    <span
                                      className="svg-icon svg-icon-lg svg-icon-primary"
                                      onClick={() =>
                                        handleGetAddress(
                                          keys === "return"
                                            ? formik.values.return_postcode
                                            : formik.values.collection_postcode
                                        )
                                      }
                                    >
                                      <SVG
                                        className="h-25 align-self-end"
                                        style={{ cursor: "pointer" }}
                                        title=" "
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/General/Search.svg"
                                        )}
                                      ></SVG>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-2 ">
                                <span
                                  style={{
                                    color: "#ff9f60",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleManualUpdate}
                                >
                                  {intl.formatMessage({ id: "Enter.Manually" })}
                                </span>
                              </div>
                              {formik.touched.collection_postcode &&
                              keys === "collection" &&
                              formik.errors.collection_postcode ? (
                                <div className=" ml-4 mb-4 fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik?.errors?.collection_postcode}
                                  </div>
                                </div>
                              ) : null}
                              {formik.touched.return_postcode &&
                              keys === "return" &&
                              formik.errors.return_postcode ? (
                                <div className=" ml-4 mb-4 fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik?.errors?.return_postcode}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12">
                            <label>
                              {intl.formatMessage({ id: "Address" })}
                            </label>
                            <div className="form-groupB">
                              {formik.values.is_return_address_manual === 1 ? (
                                <input
                                  type="text"
                                  className={`form-control  ${getInputClasses(
                                    formik,
                                    "address"
                                  )}`}
                                  placeholder={intl.formatMessage({
                                    id: "Address",
                                  })}
                                  name={
                                    keys === "return"
                                      ? "return_address_line"
                                      : "collection_address_line"
                                  }
                                  {...formik.getFieldProps(
                                    keys === "return"
                                      ? "return_address_line"
                                      : "collection_address_line"
                                  )}
                                />
                              ) : (
                                <FormControl fullWidth>
                                  <Select
                                    name={
                                      keys === "return"
                                        ? "return_address_line"
                                        : "collection_address_line"
                                    }
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                    className={`form-control  ${getInputClasses(
                                      formik,
                                      keys === "return"
                                        ? "return_address_line"
                                        : "collection_address_line"
                                    )}`}
                                    IconComponent={(props) => (
                                      <i {...props}>
                                        <KeyboardArrowDownIcon />
                                      </i>
                                    )}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    displayEmpty
                                    {...formik.getFieldProps(
                                      keys === "return"
                                        ? "return_address_line"
                                        : "collection_address_line"
                                    )}
                                  >
                                    <MenuItem value="">
                                      {intl.formatMessage({ id: "Address" })}
                                    </MenuItem>
                                    {dropDown?.address &&
                                    dropDown?.address.length > 0
                                      ? dropDown?.address.map((data, index) => {
                                          // const addressData = data.formatted_address.toString();
                                          let addressData = data.formatted_address.toString();
                                          addressData = addressData?.replaceAll(
                                            ", ",
                                            ","
                                          );
                                          addressData = addressData?.replaceAll(
                                            ",, ",
                                            ","
                                          );
                                          addressData = addressData?.replaceAll(
                                            ",,,",
                                            ","
                                          );
                                          return (
                                            <MenuItem
                                              value={addressData}
                                              key={index}
                                            >
                                              {addressData}
                                            </MenuItem>
                                          );
                                        })
                                      : null}
                                  </Select>
                                </FormControl>
                              )}

                              {formik.touched.collection_address_line &&
                              keys === "collection" &&
                              formik.errors.collection_address_line ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.collection_address_line}
                                  </div>
                                </div>
                              ) : null}
                              {formik.touched.return_address_line &&
                              keys === "return" &&
                              formik.errors.return_address_line ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.return_address_line}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12">
                            <label>{intl.formatMessage({ id: "CITY" })}</label>
                            <div className="form-groupB">
                              <input
                                type="text"
                                className={`form-control  ${getInputClasses(
                                  formik,
                                  keys === "return"
                                    ? "return_address_city"
                                    : "collection_address_city"
                                )}`}
                                placeholder={intl.formatMessage({ id: "CITY" })}
                                name="city"
                                {...formik.getFieldProps(
                                  keys === "return"
                                    ? "return_address_city"
                                    : "collection_address_city"
                                )}
                              />
                              {formik.touched.collection_address_city &&
                              keys === "collection" &&
                              formik.errors.collection_address_city ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.collection_address_city}
                                  </div>
                                </div>
                              ) : null}
                              {formik.touched.return_address_city &&
                              keys === "return" &&
                              formik.errors.return_address_city ? (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.return_address_city}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-4 mb-4">
                  <div
                    className="mb-4 d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <button
                        className={`btn ${
                          actionButton.type === "booking"
                            ? "btn-primaryCus"
                            : "btn-dark"
                        } font-weight-bolder font-size-sm mr-3 `}
                        onClick={() =>
                          setActionButton({ ...actionButton, type: "booking" })
                        }
                        type="button"
                      >
                        {intl.formatMessage({ id: "Booking" })}
                      </button>
                    </div>
                    {actionButton?.type !== "operation" && keys !== "access" && (
                      <div>
                        <button
                          className={`btn ${
                            isOverWrite ? "btn-primaryCus" : "btn-dark"
                          } font-weight-bolder font-size-sm mr-3 `}
                          onClick={() => {
                            // setIsOverWrite(true);
                            setDialogSave({ type: true, check: "overwrite" });
                          }}
                          type="button"
                        >
                          {intl.formatMessage({ id: "Overwrite" })}
                        </button>
                      </div>
                    )}
                  </div>
                  {keys !== "access" && (
                    <CalendarComponent
                      collectionDate={Date.parse(
                        keys === "collection"
                          ? formik?.values?.collection_date
                          : keys === "return"
                          ? formik?.values?.return_date
                          : moment().toDate()
                      )}
                      calendarData={calendarData}
                      formik={formik}
                      keys={keys}
                      activeEvent={activeEvent}
                      setActiveEvent={setActiveEvent}
                      actionButton={actionButton}
                      isOverWrite={isOverWrite}
                    />
                  )}
                  <DropOff
                    collectionDate={Date.parse(
                      keys === "collection"
                        ? formik?.values?.collection_date
                        : keys === "return"
                        ? formik?.values?.return_date
                        : keys === "access"
                        ? formik?.values?.access_date
                        : new Date()
                    )}
                    calendarData={calendarData}
                    formik={formik}
                    keys={keys}
                    activeEvent={activeEvent}
                    setActiveEvent={setActiveEvent}
                    actionButton={actionButton}
                    type={type}
                    state={state}
                    orderDetail={orderDetail}
                    depotList={depotList}
                  />
                </div>
              </div>
            )}
            <Modal.Footer>
              <div
                className={`${
                  dialogSave?.returnWizard ? "w-100 ml-4" : ""
                } d-flex justify-content-between`}
              >
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                    onClick={() => {
                      handleClose();
                      // setDialog(false)
                    }}
                  >
                    {intl.formatMessage({ id: "CANCEL" })}
                  </button>
                  <button
                    type={"submit"}
                    className="btn btn-primary font-weight-bolder font-size-sm "
                  >
                    {intl.formatMessage({ id: "SAVE" })}{" "}
                    {formik?.isSubmitting && (
                      <span className="ml-2 mr-2 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            </Modal.Footer>
            <CustomeOrderSaveDialog
              header={
                dialogSave?.check === "overwrite"
                  ? intl.formatMessage({ id: "Overwrite.Maximum.Time.Slot" })
                  : dialogSave?.cancel
                  ? intl.formatMessage({ id: "Cancel.job" })
                  : keys === "access" && orderDetail?.is_student_access === 1
                  ? intl.formatMessage({ id: "Change.Access" })
                  : keys === "access" && orderDetail?.is_student_access === 0
                  ? "Book Access?"
                  : intl.formatMessage({ id: "Save.Changes" })
              }
              mainText={
                dialogSave?.check === "overwrite"
                  ? intl.formatMessage({ id: "Have.overwrite.time.slot" })
                  : dialogSave?.cancel
                  ? intl.formatMessage({ id: "Are.you.cancel.booking" })
                  : keys === "access" && orderDetail?.is_student_access === 1
                  ? "Are you sure you want to change this access request?"
                  : keys === "access" && orderDetail?.is_student_access === 0
                  ? "Are you sure you want to book this access request?"
                  : intl.formatMessage({ id: "Do.you.changes.booking" })
              }
              dialog={dialogSave}
              setDialog={setDialogSave}
              type={dialogSave?.check === "overwrite" ? "overwrite" : "order"}
              formik={formik}
              handleAction={dialogSave?.cancel ? handleCancel : handleApi}
              // extraData={
              //   keys === "return" &&
              //   (formik?.values?.return_pod_number?.length === 0 ||
              //     formik?.values?.return_pod_number?.length ===
              //       +orderDetail?.pod) &&
              //   dialogSave?.check !== "overwrite"
              // }
              returnData={returnData}
              loadingDialog={loadingDialog}
              check={type}
              showCheck={keys}
              dialogConditionCheck={true}
              franchiseId={orderDetail?.franchise_id}
              orderDetail={orderDetail}
              accessAmount={accessAmount}
              setAccess={setAccess}
              dialogCondition={false}
              callPage={"collectionEdit"}
            />
          </form>
        </Modal.Body>
      </Modal>
      <DiscardDialog
        setDialogDis={setDialogSave}
        dialogDis={dialogSave}
        formik={formik}
        setActive={setDialog}
        type={"order"}
      />
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
