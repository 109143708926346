/* eslint-disable no-restricted-imports */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core/";
// import { Tab, Tabs } from "react-bootstrap";
import {
  addDriverLoader,
  cancelParReturn,
  cancelReturn,
  cancelReturnAccess,
  collectionComplete,
  completeAccess,
  completePar,
  getHasArrear,
  getInarrearsStatus,
} from "../_redux/order/orderCrud";
import {
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
  fetchPaymentInvoice,
  fetchSavedDriverLoader,
} from "../_redux/order/orderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CollectionEdit } from "../order-canva/CollectionEdit";
// import CustomeDialog from "../../../../_metronic/_partials/widgets/alert/CustomeDialog";
import { Dropdown } from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import SVG from "react-inlinesvg";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { capitalizeFirstLetter } from "../../../utils/canvaCloseFunction";
import { fetchFranchisesDepot } from "../../Settings/_redux/franchises/franchisesActions";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import moment from "moment";
import { orderCollectionValue } from "../orderUtils";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
// import { getInputClasses } from "../../../utils/commonFunction";
import BookingAccessModal from "../order-modal/BookingAccess";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import CustomeOrderSaveDialog from "../../../../_metronic/_partials/widgets/alert/CustomeOrderSaveDialog";
import DueInvoice from "../order-modal/DueInvoice";
import { withStyles, Tooltip, Chip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Form } from "react-bootstrap";
import ParModal from "../order-modal/ParModal";
import { isWithinTwoWorkingDaysFromToday } from "../../../utils/commonFunction";
import AccessAsk from "../order-modal/AccessAsk";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "1rem", // Increase the font size here
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    width: "100%",
  },
}))(Tooltip);

export function OrderCollection({
  orderDetail,
  driverLoader,
  detailDriverLoader,
  orderInvoice,
  billingCard,
  hasArrear,
  setIsRelease,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("collection");
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogSave, setDialogSave] = useState({ type: false, discard: false });
  const [dialogAccess, setDialogAccess] = useState({ check: false });
  const [isLoading, setIsLoading] = useState(false);
  const [dialogDue, setDialogDue] = useState({ check: false });
  const [test, setTest] = useState("");
  const [accessAmount, setAccess] = useState(0);
  const [dialogAccessBefore, setDialogAccessBefore] = useState(false);
  const [isArrears, setIsArrears] = useState(false);

  // const [hasArrear, setHasArrear] = useState(null)

  const init = ["kt_collectionedit_panel"];
  useEffect(() => {
    initIds(init);
  }, [init]);

  const { depotFraList } = useSelector(
    (state) => ({
      depotFraList: state.franchise.depot,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (orderDetail?.franchise_id && dialog) {
      dispatch(
        fetchFranchisesDepot({
          franchise_id: orderDetail?.franchise_id,
          postcode: orderDetail?.collection_postcode,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.franchise_id, dialog]);

  const tabHandleSelect = (key) => {
    setTitle(key);
  };

  // useEffect(() => {

  //   if(orderDetail?.customer_old_chargebee_id){
  //     getHasArrear(orderDetail?.customer_old_chargebee_id).then((res)=>{
  //       setHasArrear(res.data.data)
  //       console.log("helloop", res.data.data);

  //     })
  //   }
  // }, [orderDetail?.customer_old_chargebee_id])
  console.log("hasArrear", hasArrear);

  const initialValues = {
    order_id: orderDetail?.id || "",
    collection_driver_id: "",
    collection_loader_id: [],
    driver_id: "",
    loader_id: "",
    is_return: "",
  };

  // const initialValuesReturn = {
  //   order_id: orderDetail?.id || "",
  //   driver_id: "",
  //   loader_id: "",
  //   is_return: "1",
  // };

  const handleSubmit = (values, type) => {
    formik.resetForm({ values: "" });
    setIsLoading(true);
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      order_id: values?.order_id || "",
      driver_id:
        type === "collection"
          ? values?.collection_driver_id
          : values?.driver_id || "",
      loader_id:
        type === "collection"
          ? values?.collection_loader_id
          : values?.loader_id || [],

      is_return: type === "collection" ? "0" : 1,
    };

    addDriverLoader(data)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        dispatch(fetchMyOrderTimeline(values?.order_id));
        dispatch(fetchSavedDriverLoader(values?.order_id));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message || "",
        });
      });
  };
  const handleReset = () => {};

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    // validationSchema: CollectionSchema,
    // onSubmit: handleSubmit,
    onReset: handleReset,
  });

  const handleSetCheck = (e, i, id, type) => {
    const arr = [...formik?.values?.loader_id];
    const arrCollection = [...formik?.values?.collection_loader_id];

    if (e.target.checked) {
      if (type === "collection") {
        arrCollection.push(id);
        formik.setFieldValue(`collection_loader_id`, arrCollection);
      } else {
        arr.push(id);
        formik.setFieldValue(`loader_id`, arr);
      }
    } else {
      if (type === "collection") {
        const index = arrCollection.findIndex((e) => e === id);
        arrCollection.splice(index, 1);
        formik.setFieldValue(`collection_loader_id`, arrCollection);
      } else {
        const index = arr.findIndex((e) => e === id);
        arr.splice(index, 1);
        formik.setFieldValue(`loader_id`, arr);
      }
    }
  };
  const handleUpdate = useCallback(() => {
    const arr = [];
    let driver = "";
    const collection_arr = [];
    let collection_driver = "";
    for (let items of detailDriverLoader?.data?.getDrivers) {
      if (items?.is_return === 1) {
        driver = items?.driver_id;
      } else if (items?.is_return === 0) {
        collection_driver = items?.driver_id;
      }
    }
    for (let items of detailDriverLoader?.data?.getLoaders) {
      if (items?.is_return === 1) {
        arr.push(items?.loader_id);
      } else if (items?.is_return === 0) {
        collection_arr.push(items?.loader_id);
      }
    }

    if (arr.length) formik.setFieldValue("loader_id", arr);
    if (driver) formik.setFieldValue("driver_id", driver);
    if (collection_arr.length)
      formik.setFieldValue("collection_loader_id", collection_arr);
    if (collection_driver)
      formik.setFieldValue("collection_driver_id", collection_driver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    detailDriverLoader.data.getDrivers,
    detailDriverLoader.data.getLoaders,
    title,
  ]);

  useEffect(() => {
    if (detailDriverLoader?.data?.getLoaders) {
      handleUpdate();
    }
  }, [detailDriverLoader.data.getLoaders, handleUpdate, title, orderDetail]);

  // const value =
  //   (orderInvoice?.first_invoice &&
  //     orderInvoice?.first_invoice.length &&
  //     orderInvoice?.first_invoice[0]?.invoice) ||
  //   "";

  const handleCancel = () => {
    setIsLoading(true);
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      order_id: formik?.values?.order_id,
      booking_type: capitalizeFirstLetter(title),
    };
    if (dialogSave.is_cancel_charge) {
      data.is_cancel_charge = dialogSave?.is_cancel_charge || 1;
    }
    if (
      (dialogSave?.complete && dialogSave?.collection) ||
      (dialogSave?.complete && dialogSave?.return)
    ) {
      data.type = dialogSave?.return ? "return" : "collection";
      delete data.booking_type;
      collectionComplete(data)
        .then((res) => {
          setDialog(false);
          setDialogSave({ type: false });
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          setIsLoading(false);
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          dispatch(
            fetchPaymentInvoice(
              orderDetail?.id,
              orderDetail?.franchise_id,
              orderDetail?.subscription_id
            )
          );
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
        });
      return;
    }
    if (dialogSave?.complete && test !== "par") {
      delete data.booking_type;
      completeAccess(data)
        .then((res) => {
          setDialog(false);
          setDialogSave({ type: false });
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          setIsLoading(false);
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          setDialogAccess({ check: true });
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
        });
      return;
    } else if (dialogSave?.complete && test === "par") {
      delete data.booking_type;
      const arr = [];
      orderDetail.partial_return.map((item, index) => {
        if (item?.is_partial_return_completed !== 1) {
          const podLists = item?.pod_ids.split(",");
          arr.push(...podLists);
        }
      });
      data.return_pod_number = arr;
      completePar(data)
        .then((res) => {
          setDialog(false);
          setDialogSave({ type: false });
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          setIsLoading(false);
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          setDialogAccess({ test: true });
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
        });
      return;
    }
    if (
      title === "return" &&
      orderDetail?.partial_return &&
      orderDetail?.partial_return?.length > 0 &&
      orderDetail.is_return !== 1
    ) {
      const datas = {
        order_id: formik?.values?.order_id,
        price_id: "",
        franchise_id: orderDetail?.franchise_id || "",
        mail_to_customer: formik?.values?.mail_to_customer,
      };
      console.log(
        "orderDetail?.subscription_data",
        orderDetail?.subscription_data
      );

      for (let items of orderDetail?.subscription_data?.subscription?.items
        ?.data) {
        datas.price_id =
          items?.plan?.product === process.env.REACT_APP_PRODUCT_FIRST_CLASS ||
          items?.plan?.product === process.env.REACT_APP_PRODUCT_PREMIUM ||
          items?.plan?.product === process.env.REACT_APP_PRODUCT_ECONOMY
            ? items?.plan?.product
            : "";
      }
      cancelParReturn(datas)
        .then((res) => {
          setIsLoading(false);
          setDialog(false);
          setDialogSave({ type: false });
          setSnack({
            call: true,
            type: "success",
            message: res?.data?.message || "",
          });
          dispatch(fetchMyOrderTimeline(orderDetail?.id));
          dispatch(fetchMyOrderDetailCard(orderDetail?.id));
          dispatch(
            fetchPaymentInvoice(
              orderDetail?.id,
              orderDetail?.franchise_id,
              orderDetail?.subscription_id
            )
          );
        })
        .catch((error) => {
          setIsLoading(false);
          setSnack({
            call: true,
            type: "error",
            message: error?.response?.data?.message,
          });
        });
      return;
    }
    data.mail_to_customer = formik?.values?.mail_to_customer;
    console.log(
      "mohit",
      !isWithinTwoWorkingDaysFromToday(orderDetail?.access_date, new Date()),
      orderDetail?.order_details?.pod_plan_type === "Economy"
    );
    if (
      !isWithinTwoWorkingDaysFromToday(orderDetail?.access_date, new Date()) &&
      orderDetail?.order_details?.pod_plan_type === "Economy"
    ) {
      data.access_refund = accessAmount?.amount;
      console.log("data-12", data, accessAmount);
    }
    cancelReturnAccess(data)
      .then((res) => {
        setIsLoading(false);
        setDialog(false);
        setDialogSave({ type: false });
        setSnack({
          call: true,
          type: "success",
          message: res?.data?.message || "",
        });
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(
          fetchPaymentInvoice(
            orderDetail?.id,
            orderDetail?.franchise_id,
            orderDetail?.subscription_id
          )
        );
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };
  const shouldShowCollectionCompleteButton =
    orderDetail?.is_collection_completed !== 1;
  // &&
  // orderDetail?.is_student === 1 &&
  // orderDetail?.subscription_data.status !== "future";
  const shouldShowCompleteButton =
    orderDetail?.is_return_completed !== 1 ||
    (orderDetail?.partial_return &&
      orderDetail?.partial_return.length > 0 &&
      orderDetail?.is_return !== 1);
  // &&
  // orderDetail.fulfillment_stage === "4";

  const shouldShowCancelButton =
    (orderDetail?.is_return === 1 && orderDetail?.is_return_completed !== 1) ||
    (orderDetail?.partial_return &&
      orderDetail?.partial_return.length > 0 &&
      orderDetail?.is_return !== 1);
  // &&
  // orderDetail.fulfillment_stage === "4"

  const shouldShowAccessCompleteButton =
    orderDetail?.is_student_access === 1 &&
    orderDetail?.is_access_completed === 1;

  const shouldShowAccessCancelButton =
    (orderDetail?.is_student_access === 1 &&
      orderDetail?.is_access_completed === 1 &&
      orderDetail?.is_access_cancel === 1) ||
    orderDetail?.is_access_completed === 1;

  const shouldShowAccessButton = orderDetail?.is_student_access === 1;

  const handleArrayPartial = () => {
    const arr = [];

    orderDetail.partial_return.map((item, index) => {
      const podLists = item?.pod_ids.split(",");
      if (item?.is_partial_return_completed === 1) {
        return;
      }
      arr.push(...podLists);
    });
    return arr
      .map((id) =>
        orderDetail?.pod_storage_details.find((e) => e.id === Number(id))
      )
      .filter((title) => title !== undefined)
      .map((title, i) => (
        <React.Fragment key={i}>
          {i === 0 ? "" : ","}
          {title?.pod_number || "N/A"}
        </React.Fragment>
      ));
  };
  const handleArrayCompletePartial = () => {
    const arr = [];

    orderDetail.partial_return.forEach((item) => {
      if (item?.is_partial_return_completed === 1) {
        const podLists = item?.pod_ids.split(",");
        arr.push(...podLists);
      }
    });

    return arr
      .map((id) =>
        orderDetail?.pod_storage_details.find((e) => e.id === Number(id))
      )
      .filter((title) => title !== undefined)
      .map((title, i) => (
        <React.Fragment key={i}>
          {i === 0 ? "" : ","}
          {title?.pod_number || "N/A"}
        </React.Fragment>
      ));
  };

  const handleSetKey = (value) => {
    if (
      orderDetail?.is_collection_completed !== 1 &&
      value === "return" &&
      orderDetail?.is_return !== 1
    ) {
      setSnack({
        call: true,
        type: "error",
        message:
          "Sorry !! You can not book return since collection is not completed yet.",
      });
      setTimeout(() => {
        setSnack({
          call: false,
          type: "",
          message: "",
        });
      }, 3000);
      return;
    }
    tabHandleSelect(value);
    setDialog(true);
  };

  const depotTooltip = (
    <div>
      <div className="iIconToolTip">
        <strong>Depot Address</strong> :{" "}
        {[
          orderDetail?.depot_details?.address_line_1,
          orderDetail?.depot_details?.address_line_2,
          orderDetail?.depot_details?.address_line_3,
          orderDetail?.depot_details?.city,
          orderDetail?.depot_details?.postcode,
        ]
          .filter((part) => part) // Filter out null or undefined parts
          .join(", ")}
      </div>
      {orderDetail?.depot_details?.phone && (
        <div className="iIconToolTip ">
          <strong>Phone</strong> : {orderDetail?.depot_details?.phone}
        </div>
      )}
    </div>
  );
  const depotTooltipAccess = (
    <div>
      <div className="iIconToolTip">
        <strong>Depot Address</strong> :
        {orderDetail?.depot_details?.address_line_1 +
          "," +
          orderDetail?.depot_details?.address_line_2 +
          "," +
          orderDetail?.depot_details?.address_line_3 +
          "," +
          orderDetail?.depot_details?.city +
          "," +
          orderDetail?.depot_details?.postcode}
      </div>
      {/* <div>
        <strong>Phone</strong>:{orderDetail?.depot_details?.phone}
      </div> */}
    </div>
  );
  // const isAllPartialReturnCompleted = orderDetail?.partial_return.every(
  //   (item) => item.is_partial_return_completed !== 1
  // );

  useEffect(() => {
    console.log("orderDetail?.id", orderDetail);

    if (!orderDetail?.id) return;
    setIsLoading(true);
    const data = {
      order_id: orderDetail?.id,
    };
    getInarrearsStatus(data)
      .then((res) => {
        setIsLoading(false);
        setIsRelease(res.data.data?.release || false);
        if (res.data.data?.isInArrears)
          setIsArrears(res.data.data?.isInArrears);
      })
      .catch((err) => {
        setIsLoading(false);

        setIsArrears(false);
      });
  }, [orderDetail?.id]);

  return (
    <>
      {isLoading && <LoadingCustome />}
      <div className="card card-custom gutter-b  ">
        <div className="card-header align-items-center border-0 mt-4 mb-0">
          <div className="d-flex justify-content-between w-100">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark">
                Calendar{" "}
                <sup
                  style={{
                    color: isArrears ? "red" : "",
                  }}
                >
                  {isArrears
                    ? "Can't perform any action due to overdue invoice."
                    : ""}
                </sup>
              </span>
            </h3>
          </div>
        </div>
        <div className={`card-body ${isArrears ? "pointerNone" : ""}`}>
          <div className="neworderdtl-tab">
            <div className="edit-icon">
              {/* {title !== "collection" ? (
                <Dropdown className="dropdown-inline" drop="down" alignRight>
                  <Dropdown.Toggle
                    id="dropdown-toggle-top2"
                    variant="transparent"
                    className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                    disabled={
                      !shouldShowCompleteButton &&
                      !shouldShowCancelButton &&
                      !shouldShowAccessButton
                    }
                  >
                    {intl.formatMessage({ id: "ACTION" })}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi navi-hover">
                      {shouldShowCompleteButton && (
                        <li
                          className="navi-item"
                          onClick={() =>
                            setDialogSave({ type: true, complete: true })
                          }
                        >
                          <span
                            id="kt_franchisepostcodes_panel_toggle"
                            className="navi-link pointer"
                          >
                            <span className="navi-text">
                              {intl.formatMessage({ id: "Complete" })}
                            </span>
                          </span>
                        </li>
                      )}
                      {shouldShowCancelButton && (
                        <li
                          className="navi-item"
                          onClick={() =>
                            setDialogSave({ type: true, cancel: true })
                          }
                        >
                          <span
                            className="navi-link pointer"
                            id="kt_stripedetail_panel_toggle"
                          >
                            <span className="navi-text">
                              {intl.formatMessage({ id: "CANCEL" })}
                            </span>
                          </span>
                        </li>
                      )}
                      {shouldShowAccessButton && (
                        <li
                          className="navi-item"
                          onClick={() => setDialogAccess({ check: true })}
                        >
                          <span
                            className="navi-link pointer"
                            id="kt_termscondition_panel_toggle"
                          >
                            <span className="navi-text"> Booking Access</span>
                          </span>
                        </li>
                      )}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                ""
              )} */}

              {/* <span className="symbol-label ml-2">
                <span
                  className="svg-icon svg-icon-sm svg-icon-primary "
                  role="button"
                  onClick={() => {
                    if (
                      (title === "collection" &&
                        orderDetail?.is_collection_completed === 1) ||
                      (title === "return" &&
                        orderDetail?.is_return_completed === 1)
                    )
                      return;
                    setDialog(true);
                  }}
                  style={{
                    display:
                      title === "collection" && orderDetail?.is_cancel === 1
                        ? "none"
                        : "",
                  }}
                >
                  <SVG
                    title=" "
                    className="h-25 align-self-end"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                    disabled={
                      title === "collection" &&
                      orderDetail?.is_collection_completed === "1"
                    }
                  ></SVG>
                </span>
              </span> */}
            </div>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              <div className="d-flex">
                <button
                  type="button"
                  className={`btn ${"btn-primaryCus"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() => handleSetKey("collection")}
                  disabled={orderDetail?.is_collection_completed === 1}
                >
                  Edit{" "}
                  {orderDetail?.is_container == 1
                    ? orderDetail?.date_type == 1
                      ? "Collection"
                      : "MoveIn"
                    : "Collection"}
                </button>
                <button
                  type="button"
                  className={`btn ${"btn-primaryCus"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() => {
                    handleSetKey("return");
                  }}
                  disabled={
                    orderDetail?.is_return_completed === 1 &&
                    orderDetail?.is_return === 1
                  }
                >
                  {orderDetail?.is_return === 1 ? "Edit Return" : "Book Return"}
                </button>
                <button
                  type="button"
                  className={`btn ${"btn-primaryCus"} font-weight-bolder font-size-sm mr-3 `}
                  onClick={() =>
                    orderDetail?.is_student_access === 1 &&
                    orderDetail?.is_access_completed !== 1
                      ? setDialogAccessBefore(true)
                      : // handleSetKey("access")
                        handleSetKey("access")
                  }
                  disabled={
                    orderDetail?.is_collection_completed !== 1 ||
                    orderDetail?.is_return_completed === 1
                  }
                >
                  {orderDetail?.is_student_access === 1 &&
                  orderDetail?.is_access_completed !== 1
                    ? "Edit Access "
                    : "Book Access"}
                </button>
                {hasArrear?.has_arrears && (
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={
                      "If a customer has an order on Vault and it matches the record in the file, then need to show a label at the top of the order and in the collection/return/movein/moveout box stating ‘In Arrears’. Put a tool tip next to this label saying ‘This customer has invoices unpaid prior to 8th of Oct. Please check with your accounts team to review the customer’s outstanding invoices in Quickbooks and collect payment via Stripe for those invoices. If you wish to take the customer out of arrears then click the Actions button next to Billing, select ‘No longer in arrears’. "
                    }
                    placement="bottom"
                  >
                    <sup
                      // style={{
                      //   color:
                      //     orderDetail?.is_collection_completed === 1
                      //       ? "green"
                      //       : "red",
                      // }}
                      style={{ marginTop: "10px" }}
                      className="top-2"
                    >
                      <span
                        class={`badge badge-pill badge-danger`}
                        style={{ fontSize: "1rem" }}
                      >
                        In Arrears
                      </span>
                    </sup>
                  </Tooltip>
                )}
              </div>

              <div className="row mt-4 align-items-center order-collection ">
                <div className=" w-100 justify-content-between d-flex">
                  <h5 className="card-title align-items-start flex-column mb-3 ml-4">
                    <span className="font-weight-bolder text-dark">
                      Collection Details{" "}
                      <sup
                        // style={{
                        //   color:
                        //     orderDetail?.is_collection_completed === 1
                        //       ? "green"
                        //       : "red",
                        // }}
                        className="top-4"
                      >
                        <span
                          class={`badge badge-pill ${
                            orderDetail?.is_collection_completed === 1
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {orderDetail?.is_collection_completed === 1
                            ? "Completed"
                            : "Not completed"}
                        </span>
                        {/* <span className="badge badge-light-success"> </span> */}

                        {/* {orderDetail?.is_collection_completed === 1
                          ? "completed"
                          : "not completed"} */}
                      </sup>
                    </span>
                  </h5>
                  <Dropdown
                    className="dropdown-inline mr-4"
                    drop="down"
                    alignRight
                  >
                    <Dropdown.Toggle
                      id="dropdown-toggle-top2"
                      variant="transparent"
                      className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                      disabled={
                        !shouldShowCollectionCompleteButton ||
                        orderDetail?.is_cancel === 1
                      }
                    >
                      {intl.formatMessage({ id: "ACTION" })}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <ul className="navi navi-hover">
                        {shouldShowCollectionCompleteButton && (
                          <li
                            className="navi-item"
                            onClick={() => {
                              setTitle("collection");
                              setDialogSave({
                                type: true,
                                complete: true,
                                collection: true,
                              });
                            }}
                          >
                            <span className="navi-link pointer">
                              <span className="navi-text">
                                {intl.formatMessage({ id: "Complete" })}
                              </span>
                            </span>
                          </li>
                        )}
                        {shouldShowCancelButton && (
                          <li
                            className="navi-item"
                            onClick={() => {
                              setTitle("collection");
                              setDialogSave({ type: true, cancel: true });
                            }}
                          >
                            <span
                              className="navi-link pointer"
                              id="kt_stripedetail_panel_toggle"
                            >
                              <span className="navi-text">
                                {intl.formatMessage({ id: "CANCEL" })}
                              </span>
                            </span>
                          </li>
                        )}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {orderDetail?.collection_address_line ? (
                  <div className="col-xl-12 mb-2">
                    <strong>Collection Address : </strong>
                    {orderDetail?.collection_address_line?.replaceAll(
                      ",",
                      ", "
                    )}
                    {orderDetail?.collection_postcode
                      ? `, ${orderDetail?.collection_postcode}`
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-xl-6 mb-2">
                  <strong>Date</strong>:{" "}
                  {moment(orderDetail?.collection_date).format("DD-MM-YYYY")}
                </div>
                <div className="col-xl-6 mb-2">
                  <strong>{intl.formatMessage({ id: "Time" })}</strong>:{" "}
                  {orderDetail?.start_time_slot}
                  {orderDetail?.start_time_slot !== orderDetail?.end_time_slot
                    ? "-" + orderDetail?.end_time_slot
                    : ""}
                </div>
                <div className="col-xl-12 mb-2">
                  <strong>{intl.formatMessage({ id: "Depot" })}</strong> :{" "}
                  {orderDetail?.depot_details?.name || "-"}&nbsp;&nbsp;
                  <LightTooltip title={depotTooltip}>
                    <InfoIcon className="iIcon" />
                  </LightTooltip>
                </div>
                {orderDetail?.is_student !== 1 ? (
                  <>
                    <div className="col-xl-5 mb-2">
                      <FormControl fullWidth>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          IconComponent={(props) => (
                            <i {...props}>
                              <KeyboardArrowDownIcon />
                            </i>
                          )}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          name="collection_driver_id"
                          className={`form-control w-100  `}
                          {...formik.getFieldProps("collection_driver_id")}
                        >
                          <MenuItem value="">Driver</MenuItem>
                          {driverLoader?.data?.drivers?.map((item, index) => (
                            <MenuItem value={item?.user?.id} key={index}>
                              {item?.user?.first_name || "-"}&nbsp;
                              {item?.user?.last_name || ""}&nbsp;|&nbsp;
                              {item?.user?.phone}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-xl-5 mb-2 loader-dropdown ">
                      <Dropdown
                        className="dropdown-inline"
                        drop="down"
                        alignRight
                      >
                        <Dropdown.Toggle
                          id="dropdown-toggle-top2"
                          variant="transparent"
                          className="btn btn-light   dropdown-toggle text-dark-75"
                          title={""}
                        >
                          Loader
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover">
                            {driverLoader?.data?.loaders?.map((item, index) => (
                              <li className="navi-item">
                                <>
                                  <span className="navi-text">
                                    <label className="checkbox">
                                      <input
                                        type="checkbox"
                                        name={`collection_loader_id[${index}]`}
                                        checked={
                                          (formik.values
                                            ?.collection_loader_id &&
                                            formik.values?.collection_loader_id
                                              .length > 0 &&
                                            formik.values?.collection_loader_id?.some(
                                              (e) => e === item?.user?.id
                                            )) ||
                                          false
                                        }
                                        onChange={(e) =>
                                          handleSetCheck(
                                            e,
                                            index,
                                            item?.user?.id,
                                            "collection"
                                          )
                                        }
                                      />
                                      <span className="mr-2"></span>{" "}
                                      {item?.user?.first_name || "-"}
                                      &nbsp;
                                      {item?.user?.last_name || ""} |
                                      {item?.user?.phone}
                                    </label>
                                  </span>
                                </>
                              </li>
                            )) || ""}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-xl-2 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary font-weight-bolder font-size-sm mr-3 btn-sm"
                        onClick={() =>
                          handleSubmit(formik?.values, "collection")
                        }
                      >
                        {intl.formatMessage({ id: "SAVE" })}
                      </button>
                      {/* <button
                        className="btn btn-light font-weight-bolder font-size-sm mr-3 btn-sm"
                        id="kt_DriverNotes_panel_toggle"
                        type="text"
                      >
                        {intl.formatMessage({ id: "Driver.Note" })}
                      </button> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              {orderDetail?.is_return === 1 && (
                <div
                  style={{ borderBottom: "1px solid #ccc", margin: "10px 0px" }}
                >
                  {" "}
                </div>
              )}
              <div className="row mt-4 align-items-center order-collection">
                {/* {orderDetail?.is_invoices_due === 1 && (
                  <div>
                    <p style={{ color: "red" }}>
                      Return details are not accessible due to failed payment at
                      the time of book return.
                    </p>
                    <button
                      className="btn btn-primary font-weight-bolder font-size-sm mr-3 btn-sm"
                      type="button"
                      onClick={() => setDialogDue({ check: true })}
                    >
                      Show Return Detail
                    </button>
                  </div>
                )} */}
                {orderDetail?.is_return === 1 && (
                  <>
                    <div className="w-100 d-flex justify-content-between">
                      <h5 className="card-title align-items-start flex-column mb-2 ml-4">
                        <span className="font-weight-bolder text-dark">
                          Return Details{" "}
                          <sup className="top-4">
                            <span
                              class={`badge badge-pill ${
                                orderDetail?.is_return_completed === 1
                                  ? "badge-success"
                                  : "badge-danger"
                              }`}
                            >
                              {orderDetail?.is_return_completed === 1
                                ? "Completed"
                                : `Not completed`}
                            </span>
                          </sup>
                          &nbsp;
                          {billingCard?.in_arrears > 0 && (
                            <sup className="top-4">
                              <span
                                class={`badge badge-pill ${"badge-danger"}`}
                              >
                                {"Payment due"}
                              </span>
                            </sup>
                          )}
                        </span>
                      </h5>
                      <Dropdown
                        className="dropdown-inline mr-4"
                        drop="down"
                        alignRight
                      >
                        <Dropdown.Toggle
                          id="dropdown-toggle-top2"
                          variant="transparent"
                          className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                          disabled={
                            !shouldShowCompleteButton && !shouldShowCancelButton
                          }
                        >
                          {intl.formatMessage({ id: "ACTION" })}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover">
                            {shouldShowCompleteButton && (
                              <li
                                className="navi-item"
                                onClick={() => {
                                  setTitle("return");
                                  setDialogSave({
                                    type: true,
                                    complete: true,
                                    return: true,
                                  });
                                }}
                              >
                                <span
                                  id="kt_franchisepostcodes_panel_toggle"
                                  className="navi-link pointer"
                                >
                                  <span className="navi-text">
                                    {intl.formatMessage({ id: "Complete" })}
                                  </span>
                                </span>
                              </li>
                            )}
                            {shouldShowCancelButton && (
                              <li
                                className="navi-item"
                                onClick={() => {
                                  setTitle("return");
                                  setDialogSave({ type: true, cancel: true });
                                }}
                              >
                                <span
                                  className="navi-link pointer"
                                  id="kt_stripedetail_panel_toggle"
                                >
                                  <span className="navi-text">
                                    {intl.formatMessage({ id: "CANCEL" })}
                                  </span>
                                </span>
                              </li>
                            )}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {orderDetail?.collection_address_line ||
                    orderDetail?.return_address_line ? (
                      <div className="col-xl-12 mb-2">
                        <strong>Return Address :</strong>
                        {orderDetail?.return_address_line}
                        {orderDetail?.return_postcode
                          ? `, ${orderDetail?.return_postcode}`
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-xl-6 mb-2">
                      <strong>{intl.formatMessage({ id: "Date" })}</strong>:{" "}
                      {(orderDetail?.return_date &&
                        orderDetail?.is_return === 1 &&
                        moment(orderDetail?.return_date).format(
                          "DD-MM-YYYY"
                        )) ||
                        "-"}
                    </div>
                    <div className="col-xl-6 mb-2">
                      <strong>{intl.formatMessage({ id: "Time" })}</strong>:
                      {(orderDetail?.is_return === 1 &&
                        orderDetail?.return_start_time_slot) ||
                        ""}
                      {orderDetail?.is_return === 1 &&
                        orderDetail?.return_end_time_slot &&
                        orderDetail?.return_start_time_slot !==
                          orderDetail?.return_end_time_slot &&
                        "-" +
                          moment(
                            orderDetail?.return_date +
                              "T" +
                              orderDetail?.return_end_time_slot
                          )
                            .subtract(0, "hours")
                            .format("HH:mm")}
                    </div>
                    {orderDetail.partial_return &&
                    orderDetail.partial_return.length ? (
                      <div className="col-xl-6 mb-2 ">
                        <strong>Partial Return Pod Number</strong>:{" "}
                        {handleArrayPartial()}
                        {/* {orderDetail.partial_return.map((item, index) => {
                              const arr = [...podList];
                              const podLists = item?.pod_ids.split(",");
                              // arr.push(...podLists);
                              // setPodList(arr);
                              return (
                                <>
                                  {podList &&
                                    podList.length &&
                                    podList.map((id, i) => {
                                      const title = orderDetail?.pod_storage_details.find(
                                        (e) => e.id === Number(id)
                                      );
                                      return (
                                        <>
                                          {index === 0 || i === 0 ? "" : ", "}
                                          {title?.pod_number || "N/A"}
                                        </>
                                      );
                                    })}
                                </>
                              );
                            })} */}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-xl-12 mb-2">
                      <strong>{intl.formatMessage({ id: "Depot" })}</strong>:{" "}
                      {orderDetail?.depot_details?.name || "-"}
                    </div>
                    {orderDetail?.is_student_return !== 1 ? (
                      <>
                        <div className="col-xl-3 mb-2">
                          <FormControl fullWidth>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              IconComponent={(props) => (
                                <i {...props}>
                                  <KeyboardArrowDownIcon />
                                </i>
                              )}
                              inputProps={{ "aria-label": "Without label" }}
                              displayEmpty
                              name="driver_id"
                              className={`form-control w-100  `}
                              {...formik.getFieldProps("driver_id")}
                            >
                              <MenuItem value="">
                                {intl.formatMessage({ id: "Driver" })}
                              </MenuItem>
                              {driverLoader?.data?.drivers?.map(
                                (item, index) => (
                                  <MenuItem value={item?.user?.id} key={index}>
                                    {item?.user?.first_name || "-"}&nbsp;
                                    {item?.user?.last_name || ""}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-xl-3 mb-2 loader-dropdown ">
                          <Dropdown
                            className="dropdown-inline"
                            drop="down"
                            alignRight
                          >
                            <Dropdown.Toggle
                              id="dropdown-toggle-top2"
                              variant="transparent"
                              className="btn btn-light   dropdown-toggle text-dark-75"
                              title={
                                <span>
                                  <i className="fad fa-newspaper"></i> Dropdown
                                </span>
                              }
                            >
                              Loader
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                              <ul className="navi navi-hover">
                                {driverLoader?.data?.loaders?.map(
                                  (item, index) => (
                                    <li className="navi-item">
                                      <>
                                        <span className="navi-text">
                                          <label className="checkbox">
                                            <input
                                              type="checkbox"
                                              name={`loader_id[${index}]`}
                                              checked={
                                                (formik.values?.loader_id &&
                                                  formik.values?.loader_id
                                                    .length > 0 &&
                                                  formik.values?.loader_id?.some(
                                                    (e) => e === item?.user?.id
                                                  )) ||
                                                false
                                              }
                                              onChange={(e) =>
                                                handleSetCheck(
                                                  e,
                                                  index,
                                                  item?.user?.id
                                                )
                                              }
                                            />
                                            <span className="mr-2"></span>{" "}
                                            {item?.user?.first_name || "-"}
                                            &nbsp;
                                            {item?.user?.last_name || ""}
                                          </label>
                                        </span>
                                      </>
                                    </li>
                                  )
                                ) || ""}
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="col-xl-6 mb-2">
                          <button
                            className="btn btn-primary font-weight-bolder font-size-sm mr-3 btn-sm"
                            type="button"
                            onClick={() =>
                              handleSubmit(formik?.values, "return")
                            }
                          >
                            {intl.formatMessage({ id: "SAVE" })}
                          </button>
                        </div>
                        {orderDetail?.pod_storage_details &&
                          orderDetail?.pod_storage_details.length > 0 &&
                          orderDetail?.pod_storage_details.map(
                            (item, index) => (
                              <div className="row  w-100 ml-2 mr-2" key={index}>
                                <div className="col-sm-4 mt-2 mb-2">
                                  <div className="d-flex justify-content-between align-items-center storage-option">
                                    <div>
                                      {" "}
                                      <strong>
                                        {orderDetail?.is_container === 1
                                          ? "Container"
                                          : "Pod"}{" "}
                                        Number:
                                      </strong>
                                      &nbsp;{item.pod_number}
                                    </div>
                                    <div> </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 mt-2 mb-2">
                                  <div className="d-flex justify-content-between align-items-center storage-option">
                                    <div>
                                      <strong>Seal&nbsp;Number&nbsp;1:</strong>
                                      &nbsp;
                                      {item.seal_number_one}{" "}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                                <div className="col-sm-4 mt-2 mb-2">
                                  <div className="d-flex justify-content-between align-items-center storage-option">
                                    <div>
                                      <strong>Seal&nbsp;Number&nbsp;2:</strong>
                                      &nbsp;
                                      {item.seal_number_two}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        <div></div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {orderDetail?.is_return !== 1 &&
                orderDetail.partial_return &&
                orderDetail.partial_return.length &&
                (handleArrayPartial().length > 0 ||
                  handleArrayCompletePartial().length > 0) ? (
                  <>
                    <div
                      className="w-100 mr-4 ml-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "10px 0px",
                      }}
                    >
                      {" "}
                    </div>
                    <div className="w-100 mr-4 d-flex justify-content-between">
                      <div>
                        <h5 className="card-title align-items-start flex-column mb-2 ml-4">
                          <span className="font-weight-bolder text-dark">
                            Partial Return&nbsp;
                            <sup className="top-4">
                              <span
                                class={`badge badge-pill ${
                                  handleArrayCompletePartial().length > 0 &&
                                  handleArrayPartial().length < 1
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {handleArrayCompletePartial().length > 0 &&
                                handleArrayPartial().length < 1
                                  ? "Completed"
                                  : "Not completed"}
                              </span>
                            </sup>
                          </span>
                        </h5>
                      </div>
                      <Dropdown
                        className="dropdown-inline "
                        drop="down"
                        alignRight
                      >
                        <Dropdown.Toggle
                          id="dropdown-toggle-top2"
                          variant="transparent"
                          className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                          disabled={
                            (!shouldShowCompleteButton &&
                              !shouldShowCancelButton) ||
                            handleArrayPartial().length < 1
                          }
                        >
                          {intl.formatMessage({ id: "ACTION" })}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="navi navi-hover">
                            {shouldShowCompleteButton &&
                              orderDetail?.partial_return?.find(
                                (item) => item.is_partial_return_completed !== 1
                              ) && (
                                <li
                                  className="navi-item"
                                  onClick={() => {
                                    setTitle("return");
                                    setTest("par");
                                    setDialogSave({
                                      type: true,
                                      complete: true,
                                    });
                                  }}
                                >
                                  <span
                                    id="kt_franchisepostcodes_panel_toggle"
                                    className="navi-link pointer"
                                  >
                                    <span className="navi-text">
                                      {intl.formatMessage({ id: "Complete" })}
                                    </span>
                                  </span>
                                </li>
                              )}

                            {shouldShowCancelButton &&
                              orderDetail?.partial_return?.find(
                                (item) => item.is_partial_return_completed !== 1
                              ) && (
                                <li
                                  className="navi-item"
                                  onClick={() => {
                                    setTitle("return");
                                    setDialogSave({ type: true, cancel: true });
                                  }}
                                >
                                  <span
                                    className="navi-link pointer"
                                    id="kt_stripedetail_panel_toggle"
                                  >
                                    <span className="navi-text">
                                      {intl.formatMessage({ id: "CANCEL" })}
                                    </span>
                                  </span>
                                </li>
                              )}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {handleArrayPartial().length > 0 && (
                      <div className="col-xl-12 mb-2 mt-4 d-flex justify-content-between">
                        <div>
                          <strong>
                            Partial Return Pod Number(
                            {
                              orderDetail.partial_return.find(
                                (e) => e.is_partial_return_completed !== 1
                              )?.partial_returns_date
                            }
                            )
                          </strong>
                          :{" "}
                          {handleArrayPartial().length > 0
                            ? handleArrayPartial()
                            : "-"}
                        </div>
                      </div>
                    )}
                    {handleArrayCompletePartial().length > 0 && (
                      <div className="col-xl-12 mb-2 mt-4 d-flex justify-content-between">
                        <div>
                          <strong>Partial Return Complete Pod Number</strong>:{" "}
                          {handleArrayCompletePartial().length > 0
                            ? handleArrayCompletePartial()
                            : "-"}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* {orderDetail?.is_return !== 1 &&
                orderDetail.partial_return &&
                orderDetail.partial_return.length ? (
                  <>
                    <div
                      className="w-100 mr-4 ml-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "10px 0px",
                      }}
                    >
                      {" "}
                    </div>
                    <div className="w-100 mr-4 d-flex justify-content-between">
                      <div>
                        <h5 className="card-title align-items-start flex-column mb-2 ml-4">
                          <span className="font-weight-bolder text-dark">
                            Completed Partial Return &nbsp;
                            <sup className="top-4">
                              <span
                                class={`badge badge-pill ${
                                  handleArrayCompletePartial().length > 0 &&
                                  handleArrayPartial().length < 1
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {handleArrayCompletePartial().length > 0 &&
                                handleArrayPartial().length < 1
                                  ? "Completed"
                                  : "Not completed"}
                              </span>
                            </sup>
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-xl-12 mb-2 mt-4 d-flex justify-content-between">
                      <div>
                        <strong>Partial Return Complete Pod Number</strong>:
                        {handleArrayCompletePartial().length > 0
                          ? handleArrayCompletePartial()
                          : "-"}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
              {orderDetail?.access_date &&
                orderDetail?.is_student_access === 1 && (
                  <>
                    {" "}
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        margin: "10px 0",
                      }}
                    ></div>
                    <div className="row mt-4 align-items-center order-collection">
                      <div className="w-100 d-flex justify-content-between ">
                        <h5 className="card-title align-items-start flex-column mb-2 ml-4">
                          <span className="font-weight-bolder text-dark">
                            Access Details
                            <sup
                              // style={{
                              //   color:
                              //     orderDetail?.is_collection_completed === 1
                              //       ? "green"
                              //       : "red",
                              // }}
                              className="top-4"
                            >
                              <span
                                class={`badge badge-pill ${
                                  orderDetail?.is_access_completed === 1
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {orderDetail?.is_access_completed === 1
                                  ? "Completed"
                                  : "Not completed"}
                              </span>
                              {/* <span className="badge badge-light-success"> </span> */}

                              {/* {orderDetail?.is_collection_completed === 1
                          ? "completed"
                          : "not completed"} */}
                            </sup>
                          </span>
                        </h5>
                        <Dropdown
                          className="dropdown-inline mr-4"
                          drop="down"
                          alignRight
                        >
                          <Dropdown.Toggle
                            id="dropdown-toggle-top2"
                            variant="transparent"
                            className="btn btn-light  font-size-sm font-weight-bolder dropdown-toggle text-dark-75"
                            disabled={
                              (shouldShowAccessCompleteButton &&
                                shouldShowAccessCancelButton &&
                                !shouldShowAccessButton) ||
                              orderDetail?.is_access_completed === 1
                            }
                          >
                            {intl.formatMessage({ id: "ACTION" })}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <ul className="navi navi-hover">
                              {!shouldShowAccessCompleteButton && (
                                <li
                                  className="navi-item"
                                  onClick={() => {
                                    setTitle("access");
                                    setDialogSave({
                                      type: true,
                                      complete: true,
                                      access: true,
                                    });
                                  }}
                                >
                                  <span
                                    id="kt_franchisepostcodes_panel_toggle"
                                    className="navi-link pointer"
                                  >
                                    <span className="navi-text">
                                      {intl.formatMessage({ id: "Complete" })}
                                    </span>
                                  </span>
                                </li>
                              )}
                              {!shouldShowAccessCancelButton && (
                                <li
                                  className="navi-item"
                                  onClick={() => {
                                    setTitle("access");
                                    setDialogSave({
                                      type: true,
                                      cancel: true,
                                      access: true,
                                    });
                                  }}
                                >
                                  <span
                                    className="navi-link pointer"
                                    id="kt_stripedetail_panel_toggle"
                                  >
                                    <span className="navi-text">
                                      {intl.formatMessage({ id: "CANCEL" })}
                                    </span>
                                  </span>
                                </li>
                              )}
                              {/* {shouldShowAccessButton && (
                                <li
                                  className="navi-item"
                                  onClick={() =>
                                    setDialogAccess({ check: true })
                                  }
                                >
                                  <span
                                    className="navi-link pointer"
                                    id="kt_termscondition_panel_toggle"
                                  >
                                    <span className="navi-text"> Charge</span>
                                  </span>
                                </li>
                              )} */}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-6 mb-2">
                        <strong>{intl.formatMessage({ id: "Date" })}</strong>:{" "}
                        {(orderDetail?.access_date &&
                          moment(orderDetail?.access_date).format(
                            "DD-MM-YYYY"
                          )) ||
                          "-"}
                      </div>
                      {orderDetail?.access_start_time_slot && (
                        <div className="col-xl-6 mb-2">
                          <strong>{intl.formatMessage({ id: "Time" })}</strong>:{" "}
                          {orderDetail?.access_start_time_slot}
                        </div>
                      )}
                      {orderDetail?.access_ids && (
                        <div className="col-xl-6 mb-2">
                          <strong>Access Pod Number</strong>:{" "}
                          {/* {orderDetail?.access_ids} */}
                          {orderDetail?.access_ids &&
                            orderDetail?.access_ids
                              .split(",")
                              .map((item, index) => {
                                const title = orderDetail?.pod_storage_details.find(
                                  (e) => e.id === Number(item)
                                );
                                return (
                                  <>
                                    {title?.pod_number || "N/A"}

                                    {index ===
                                    orderDetail?.access_ids.split(",").length -
                                      1
                                      ? ""
                                      : ", "}
                                  </>
                                );
                              })}
                        </div>
                      )}
                      <div className="col-xl-12 mb-2">
                        <strong>{intl.formatMessage({ id: "Depot" })}</strong>:{" "}
                        {orderDetail?.depot_details?.name || "-"}&nbsp;&nbsp;
                        <LightTooltip title={depotTooltipAccess}>
                          <InfoIcon className="iIcon" />
                        </LightTooltip>
                      </div>
                    </div>
                  </>
                )}
            </form>
          </div>
        </div>
      </div>
      <CollectionEdit
        title={"Job Date"}
        type="order"
        keys={title}
        setKeys={setTitle}
        setDialog={setDialog}
        dialog={dialog}
        depotList={depotFraList}
        franchise={orderDetail?.franchise_id}
        // value={value}
        orderDetail={orderDetail}
        initialValues={orderCollectionValue(moment, orderDetail, title)}
      />
      <CustomeOrderSaveDialog
        header={
          dialogSave?.cancel
            ? intl.formatMessage({ id: "Cancel.job" })
            : dialogSave?.complete
            ? intl.formatMessage({ id: "Complete.job" })
            : ""
        }
        mainText={
          dialogSave?.cancel
            ? intl.formatMessage({ id: "Are.you.cancel.booking" })
            : dialogSave?.access && dialogSave?.complete
            ? "Are you sure you want to complete this Access?"
            : dialogSave?.complete
            ? intl.formatMessage({ id: "Are.you.complete.booking" })
            : ""
        }
        dialog={dialogSave}
        setDialog={setDialogSave}
        type={dialogSave?.check === "overwrite" ? "overwrite" : "order"}
        formik={formik}
        handleAction={
          dialogSave?.cancel || dialogSave?.complete ? handleCancel : ""
        }
        dialogCondition={
          dialogSave?.cancel ? dialogSave?.cancel : dialogSave?.complete
        }
        dialogConditionCheck={dialogSave?.cancel ? dialogSave?.cancel : false}
        isLoading={isLoading}
        check={"order"}
        showCheck={title}
        orderDetail={orderDetail}
        accessAmount={accessAmount}
        setAccess={setAccess}
        franchiseId={orderDetail?.franchise_id}
      />
      {dialogAccess?.test && (
        <ParModal
          dialog={dialogAccess}
          setDialog={setDialogAccess}
          orderDetail={orderDetail}
        />
      )}
      {dialogAccess?.check && (
        <BookingAccessModal
          dialog={dialogAccess}
          setDialog={setDialogAccess}
          orderDetail={orderDetail}
        />
      )}
      {dialogDue?.check && (
        <DueInvoice
          dialog={dialogDue}
          setDialog={setDialogDue}
          orderDetail={orderDetail}
        />
      )}
      {dialogAccessBefore && (
        <AccessAsk
          dialog={dialogAccessBefore}
          setDialog={setDialogAccessBefore}
          handleSetKey={handleSetKey}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
}
