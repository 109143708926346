import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
//import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
//import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual } from "react-redux";
import { NavLink } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";

// import NotAuthorized from "../../../_partials/widgets/NotAuthorized";

export function Topbar() {
  const { permission } = useSelector(
    (state) => ({
      permission: state.auth.permission,
    }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      /*viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),*/
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      /*viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),*/
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);
  const intl = useIntl();
  const history = useHistory();
  const handleRedirect = (value) => {
    history.push(value);
  };
  return (
    <>
      <div style={{ width: "60%" }}>
        {layoutProps.viewSearchDisplay && <SearchDropdown />}
      </div>
      <div className="topbar">
        {/* <a
          target="_blank"
          href="https://calendly.com/akhil-sharma-01/vault-1-1-discussion"
          className="mr-3"
          rel="noopener noreferrer"
          style={{
            height: "43px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline", // Adds underline
          }}
        >
          Book Meeting
        </a>
        <a
          target="_blank"
          href="https://estorageworldwide.sharepoint.com/:x:/g/EasAmP1Yyc5FjCkM5bu6GPoBsk23OY_iek7sipbZb-XkqQ?e=7WiL6X"
          rel="noopener noreferrer"
          style={{
            height: "43px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            textDecoration: "underline", // Adds underline
          }}
          className="mr-3"
        >
          Resources
        </a> */}
        <button
          style={{ height: "38.8px" }}
          className="btn btn-primary font-size-sm font-weight-bolder mt-1 mr-3"
          onClick={() => handleRedirect("/new-order-stripe")}
        >
          Create Order
        </button>

        {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

        {permission?.setting?.view ? (
          <>
            <Link to="/setting-page">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-notification-tooltip">
                    {intl.formatMessage({ id: "SETTINGS" })}
                  </Tooltip>
                }
              >
                <div className="btn btn-icon btn-clean btn-lg mr-1">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-2.svg"
                      )}
                    />
                  </span>
                </div>
              </OverlayTrigger>
            </Link>
          </>
        ) : (
          ""
        )}

        {/* <LanguageSelectorDropdown /> */}

        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </>
  );
}
