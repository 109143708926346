/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useFormik } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SnackBarTool from "../../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

import DiscardDialog from "../../../../../../_metronic/_partials/widgets/alert/DiscardDialog";
import { LoadingCustome } from "../../../../../../_metronic/_partials/controls/LoadingCustome";
import {
  automatePrice,
  createSite,
} from "../../../_redux/franchises/franchisesCrud";
import { AutomateValidation } from "../franchise-form/validateSchema";
import {
  fetchFranchisesDetail,
  fetchSiteList,
} from "../../../_redux/franchises/franchisesActions";
import { useIntl } from "react-intl";
import ImageUploading from "react-images-uploading";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import { imageUrlToFile } from "../../../../../utils/commonFunction";
import _ from "lodash";

const SiteModal = ({ dialog, setDialog, franchiseId, franchData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [dialogDis, setDialogDis] = useState(false);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const [loading, setLoading] = useState(false);
  const initialValues = {
    franchise_id: franchData?.id,
    name: dialog?.data?.name || "",
    capacity: dialog?.data?.capacity || "",
    _20ft_capacity: dialog?.data?._20ft_capacity || "",
    _5ft_capacity: dialog?.data?._5ft_capacity || "",
    _6ft_capacity: dialog?.data?._6ft_capacity || "",
    _8ft_capacity: dialog?.data?._8ft_capacity || "",
    car_parking_capacity: "",
    manager_name: dialog?.data?.manager_name || "",
    manager_email: dialog?.data?.manager_email || "",
    manager_phone: dialog?.data?.manager_phone || "",
    address: dialog?.data?.address || "",
    latitude: dialog?.data?.latitude || "",
    longitude: dialog?.data?.longitude || "",
    available_from: dialog?.data?.available_from || "",
    is_default: dialog?.data?.is_default || "",
    additional_email_address: dialog?.data?.additional_email_address || "",
    key_holder_responder_name: dialog?.data?.key_holder_responder_name || "",
    key_holder_responder_number:
      dialog?.data?.key_holder_responder_number || "",
    access_control_emergency_name:
      dialog?.data?.access_control_emergency_name || "",
    access_control_emergency_number:
      dialog?.data?.access_control_emergency_number || "",
    images: dialog?.data?.image || [],
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      // Handle the 'images' separately, as it is an array of files
      if (key === "images") {
        values[key].forEach((file, index) => {
          formData.append(`${key}[${index}]`, file);
        });
      } else {
        formData.append(key, values[key]);
      }
    });
    setSnack({ call: false, type: "", message: "" });
    createSite(
      formData,
      dialog?.data ? "/" + dialog?.data.id + "?_method=PATCH" : ""
    )
      .then((res) => {
        setIsLoading(false);
        dispatch(fetchSiteList(franchData?.id));
        // dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchFranchisesDetail(values?.franchise_id));
        setSnack({ call: true, type: "success", message: res.data.message });
        setTimeout(() => {
          setDialog({ check: false });
          setSnack({ call: false, type: "", message: "" });
          formik.resetForm();
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error.response.data.message,
        });
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: AutomateValidation,
    onSubmit: handleSubmit,
  });
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    const arr = imageList.map((e) => e?.file || e);
    setImages(arr);

    formik.setFieldValue("images", arr);
  };
  const handleClose = () => {
    formik.resetForm();

    setDialog({ check: false });
  };
  useEffect(() => {
    const convertImages = async () => {
      if (dialog?.data?.image?.length > 0) {
        const convertedFiles = [];
        for (const imageData of dialog.data.image) {
          try {
            const file = await imageUrlToFile(imageData.image);
            convertedFiles.push(file);
          } catch (error) {
            console.error("Error converting image:", error);
          }
        }
        setImages(convertedFiles);
        formik.setFieldValue("images", convertedFiles);
      }
    };

    convertImages();
  }, [dialog?.data?.image]);

  return (
    <div
      id="kt_site_panel"
      className="offcanvas offcanvas-right pt-5 pb-10 offcanvas-on "
    >
      {/*begin::Header*/}
      <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
        <div
          className="offcanvas-close mt-n1 pr-5"
          style={{ position: "absolute", top: "15px", right: "10px" }}
        >
          <span
            onClick={handleClose}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_site_panel_close"
          >
            <i className="ki ki-close icon-sm text-muted"></i>
          </span>
        </div>
      </div>
      {/*end::Header*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="offcanvas-content px-10 date-picker ">
          <div>
            <h5>Site</h5>
          </div>
          <div className="tab-content">
            <div
              id="kt_quick_panel_logs_kt_site_panel"
              className="tab-pane fade pt-3 pr-5 mr-n5 custom-scroll  active show"
              role="tabpanel"
            >
              <div className="mt-10 pb-10">
                <div className="row">
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Site Name</span>
                    <input
                      type="text"
                      name="name"
                      placeholder={"Site Name"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">20ft Capacity​</span>
                    <input
                      type="text"
                      name="_20ft_capacity"
                      placeholder={"20ft Capacity​"}
                      label="20ft Capacity​"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("_20ft_capacity")}
                    />
                    {formik.touched._20ft_capacity &&
                    formik.errors._20ft_capacity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors._20ft_capacity}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">5ft Capacity</span>
                    <input
                      type="text"
                      name="_5ft_capacity"
                      placeholder={"5ft Capacity"}
                      label="Capacity"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("_5ft_capacity")}
                    />
                    {formik.touched._5ft_capacity &&
                    formik.errors._5ft_capacity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors._5ft_capacity}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">6ft Capacity</span>
                    <input
                      type="text"
                      name="_6ft_capacity"
                      placeholder={"6ft Capacity"}
                      label="6ft Capacity"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("_6ft_capacity")}
                    />
                    {formik.touched._6ft_capacity &&
                    formik.errors._6ft_capacity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors._6ft_capacity}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">8ft Capacity​</span>
                    <input
                      type="text"
                      name="_8ft_capacity"
                      placeholder={"8ft Capacity​"}
                      label="8ft Capacity​"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("_8ft_capacity")}
                    />
                    {formik.touched._8ft_capacity &&
                    formik.errors._8ft_capacity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors._8ft_capacity}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Car Parking Capacity</span>
                    <input
                      type="text"
                      name="car_parking_capacity"
                      placeholder={"Car Parking Capacity"}
                      label="Car Parking Capacity"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("car_parking_capacity")}
                    />
                    {formik.touched.car_parking_capacity &&
                    formik.errors.car_parking_capacity ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.car_parking_capacity}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Manager Name</span>
                    <input
                      type="text"
                      name="manager_name"
                      placeholder={"Manager Name"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("manager_name")}
                    />
                    {formik.touched.manager_name &&
                    formik.errors.manager_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Manager Email</span>
                    <input
                      type="text"
                      name="manager_email"
                      placeholder={"Manager Email"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("manager_email")}
                    />
                    {formik.touched.manager_email &&
                    formik.errors.manager_email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Manager Phone</span>
                    <input
                      type="text"
                      name="manager_phone"
                      placeholder={"Manager Phone"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("manager_phone")}
                    />
                    {formik.touched.manager_phone &&
                    formik.errors.manager_phone ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.manager_phone}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Site Address</span>
                    <input
                      type="text"
                      name="address"
                      placeholder={"Site Address"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("address")}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-5">
                    <label>{intl.formatMessage({ id: "From.Date" })}</label>
                    <InputGroup className="calendr">
                      <DatePicker
                        fullWidth
                        fixedHeight
                        className="form-control"
                        wrapperClassName="datepicker"
                        selected={Date.parse(formik?.values.available_from)}
                        // startDate={Date.parse(formik?.values.blocking_date_from)}
                        // endDate={Date.parse(formik?.values.blocking_date_to)}
                        dateFormat="dd-MM-Y"
                        placeholderText="Select From Date"
                        onChange={(fromDate) => {
                          formik.setFieldValue(
                            `available_from`,
                            moment(fromDate).format("yyyy-MM-DD")
                          );
                        }}
                        minDate={new Date()}
                      />
                      <CalendarTodayIcon />
                    </InputGroup>
                    {formik.touched.blocking_date_from &&
                    formik.errors.blocking_date_from ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.blocking_date_from}
                        </div>
                      </div>
                    ) : null}
                    {formik.touched.blocking_date_to &&
                    !formik.errors.blocking_date_from &&
                    formik.errors.blocking_date_to ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.blocking_date_to}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Lat</span>
                    <input
                      type="text"
                      name="latitude"
                      placeholder={"Lat"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("latitude")}
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.latitude}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Long</span>
                    <input
                      type="text"
                      name="longitude"
                      placeholder={"Long"}
                      label="External Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("longitude")}
                    />
                    {formik.touched.longitude && formik.errors.longitude ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.longitude}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Additional Email Address</span>
                    <input
                      type="text"
                      name="additional_email_address"
                      placeholder={"Email"}
                      label="Email"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("additional_email_address")}
                    />
                    {formik.touched.additional_email_address &&
                    formik.errors.additional_email_address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.additional_email_address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Key Holder Responder Name</span>
                    <input
                      type="text"
                      name="key_holder_responder_name"
                      placeholder={"name"}
                      label="name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("key_holder_responder_name")}
                    />
                    {formik.touched.key_holder_responder_name &&
                    formik.errors.key_holder_responder_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.key_holder_responder_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Key Holder Responder Number</span>
                    <input
                      type="text"
                      name="key_holder_responder_number"
                      placeholder={"Number"}
                      label="Number"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("key_holder_responder_number")}
                    />
                    {formik.touched.key_holder_responder_number &&
                    formik.errors.key_holder_responder_number ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.key_holder_responder_number}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">Access Control Emergency Name</span>
                    <input
                      type="text"
                      name="access_control_emergency_name"
                      placeholder={"Name"}
                      label="Name"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps("access_control_emergency_name")}
                    />
                    {formik.touched.access_control_emergency_name &&
                    formik.errors.access_control_emergency_name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.access_control_emergency_name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-6 mb-4">
                    <span className="mt-3 ">
                      Access Control Emergency Number
                    </span>
                    <input
                      type="text"
                      name="access_control_emergency_number"
                      placeholder={"Number"}
                      label="Number"
                      className={`form-control  `}
                      // max={3}
                      // maxLength="3"
                      {...formik.getFieldProps(
                        "access_control_emergency_number"
                      )}
                    />
                    {formik.touched.access_control_emergency_number &&
                    formik.errors.access_control_emergency_number ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.access_control_emergency_number}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12 mb-4">
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            type="button"
                            className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or Drop here
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-primary font-weight-bolder font-size-sm "
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={URL.createObjectURL(image)}
                                alt=""
                                width="100"
                              />
                              <div className="image-item__btn-wrapper">
                                <button
                                  type="button"
                                  className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary font-weight-bolder font-size-sm "
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="offcanvas-content text-right ">
            <button
              type="reset"
              className="btn btn-dark font-weight-bolder font-size-sm mr-3 "
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
            <button
              type="submit"
              className="btn btn-primary font-weight-bolder font-size-sm "
              disabled={isLoading}
            >
              {intl.formatMessage({ id: "SAVE" })}
              {isLoading && (
                <span className="ml-1 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </form>
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};

export default SiteModal;
