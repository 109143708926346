import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const EditOrderTableAction = ({
  setDialog,
  subItem,
  orderUIContext,
  editOrderDetail,
  state,
  dispatchReducer,
  index,
  pageOn,
  // value
}) => {
  const handleRemoveProduct = () => {
    const phaseIndex = state.phases.findIndex((e) => e.is_edit);

    let phase = state.phases.find((e) => e.is_edit);
    const arr = [...phase.items];
    // Create a shallow copy of the items array
    arr.splice(index, 1);

    // Update the state immutably
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        phases: state.phases.map((phase, i) =>
          i === phaseIndex ? { ...phase, items: arr } : phase
        ),
      },
    });
  };
  const handleRemoveProductRelease = () => {
    const arr = [...state.items.data];
    // Create a shallow copy of the items array
    // arr.splice(index, 1);
    arr[index].deleted = true;

    // Update the state immutably
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        items: { ...state.items, data: arr },
        is_call_upcoming: true,
      },
    });
  };
  return (
    <div>
      <Dropdown
        //   onClick={() => setCheck(check ? false : true)}
        className="dropdown-inline"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          id="dropdown-toggle-top2"
          className="actionEditList"
          variant="transparent"
          style={{ backgroundImage: "none !important" }}
        >
          <span className="svg-icon svg-icon-sm svg-icon-primary">
            <SVG
              title=" "
              src={toAbsoluteUrl("/media/svg/icons/General/Other1.svg")}
            />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-sm dropdown-menu-right"
          style={{ width: "200px" }}
        >
          <ul className="navi navi-hover">
            {subItem?.type === "plan" && (
              <>
                <li
                  className="navi-item pointer"
                  onClick={() => setDialog({ check: true, subItem })}
                >
                  <span className="navi-link">
                    <span className="navi-text">Change Frequency</span>
                  </span>
                </li>
                {editOrderDetail?.is_container != 1 && (
                  <li
                    className="navi-item pointer"
                    onClick={() => setDialog({ plan: true, subItem })}
                  >
                    <span className="navi-link">
                      <span className="navi-text">Change Plan</span>
                    </span>
                  </li>
                )}
              </>
            )}
            {subItem?.type !== "plan" && (
              <li
                className="navi-item pointer"
                onClick={() => {
                  if (pageOn == "release") {
                    handleRemoveProductRelease();
                  } else {
                    handleRemoveProduct();
                  }
                }}
              >
                <span className="navi-link">
                  <span className="navi-text">Remove</span>
                </span>
              </li>
            )}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
