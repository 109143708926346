/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-unused-vars */
import React from "react";
import NewBreadCrumbs from "../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs.js";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchEditOrderDetailCard,
  fetchEditOrderSummary,
  fetchOrderSummaryListStripe,
} from "../_redux/order/orderAction.js";
import { useEffect } from "react";
import { ApplyChangesCard } from "../components/EditOrderComponents/ApplyChangesCard.js";
import { useOrderUIContext } from "../OrderUIContext.js";
import { useState } from "react";
import UpdateSubscriptionModal from "../components/EditOrderComponents/Modal/UpdateSubscriptionModal.js";
import { useHistory } from "react-router-dom";
import { EditBreakDownCard } from "../components/EditOrderComponents/EditBreakDownCard.js";
import { Table } from "react-bootstrap";
import { useContextState } from "../reducer/editOrderStripeReducer.js";
import moment from "moment";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from "@material-ui/core";
import { deleteStateData, planProductId } from "../_mocks/dummyData.js";
import _ from "lodash";
import {
  editStripeOrder,
  getPeriod,
  getSubDetail,
} from "../_redux/order/orderCrud.js";
import {
  UpdateData,
  removeCouponFromPhases,
  resetDiscounts,
  transformData,
} from "./editStripeReleaseUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner.js";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar.js";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome.js";
import AddProductModal from "../components/EditOrderComponents/Modal/AddProoductModal.js";
import AddProductMainModal from "../components/EditOrderComponents/Modal/AddProductMainModal.js";
import { EditOrderReleaseList } from "./EditOrderReleaseList.js";
import Checkbox from "@material-ui/core/Checkbox";
import { useLocation } from "react-router-dom";

export const EditOrderStripeRelease = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, dispatchReducer } = useContextState();
  const history = useHistory();
  const params = useLocation();
  const [dialog, setDialog] = useState({ update: false });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [updateLoading, setUploadLoading] = useState(false);
  const [periodData, setPeriodData] = useState(null);
  const [callOnce, setCallOnce] = useState(false);

  const orderUIContext = useOrderUIContext();
  const {
    editOrderDetail,
    editOrderSummary,
    subLoading,
    callApi,
    orderSummaryListStripe,
  } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
      editOrderSummary: state.order.editOrderSummary,
      subLoading: state.order.sub,
      callApi: state.order.callApi,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEditOrderDetailCard(props?.match?.params?.id));
    getPeriod(props?.match?.params?.id).then((res) => {
      setPeriodData(res.data.data);
    });
  }, [dispatch, props.match.params.id]);
  console.log("periodData", params);

  useEffect(() => {
    if (editOrderDetail?.subscription_data) {
      const insurance_cover = editOrderDetail?.order_summary?.find(
        (e) =>
          e?.stripe_product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
          e?.stripe_product_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT
      );
      console.log("insurance_cover", editOrderDetail);
      getSubDetail(editOrderDetail?.subscription_id).then((res) => {
        console.log("res-900", res.data);

        dispatchReducer({
          type: "SET_DATA",
          data: {
            ...state,
            // ...editOrderDetail?.subscription_data,
            ...res.data.data,
            billing_cycle_anchor: params?.search === "?reset=1" ? "now" : "",
            proration_behavior: "none",
            insurance_cover:
              editOrderDetail?.insurance_cover || insurance_cover?.cover || "",
            insurance_price: insurance_cover?.total || "",
          },
        });
      });

      // dispatchReducer({
      //   type: "SET_DATA",
      //   data: {
      //     ...state,
      //     ...editOrderDetail?.subscription_data,
      //     proration_behavior: "none",
      //     insurance_cover:
      //       editOrderDetail?.insurance_cover || insurance_cover?.cover || "",
      //     insurance_price: insurance_cover?.total || "",
      //   },
      // });
    }
  }, [editOrderDetail?.subscription_data]);

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    // const phaseArr = [...state.phases];
    // const phase = phaseArr.find((e) => e.is_edit);
    // phase.proration_behavior = event.target.value;
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        proration_behavior: event.target.value,
        is_call_upcoming: true,
      },
    });
    setValue(event.target.value);
  };

  const handleUpdateOrder = () => {
    setUploadLoading(true);
    setSnack({ call: false, type: "", message: "" });
    function cleanDiscountData(discounts) {
      return discounts.map((discount) => ({
        coupon: discount.coupon.id || discount.coupon,
      }));
    }
    function renamePriceKey(subscriptionItems) {
      return subscriptionItems.map((item) => {
        const { price, ...rest } = item; // Destructure price and the rest of the object
        return {
          ...rest,
          price_data: price, // Rename price to price_data
        };
      });
    }
    function updateUnitAmount(items) {
      return items.map((item) => {
        console.log("item-890", item);

        // Update price_data.unit_amount by multiplying it by 100
        const updatedPriceData = {
          ...item.price_data,
          unit_amount: item.price_data.unit_amount * 100,
          unit_amount_decimal: Number(item.price_data.unit_amount * 100),
        };

        // Return the updated object
        return {
          ...item,
          price_data: updatedPriceData,
          idss: item?.id,
        };
      });
    }
    const newDiscount = cleanDiscountData(state?.discounts);
    const updatedData = renamePriceKey(state?.items.data);
    const updatedDataPrice = updateUnitAmount(updatedData);
    console.log("newState", updatedDataPrice);
    const newState = {
      ...state,
      discounts: newDiscount.length > 0 ? newDiscount : "",
      items: updatedDataPrice,
    };
    const podQty = updatedDataPrice?.find((item) =>
      planProductId.includes(item.plan.product)
    )?.quantity;
    const cleanedData = UpdateData(
      newState,
      props?.match?.params?.id,
      periodData,
      podQty,
      editOrderDetail
    );
    cleanedData.customer = cleanedData.customer_id;
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    // copyCleanedData.schedule_data.phases.map((phase, index) => {
    //   phase.discounts = state.phases[index].discounts.map((discount) => {
    //     const { discount: _, promotion_code: __, ...rest } = discount;
    //     return rest;
    //   });
    // });
    console.log("copyCleanedData", copyCleanedData);
    function replaceIdssWithId(data) {
      // Clone the input to avoid mutating the original object
      const clonedData = JSON.parse(JSON.stringify(data));

      // Update items in schedule_data
      clonedData.schedule_data.items = clonedData.schedule_data.items.map(
        (item) => {
          // Replace "idss" with "id" in price_data
          if (item && item.idss) {
            item.id = item.idss;
            delete item.idss; // Remove the old key
          }
          return item;
        }
      );

      return clonedData;
    }

    copyCleanedData.customer = copyCleanedData.customer_id;
    const updatedDataFinale = replaceIdssWithId(copyCleanedData);
    const phaseProOld = editOrderDetail?.subscription_data?.phases[0];
    const planPrOld = phaseProOld?.items?.filter((item) => {
      const match = orderSummaryListStripe?.allProducts?.find(
        (e) => e?.stripe_product_id == item.product_id
      );
      return match?.type === "plan";
    });
    const planPr = state?.items?.data?.filter((item) => {
      const match = orderSummaryListStripe?.allProducts?.find(
        (e) => e?.stripe_product_id == item?.plan?.product
      );
      return match?.type === "plan";
    });
    updatedDataFinale.planOldQuantity = planPrOld[0]?.quantity;
    updatedDataFinale.planNewQuantity = planPr[0]?.quantity;

    if (newState?.billing_cycle_anchor === "now") {
      updatedDataFinale.schedule_data.billing_cycle_anchor =
        newState?.billing_cycle_anchor;
    } else {
      delete updatedDataFinale.schedule_data.billing_cycle_anchor;
    }
    updatedDataFinale.is_release = true;
    updatedDataFinale.liveTest = true;
    editStripeOrder(updatedDataFinale)
      .then((res) => {
        setUploadLoading(false);
        setSnack({
          call: true,
          type: "success",
          message: "Subscription Updated Successfully ",
        });
        setTimeout(() => {
          history.push(`/order-details/${props?.match?.params?.id}`);
          // window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setUploadLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleUpcomingApi = (summary) => {
    function cleanDiscountData(discounts) {
      return discounts?.map((discount) => ({
        coupon: discount.coupon.id || discount.coupon,
      }));
    }
    function renamePriceKey(subscriptionItems) {
      return subscriptionItems.map((item) => {
        const { price, ...rest } = item; // Destructure price and the rest of the object
        return {
          ...rest,
          price_data: price, // Rename price to price_data
        };
      });
    }
    function updateUnitAmount(items) {
      return items.map((item) => {
        console.log("item-890", item);

        // Update price_data.unit_amount by multiplying it by 100
        const updatedPriceData = {
          ...item.price_data,
          unit_amount: item.price_data.unit_amount * 100,
          unit_amount_decimal: Number(item.price_data.unit_amount * 100),
        };

        // Return the updated object
        return {
          ...item,
          price_data: updatedPriceData,
          idss: item?.id,
        };
      });
    }
    const newDiscount = cleanDiscountData(state?.discounts);
    const updatedData = renamePriceKey(state?.items.data);
    const updatedDataPrice = updateUnitAmount(updatedData);
    const newState = {
      ...state,
      discounts: newDiscount.length > 0 ? newDiscount : "",
      items: updatedDataPrice,
    };
    console.log("newState", newState);

    const cleanedData = UpdateData(
      newState,
      props?.match?.params?.id,
      periodData,
      "",
      editOrderDetail
    );
    cleanedData.customer = cleanedData.customer_id;
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    // copyCleanedData.schedule_data.phases.map((phase, index) => {
    //   phase.discounts = state.phases[index].discounts.map((discount) => {
    //     const { discount: _, promotion_code: __, ...rest } = discount;
    //     return rest;
    //   });
    // });
    console.log("copyCleanedData", copyCleanedData);
    function replaceIdssWithId(data) {
      // Clone the input to avoid mutating the original object
      const clonedData = JSON.parse(JSON.stringify(data));

      // Update items in schedule_data
      clonedData.schedule_data.items = clonedData.schedule_data.items.map(
        (item) => {
          // Replace "idss" with "id" in price_data
          if (item && item.idss) {
            item.id = item.idss;
            delete item.idss; // Remove the old key
          }
          return item;
        }
      );

      return clonedData;
    }
    copyCleanedData.customer = copyCleanedData.customer_id;
    let updatedDataFinale = replaceIdssWithId(copyCleanedData);
    updatedDataFinale.schedule_data.billing_cycle_anchor =
      newState?.billing_cycle_anchor;
    if (newState?.billing_cycle_anchor === "now") {
      updatedDataFinale.schedule_data.billing_cycle_anchor =
        newState?.billing_cycle_anchor;
    } else {
      delete updatedDataFinale.schedule_data.billing_cycle_anchor;
    }
    updatedDataFinale.is_release = true;
    updatedDataFinale.liveTest = true;
    console.log("updatedDataFinale", updatedDataFinale);

    // const gog = resetDiscounts(copyCleanedData);
    dispatch(fetchEditOrderSummary(updatedDataFinale));
  };
  const [callUpComing, setCallUpComing] = useState(false);
  useEffect(() => {
    if (state?.is_call_upcoming && periodData) {
      handleUpcomingApi(editOrderDetail);
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          is_call_upcoming: false,
        },
      });
    }
  }, [state?.is_call_upcoming, periodData]);

  useEffect(() => {
    if (editOrderDetail?.franchise_id) {
      dispatch(
        fetchOrderSummaryListStripe({
          franchise_id: editOrderDetail?.franchise_id,
          postcode:
            editOrderDetail?.postcode || editOrderDetail?.collection_postcode,
          site_id:
            (editOrderDetail?.order_container_details &&
              editOrderDetail?.order_container_details?.length > 0 &&
              editOrderDetail?.order_container_details[0]?.site_id) ||
            "",
        })
      );
    }
  }, [editOrderDetail?.postcode, editOrderDetail?.franchise_id]);

  return (
    <div>
      {/* {editOrderDetail?.id ? "" : <LoadingCustome />} */}
      <NewBreadCrumbs breadcrumb={true} title={"Edit Order"} />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          fontSize: "16px",
          marginBottom: "2%",
          color: "red",
        }}
      ></div>
      <div className="row">
        <div className="col-md-12">
          <div className="text-right mb-2">
            <button
              type="button"
              class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
              onClick={() => {
                history.goBack();
                // history.push("/order-details/" + editOrderDetail?.id);
              }}
            >
              Dismiss
            </button>
            {updateLoading ? (
              <LoadingCustome />
            ) : (
              <button
                type="button"
                class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
                onClick={handleUpdateOrder}
              >
                Update
              </button>
            )}
          </div>
        </div>

        <>
          <div className="col-md-8">
            <div className="card card-custom gutter-b">
              <EditOrderReleaseList
                state={state}
                setCallUpComing={setCallUpComing}
                dispatchReducer={dispatchReducer}
                phases={state.phases?.find((e) => e?.is_edit)}
                editOrderDetail={editOrderDetail}
                orderUIContext={orderUIContext}
                orderSummaryListStripe={orderSummaryListStripe}
              />
              {/* {state.status === "not_started" &&
              state?.phases?.findIndex((e) => e.is_edit) == 0 ? (
                ""
              ) : ( */}
              <div className="card-body pt-0">
                {/* {editOrderDetail?.subscription_data?.status === "active" && ( */}
                <div className="py-4">
                  {/* <div className="mb-4">
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.billing_cycle_anchor == "now"}
                            onChange={(e) => {
                              console.log("event", e.target.checked);

                              if (e.target.checked) {
                                dispatchReducer({
                                  type: "SET_DATA",
                                  data: {
                                    ...state,
                                    billing_cycle_anchor: "now",
                                    is_call_upcoming: true,
                                  },
                                });
                              } else {
                                dispatchReducer({
                                  type: "SET_DATA",
                                  data: {
                                    ...state,
                                    billing_cycle_anchor: "",
                                    is_call_upcoming: true,
                                  },
                                });
                              }
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                      <div style={{ marginLeft: "-20px" }}>
                        <h6 style={{ marginBottom: "-1px" }}>
                          Reset billing cycle
                        </h6>
                        <span>This will generate an invoice immediately</span>
                      </div>
                    </FormGroup>
                  </div> */}
                  {/* <div>
                    <h3 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder text-dark">
                        Proration behavior
                      </span>
                    </h3>
                  </div>

                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state?.proration_behavior !== "none"}
                          onChange={(e) => {
                            dispatchReducer({
                              type: "SET_DATA",
                              data: {
                                ...state,
                                proration_behavior:
                                  state?.proration_behavior === "none"
                                    ? "create_prorations"
                                    : "none",
                                is_call_upcoming: true,
                              },
                            });
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Apply changes"
                    />
                  </FormGroup>
                  {state?.proration_behavior !== "none" ? (
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={state?.proration_behavior}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="create_prorations"
                          control={<Radio />}
                          label="Apply changes on next invoice"
                        />
                        <FormControlLabel
                          value="always_invoice"
                          control={<Radio />}
                          label="Apply changes immediately"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    ""
                  )} */}
                </div>
                {/* )} */}
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-custom gutter-b">
              <div className="card-body pt-0">
                <div className="py-4" style={{ marginBottom: "-24px" }}>
                  <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">
                      Summary
                    </span>
                  </h3>
                </div>
                {subLoading && <LoadingSpinner />}
                {editOrderSummary.period_start && (
                  <List className={classes.root}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <CalendarTodayIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={moment(
                          editOrderSummary &&
                            editOrderSummary.period_start &&
                            editOrderSummary.period_start * 1000
                        ).format("MMMM D, YYYY")}
                        secondary="Subscription updates"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ReceiptIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Next Invoice"
                        secondary={`Amount Due £${
                          editOrderSummary && editOrderSummary.amount_due
                            ? Number(editOrderSummary.amount_due) / 100
                            : ""
                        }`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <ReceiptIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Billing"
                        secondary={`Bills on ${
                          editOrderSummary?.created
                            ? moment(editOrderSummary?.created * 1000).format(
                                "DD-MM-YYYY"
                              )
                            : ""
                        }`}
                      />
                    </ListItem>
                  </List>
                )}
              </div>
            </div>
          </div>
        </>
        <div className="col-md-4">
          {Object.keys(orderUIContext?.state?.breakdown).length > 0 && (
            <div className="card card-custom gutter-b">
              <EditBreakDownCard orderUIContext={orderUIContext} />
            </div>
          )}
        </div>
        {orderUIContext?.state?.orderData?.status === "active" && (
          <div className="col-md-8">
            <div className="card card-custom gutter-b">
              <ApplyChangesCard orderUIContext={orderUIContext} />
            </div>
          </div>
        )}
      </div>
      {dialog.update && (
        <UpdateSubscriptionModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.check && (
        <AddProductMainModal
          dialog={dialog}
          setDialog={setDialog}
          state={state}
          dispatchReducer={dispatchReducer}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
