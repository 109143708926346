import React, { useEffect, useState } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchNearSite,
  fetchOrderSummaryListStripe,
} from "../../_redux/order/orderAction";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { LoadingCustome } from "../../../../../_metronic/_partials/controls/LoadingCustome";
import { LoadingSpinner } from "../../../../../_metronic/_partials/controls/LoadingSpinner";
import { orderSlice } from "../../_redux/order/orderSlice";
import moment from "moment/moment";
import { getONAContainerSite } from "../../_redux/order/orderCrud";
const { actions } = orderSlice;
export const NearSiteModal = ({
  show,
  setShow,
  dispatchReducer,
  state,
  cardDetail,
  setONASiteData,
}) => {
  const dispatch = useDispatch();
  const [availSite, setAvailSite] = useState([]);
  const { siteData, siteLoading } = useSelector(
    (state) => ({
      siteData: state.order.siteData,
      siteLoading: state.order.siteLoading,
    }),
    shallowEqual
  );
  const handleRadioChange = (e, siteData) => {
    setONASiteData(siteData?.containers);
    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        siteSelect: e,
        planReplaced: 0,
        franchise_id: e.franchise_id,
      },
    });
    console.log("rewq", e);
    dispatch(
      fetchOrderSummaryListStripe({
        franchise_id: e.franchise_id,
        postcode: state?.postcode,
        site_id: e?.id,
      })
    );
    dispatch(
      actions.newCustomerFetched({
        id: e.franchise_id,
        chargebee_key: e?.franchise?.chargebee_key,
        stripe_pk: e?.franchise?.stripe_pk,
      })
    );

    // setShow(false);
  };
  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length &&
      cardDetail[0]?.contact?.franchise_id
    ) {
      const data = {
        franchise_id: cardDetail[0]?.contact?.franchise_id,
        containerSize: state?.containerListSelect,
        type: state?.containerList?.find(
          (e) => e.id === state?.containerListSelect
        )?.type,
      };
      const address = cardDetail[0]?.address + cardDetail[0]?.postcode;
      console.log();

      dispatch(fetchNearSite(data, address));
    }
  }, []);
  useEffect(() => {
    getONAContainerSite(state?.siteSelect?.id).then((res) => {
      // setONASiteData(res?.data?.data?.containers);
      setAvailSite(res?.data?.data);
    });
  }, []);
  console.log("availSite", availSite);

  return (
    <Modal
      size="xl"
      show={show.open}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Select Site
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {console.log("siteDat123", siteData)}
        {siteLoading && <LoadingSpinner />}
        {siteData && siteData.length > 0
          ? siteData.map((e) => {
              if (e.id == 5) return;
              const siteAvail = Object.values(availSite)?.find(
                (item) => item.site_id === e.id
              );
              console.log("siteAvail", siteAvail?.containers);

              return (
                <Card
                  body
                  className="mb-2 cardDemo"
                  onClick={() => e.capacity && handleRadioChange(e, siteAvail)}
                >
                  <div className="row marginSite">
                    <div className="col-9 row">
                      <div className="col-4 d-flex">
                        <label className="checkbox alignCu">
                          <input
                            type="checkbox"
                            name="Checkboxes4"
                            checked={
                              state?.siteSelect?.id === e.id ? true : false
                            }
                            onClick={() => e.capacity && handleRadioChange(e)}
                          />
                          <span className="mr-2"></span>
                        </label>
                        <span className="font-weight-bolder text-dark">
                          {e.name}
                        </span>
                      </div>
                      <div className="col-4">
                        Capacity:&nbsp;
                        <span className="font-weight-bolder text-dark">
                          {e.capacity || "-"}
                        </span>
                      </div>

                      <div className="col-4">
                        Franchise:&nbsp;
                        <span className="font-weight-bolder text-dark">
                          {e.franchise?.name}
                        </span>
                      </div>
                      <div className="col-12">
                        Distance:&nbsp;{e.distance} Km
                      </div>
                      <div className="col-12">
                        Available&nbsp;From:&nbsp;
                        {e.available_from
                          ? moment(e.available_from).format("DD-MM-yyyy")
                          : "-"}
                      </div>
                      <div className="col-12 mb-2">
                        Address:&nbsp;{e.address}
                      </div>
                    </div>

                    {/* <div className="col-6 row">
                   
                    
                  </div> */}
                    <div className="col-3">
                      <Swiper
                        pagination={true}
                        modules={[Pagination]}
                        className="newSwiper"
                      >
                        {e.images &&
                          e.images.map((value) => (
                            <SwiperSlide>
                              <img src={value.image} alt="hello" />
                            </SwiperSlide>
                          ))}

                        {/* <SwiperSlide>
                        <img
                          src={toAbsoluteUrl("/media/demos/demo2.png")}
                          alt="hello"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={toAbsoluteUrl("/media/demos/demo3.png")}
                          alt="hello"
                        />
                      </SwiperSlide> */}
                      </Swiper>
                    </div>
                  </div>
                  <div className="row mt-6" style={{ marginBottom: "-14px" }}>
                    <hr style={{ width: "100%" }} />
                    <div className="col-3">
                      20ft Available:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {siteAvail?.containers["20ft"]?.available || "-"}
                      </span>
                    </div>
                    <div className="col-2">
                      8ft Available:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {siteAvail?.containers["8ft"]?.available || "-"}
                      </span>
                    </div>
                    <div className="col-2">
                      6ft Available:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {siteAvail?.containers["6ft"]?.available || "-"}
                      </span>
                    </div>
                    <div className="col-2">
                      5ft Available:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {siteAvail?.containers["5ft"]?.available || "-"}
                      </span>
                    </div>
                    <div className="col-3">
                      Car Park Available:&nbsp;
                      <span className="font-weight-bolder text-dark">
                        {siteAvail?.containers["car_parking"]?.available || "-"}
                      </span>
                    </div>
                  </div>
                </Card>
              );
            })
          : ""}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => setShow(false)}
          disabled={!state?.siteSelect?.id}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
