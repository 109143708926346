import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  addDays,
  isWeekend,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
} from "date-fns";
import { checkAvailability } from "../../_redux/order/orderCrud";
import { LoadingSpinner } from "../../../../../_metronic/_partials/controls/LoadingSpinner";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";

export const ContainerModalCompoent = ({
  formik,
  state,
  franchise,
  dispatchReducer,
  check,
  orderDetail,
  keyC,
  setFranchiseContainer,
}) => {
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  function shouldDisableDate(date) {
    // Calculate the next 3 days from today
    for (let i = 1; i <= 3; i++) {
      const nextDay = addDays(new Date(), i);
      // Disable the date if it's a weekend (Saturday or Sunday)
      if (isWeekend(nextDay)) {
        return true;
      }
    }
    return false;
  }

  const handleAvailability = (date) => {
    setSnack({
      call: false,
      type: "",
      message: "",
    });
    const data = {
      franchise_id: franchise || "",
      type:
        (state?.containerList &&
          state?.containerList.length &&
          state.containerList.find((e) => e.id === state?.containerListSelect)
            ?.type) ||
        "",
      container_size: state?.containerListSelect || "",
      site_id: state?.siteSelect?.id || "",
      collection_date: date || "",
    };
    checkAvailability(data).then((res) => {
      const check = state.product_array.find((e) => e.type === "plan").quantity;
      const condition = Number(check) <= res.data.data.length;
      if (res.data.data?.length == 0 || !condition) {
        setSnack({
          call: true,
          type: "error",
          message:
            "No container is available on this date. Please select another date",
        });
        formik.setFieldValue("collection_date", "");
      }
      if (res.data.data?.length > 0 && condition) {
        setSnack({
          call: true,
          type: "success",
          message: "Date Selected Successfully",
        });
      }
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, avail: res.data.data },
      });

      setLoading(false);
    });
  };
  const handleOrderAvailability = (date) => {
    const data = {
      franchise_id: orderDetail?.franchise_id || "",
      type: orderDetail?.container_size.includes("Non-Ele") ? "NON" : "",
      container_size: orderDetail?.container_size || "",
      site_id:
        (orderDetail?.order_container_details &&
          orderDetail?.order_container_details.length > 0 &&
          orderDetail?.order_container_details[0]?.site_id) ||
        "",
      collection_date: date || "",
    };
    checkAvailability(data).then((res) => {
      const check = orderDetail?.pod;
      const condition = Number(check) <= res.data.data.length;
      // if ((res.data.data?.length == 0) || !condition) {
      //   setSnack({
      //     call: true,
      //     type: "error",
      //     message: "No container is available on this date. Please select another date",
      //   });
      //   formik.setFieldValue("collection_date", orderDetail?.collection_date || null);
      // }
      // if (res.data.data?.length > 0 && condition) {
      setSnack({
        call: true,
        type: "success",
        message: "Date Selected Successfully",
      });
      // setFranchiseContainer(res.data.data)
      // }
      // dispatchReducer({ type: "avail", value: res.data.data });

      setLoading(false);
    });
  };
  return (
    <div className="collection-date">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <DatePicker
          className="form-control"
          dateFormat="dd-MM-Y"
          selected={
            check === "order" && keyC === "moveIn"
              ? new Date(formik?.values?.collection_date)
              : check === "order" && keyC === "collection"
              ? formik?.values?.container_collection_date
                ? new Date(formik?.values?.container_collection_date)
                : new Date()
              : check === "order" && keyC === "moveOut"
              ? formik?.values?.return_date
                ? new Date(formik?.values?.return_date)
                : null
              : check === "order" && keyC === "return"
              ? formik?.values?.container_return_date
                ? new Date(formik?.values?.container_return_date)
                : new Date()
              : formik.values.date_type == "2" &&
                formik?.values?.collection_date
              ? new Date(formik?.values?.collection_date)
              : formik.values.date_type == "1" &&
                formik?.values?.container_collection_date
              ? new Date(formik?.values?.container_collection_date)
              : formik.values.date_type == "3" &&
                formik?.values?.container_return_date
              ? new Date(formik?.values?.container_return_date)
              : formik.values.date_type == "4" && formik?.values?.return_date
              ? new Date(formik?.values?.return_date)
              : null
          }
          onChange={(date) => {
            if (check === "order") {
              formik.setFieldValue(
                keyC === "collection"
                  ? "container_collection_date"
                  : keyC === "moveIn"
                  ? "collection_date"
                  : keyC === "moveOut"
                  ? "return_date"
                  : keyC === "return"
                  ? "container_return_date"
                  : "",
                moment(date).format("yyyy-MM-DD")
              );
              if (keyC === "moveIn") {
                setLoading(true);

                handleOrderAvailability(moment(date).format("yyyy-MM-DD"));
              }
              return;
            } else {
              formik.setFieldValue(
                formik.values.date_type == "1"
                  ? "container_collection_date"
                  : formik.values.date_type == "2"
                  ? "collection_date"
                  : formik.values.date_type == "3"
                  ? "container_return_date"
                  : formik.values.date_type == "4"
                  ? "return_date"
                  : "",
                moment(date).format("yyyy-MM-DD")
              );
              if (formik.values.date_type == "2") {
                setLoading(true);
                handleAvailability(moment(date).format("yyyy-MM-DD"));
              }
            }
          }}
          placeholderText="Select Collection Date"
          inline
          minDate={new Date()}
          shouldDisableDate={shouldDisableDate}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
