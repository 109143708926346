import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  AddEditStorage,
  CustomerDetailEdit,
  NewDistanceDuration,
} from "../order-canva/index";
import {
  fetchNewCustomerCard,
  // fetchOrderSummary,
  fetchPakagingMaterialList,
  fetchQuoteOrderData,
  fetchSearchNewOrder,
} from "../_redux/order/orderAction";
import { init, reducer } from "../reducer/reducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import ContactDealModal from "../../../../_metronic/_partials/widgets/alert/ContactDealModal";
import { CreateContactOffCanvas } from "../../ContactDeals/canvas/CreateContactOffCanvas";
import { CustomerDetailCard } from "./components/CustomerDetailCard";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome";
import { NewOrderCollection } from "../components/NewOrderCollection";
import OrderDetailDistance from "../components/OrderDetailDistance";
import { OrderSummary } from "./components/OrderSummary";
import { TextField } from "@material-ui/core";
import { formValues } from "../orderUtils";
import { initIds } from "../../../../_metronic/_partials/controls/RightPanel";
import { initsNewOrder } from "../OrderUIHelpers";
import { orderSlice } from "../_redux/order/orderSlice";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import { OrderNotes } from "../components/OrderNotes";
import NotAuthorized from "../../../../_metronic/_partials/widgets/NotAuthorized";
import { updateFranchise } from "../_redux/order/orderCrud";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { ContainerConfirmModal } from "./modal/ContainerConfirmModal";
import { NearSiteModal } from "./modal/NearSiteModal";
import { SiteCard } from "./components/SiteCard";

export const NewOrder = (props) => {
  const controllerRef = useRef(new AbortController());
  const { actions } = orderSlice;
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Order");
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const {
    orderData,
    searchNewOrder,
    cardDetail,
    storage,
    quoteData,
    admin,
    permission,
    role,
  } = useSelector(
    (state) => ({
      orderData: state.order,
      searchNewOrder: state.order.searchNew,
      cardDetail: state.order.cardDetail,
      storage: state.auth.storageData?.customerData,
      quoteData: state.order.quoteData,
      admin: state.auth.user,
      permission: state.auth.permission,
      role: state.auth.user.roles,
    }),
    shallowEqual
  );
  const [keyDis, setKeyDis] = useState("collection");
  // const [selectV, setSelectV] = useState(false);
  const [openCanva, setOpenCanva] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [customerValue, setCustomerValue] = useState("");
  const [checkLocation, setCheckLocation] = useState(true);
  const [state, dispatchReducer] = useReducer(reducer, init);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [onLoading, setOnLoading] = useState(false);
  const [billingCall, setBillingCall] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [dialogSite, setDialogSite] = useState({ open: false });
  const [paramData, setParamData] = useState({
    unit: 1,
    period: "month",
    products_id: [],
  });

  useEffect(() => {
    setTimeout(() => {
      initIds(initsNewOrder);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initsNewOrder]);

  // useEffect(() => {
  //   if (selectV) {
  //     dispatch(fetchSearchNewOrder("", controllerRef?.current));
  //   }
  // }, [dispatch, selectV]);
  useEffect(() => {
    if (location?.state?.id) {
      dispatch(fetchSearchNewOrder(""));
      dispatch(fetchNewCustomerCard(location.state.id));
      // setSelectV(true);
    } else if (
      quoteData &&
      quoteData?.customer &&
      quoteData?.customer.length > -1 &&
      quoteData.customer[0]?.id
    ) {
      dispatch(fetchSearchNewOrder(""));
      dispatch(fetchNewCustomerCard(quoteData.customer[0].id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location?.state?.id, quoteData.customer]);

  //runs in case of add edit storage redirect
  useEffect(() => {
    if (location?.search.includes("cm-qty")) {
      dispatch(fetchSearchNewOrder(""));
      dispatch(fetchNewCustomerCard(storage.length > 0 ? storage[0].id : ""));
      // setSelectV(true);
      dispatch(
        fetchPakagingMaterialList(
          (storage.length > 0 && storage[0]?.contact?.franchise_id) || ""
        )
      );
    } else if (location?.search.includes("quote") && location.state?.quote) {
      dispatch(fetchPakagingMaterialList(location.state?.franchise_id || ""));
      dispatch(
        fetchQuoteOrderData(
          location.state?.franchise_id,
          location.state?.quote,
          setSnack
        )
      );
      // setSelectV(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location?.state?.id]);
  const handleContactUpdate = (newValue) => {
    if (state?.containerTypeSelect !== "") {
      return;
    }
    if (newValue.plan_type && newValue.plan_type.length > 1) {
      dispatchReducer({ type: "plan_type", value: newValue.plan_type });
      setDialogType({ open: true });
      return;
    }
    if (
      newValue.plan_type.length < 2 &&
      role &&
      role?.length &&
      role[0] !== "super-admin"
    ) {
      if (newValue.plan_type[0] === "pod") {
        dispatchReducer({ type: "containerTypeSelect", value: "pod" });
        // return;
      }
    } else {
      setDialogType({ open: true });
    }
  };

  const handleChange = (event, newValue) => {
    setCheckLocation(true);
    if (newValue?.id) {
      setOnLoading(true);
    }

    if (newValue.plan_type && newValue.plan_type.length > 0) {
      dispatchReducer({ type: "plan_type", value: newValue.plan_type });
      if (
        newValue.plan_type.length < 2 &&
        role &&
        role?.length &&
        role[0] !== "super-admin"
      ) {
        if (newValue.plan_type[0] === "pod") {
          dispatchReducer({ type: "containerTypeSelect", value: "pod" });
          // return;
        }
      } else {
        setDialogType({ open: true });
      }
    }
    if (newValue?.id) {
      const data = {
        contact_id: newValue?.contact_id,
        postcode: newValue?.postcode,
      };
      updateFranchise(data)
        .then(() => {
          setOnLoading(false);
          dispatch(
            fetchNewCustomerCard(
              newValue?.id,
              cardDetail &&
                cardDetail.length > -1 &&
                cardDetail[0]?.contact?.franchise_id
            )
          );
        })

        .catch(() => {
          setOnLoading(false);
          dispatch(
            fetchNewCustomerCard(
              newValue?.id,
              cardDetail &&
                cardDetail.length > -1 &&
                cardDetail[0]?.contact?.franchise_id
            )
          );
        });
    }
    localStorage.removeItem("selectedItems");
    localStorage.removeItem("selectedUserItems");
  };

  const editValues = formValues(cardDetail, admin);
  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length &&
      !location?.search.includes("cm-qty") &&
      !location?.search.includes("quote")
    ) {
      // const data = {
      //   pod:
      //     (state?.productArray?.length > 0 &&
      //       state?.productArray.find((e) => e.type === "plan")?.qty) ||
      //     "",
      // };
      // dispatch(
      //   fetchOrderSummary(
      //     "2",
      //     1,
      //     "month",
      //     paramData?.products_id?.length > 0 ? paramData?.products_id : [],
      //     cardDetail &&
      //       cardDetail.length &&
      //       cardDetail[0].contact &&
      //       cardDetail[0].contact?.franchise?.id
      //       ? cardDetail[0].contact?.franchise?.id
      //       : "",
      //     cardDetail && cardDetail.length && cardDetail[0].postcode
      //       ? cardDetail[0].postcode
      //       : "",
      //     "",
      //     "",
      //     "",
      //     paramData?.products_id?.length > 0 ? 1 : "0",
      //     data
      //   )
      // );
      // dispatch(
      //   fetchPakagingMaterialList(
      //     cardDetail &&
      //       cardDetail.length &&
      //       cardDetail[0].contact &&
      //       cardDetail[0].contact?.franchise?.id
      //       ? cardDetail[0].contact?.franchise?.id
      //       : ""
      //   )
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetail, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(actions.blankCustomer({}));
      dispatchReducer({ type: "containerTypeSelect", value: "" });
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedUserItems");
    };
  }, [actions, dispatch]);
  // useEffect(() => {
  //   const script = document.createElement("script");

  //   script.src =
  //     "https://calcumate-calculator-new-production.s3-ap-southeast-2.amazonaws.com/static/js/main.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  const handleInputChange = (newInputValue) => {
    setCustomerValue(newInputValue);
    clearTimeout(debounceTimer);
    const timer = setTimeout(() => {
      dispatch(fetchSearchNewOrder(newInputValue, controllerRef?.current));
    }, 500); // Adjust the debounce delay as needed (in milliseconds)
    setDebounceTimer(timer);
  };
  const [customerCheck, setCustomerCheck] = useState(false);
  useEffect(() => {
    if (
      location?.search.includes("customer") &&
      !customerCheck &&
      cardDetail &&
      cardDetail.length > 0 &&
      cardDetail[0]?.contact?.franchise?.plan_type
    ) {
      dispatchReducer({
        type: "plan_type",
        value: cardDetail[0].contact?.franchise?.plan_type,
      });
      setDialogType({ open: true });
      setCustomerCheck(true);
    }
  }, [location.search, cardDetail, cardDetail.length]);
  useEffect(() => {
    if (
      state?.siteSelect?.franchise_id &&
      cardDetail &&
      cardDetail.length > 0 &&
      cardDetail[0]?.contact?.franchise_id
    ) {
      const siteFra = state?.siteSelect?.franchise_id;
      const cardFra = cardDetail[0]?.contact?.franchise_id;
      if (siteFra != cardFra) {
        dispatchReducer({ type: "siteSelect", value: {} });
      }
    }
  }, [cardDetail]);
  return (
    <>
      {onLoading && <LoadingCustome />}
      {quoteData?.loading ? (
        <LoadingCustome />
      ) : (
        <>
          {permission?.new_order?.create ? (
            <>
              <div
                className="customer-search d-flex justify-content-end mt-2 row"
                style={{ width: "50%" }}
              >
                <div className="mt-2">
                  <button
                    className="btn btn-primary mr-2 "
                    type="button"
                    // style={{ width: "30%" }}
                    onClick={() => setDialog(true)}
                    disabled={
                      cardDetail && cardDetail.length > 0 ? false : true
                    }
                  >
                    {intl.formatMessage({ id: "Contact/Deal" })}
                  </button>
                </div>
                {/* {admin?.roles &&
                admin.roles?.length &&
                admin.roles?.length > 0 &&
                admin.roles[0]?.name !== "franchise-owner" ? ( */}
                <div className="mr-2 mt-2">
                  <Autocomplete
                    loading={orderData?.customerLoading}
                    autoComplete={false}
                    onChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    onInputChange={(event, newInputValue) => {
                      if (
                        newInputValue?.length > 3 &&
                        event?.type !== "click"
                      ) {
                        handleInputChange(newInputValue);
                      }
                    }}
                    id="controllable-states-demo"
                    options={
                      customerValue !== "" && customerValue !== " "
                        ? searchNewOrder && searchNewOrder?.length > 0
                          ? searchNewOrder
                          : []
                        : []
                    }
                    getOptionLabel={(option) =>
                      `${option?.first_name || "-"} ${option?.last_name ||
                        ""} | ${option?.email || ""} | ${option?.phone || ""}`
                    }
                    style={{ width: 350 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({
                          id: "Search.Contact/Deal/Customer",
                        })}
                        variant="outlined"
                        className="form-control"
                        // onChange={(e) => setSelectV(true)}
                      />
                    )}
                  />
                </div>
                {/* ) : (
                  ""
                )} */}
              </div>
              <div className="mt-8">
                <div className="card card-custom gutter-b">
                  <div className="card-body">
                    <CustomerDetailCard
                      detailData={
                        cardDetail && cardDetail.length && cardDetail[0]
                      }
                      franchise={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.contact?.franchise?.name
                      }
                      agent={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.contact?.agent?.first_name
                      }
                      agentLast={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.contact?.agent?.last_name
                          ? cardDetail[0].contact.agent.last_name
                          : "-"
                      }
                      orderDataLoading={orderData?.detailLoading}
                      setOpenCanva={setOpenCanva}
                      type={"newOrder"}
                      state={state}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                {/* {(state.containerTypeSelect === "pod" || (state.containerTypeSelect === "container" && state.containerListSelect !==""))  && */}
                <div className="col-md-12 col-lg-6">
                  <OrderSummary
                    franchise={
                      location?.search.includes("quote")
                        ? location.state?.franchise_id
                        : (cardDetail &&
                            cardDetail.length > -1 &&
                            cardDetail[0]?.contact?.franchise_id) ||
                          state?.postcode?.franchise_id
                    }
                    location={location}
                    setCheckLocation={setCheckLocation}
                    checkLocation={checkLocation}
                    quoteData={quoteData}
                    dispatchReducer={dispatchReducer}
                    state={state}
                    setParamData={setParamData}
                    paramData={paramData}
                    billingCall={billingCall}
                    setBillingCall={setBillingCall}
                    orderDataLoading={orderData?.detailLoading}
                  />
                </div>
                {/* } */}

                <div className="col-md-12 col-lg-6">
                  {state.containerTypeSelect === "container" && (
                    <SiteCard
                      state={state}
                      setDialogType={setDialogType}
                      dialogType={dialogType}
                      setDialogSite={setDialogSite}
                    />
                  )}
                  <div className="mb-8">
                    <NewOrderCollection
                      setDialogType={setDialogType}
                      franchise={
                        (cardDetail &&
                          cardDetail.length > -1 &&
                          cardDetail[0]?.contact?.franchise_id) ||
                        state?.postcode?.franchise_id
                      }
                      dispatchReducer={dispatchReducer}
                      state={state}
                      customerAddress={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.address
                      }
                      postcode={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.postcode
                      }
                      city={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.city
                      }
                      keyDis={keyDis}
                      setKeyDis={setKeyDis}
                      paramData={paramData}
                    />
                    <OrderDetailDistance
                      orderDetail={
                        cardDetail && cardDetail.length > -1 && cardDetail[0]
                      }
                      type={"new_order"}
                      customerAddress={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.address
                      }
                      postcode={
                        cardDetail &&
                        cardDetail.length > -1 &&
                        cardDetail[0]?.postcode
                      }
                      state={state}
                      franchise={
                        (cardDetail &&
                          cardDetail.length > -1 &&
                          cardDetail[0]?.contact?.franchise_id) ||
                        state?.postcode?.franchise_id
                      }
                      setParamData={setParamData}
                      paramData={paramData}
                      dispatchReducer={dispatchReducer}
                      keyDis={keyDis}
                      setKeyDis={setKeyDis}
                    />
                    <OrderNotes
                      state={state}
                      title={"Driver Note"}
                      dispatchReducer={dispatchReducer}
                      cardDetail={cardDetail}
                      mainText={quoteData?.driverNotes}
                      location={location && location}
                    />
                    {location?.search.includes("quote") ? (
                      <OrderNotes
                        state={state}
                        title={"Notes"}
                        mainText={quoteData?.notes}
                        type={"quote"}
                        dispatchReducer={dispatchReducer}
                        cardDetail={cardDetail}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <CustomerDetailEdit />
              {}
              {openCanva && (
                <>
                  <CreateContactOffCanvas
                    title={"Create Contact"}
                    type={cardDetail && cardDetail.length ? "edit" : ""}
                    check={"customer"}
                    editCustomer={editValues}
                    editCustomerId={
                      cardDetail &&
                      cardDetail.length &&
                      cardDetail[0].contact &&
                      cardDetail[0].contact.id
                        ? cardDetail[0].contact.id
                        : ""
                    }
                    setOpenCanva={setOpenCanva}
                    pageCheck={"new_order"}
                    detailData={
                      cardDetail && cardDetail.length && cardDetail[0]
                    }
                    setBillingCall={setBillingCall}
                    isPopSelected={
                      state?.containerTypeSelect !== "" ? "no_reload" : "reload"
                    }
                    handleContactUpdate={handleContactUpdate}
                  />
                  <div
                    className="offcanvas-overlay"
                    onClick={() => setOpenCanva(false)}
                  ></div>
                </>
              )}
              <NewDistanceDuration />
              <AddEditStorage />
              <ContactDealModal
                dialog={dialog}
                dispatch={dispatch}
                cardDetail={cardDetail}
                setDialog={setDialog}
              />
            </>
          ) : (
            <NotAuthorized />
          )}
        </>
      )}
      {dialogType.open && (
        <ContainerConfirmModal
          show={dialogType}
          setShow={setDialogType}
          dispatchReducer={dispatchReducer}
          state={state}
          setDialogSite={setDialogSite}
          setParamData={setParamData}
        />
      )}
      {dialogSite.open && (
        <NearSiteModal
          show={dialogSite}
          setShow={setDialogSite}
          dispatchReducer={dispatchReducer}
          state={state}
          cardDetail={cardDetail}
        />
      )}

      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </>
  );
};
