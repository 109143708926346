import React, { useEffect, useState } from "react";
import AutoCollectModal from "../../order-modal/AutoCollect";
import RequestPaymentModal from "../../order-modal/RequestPayment";
import ExemptDialog from "../EditOrderComponents/Modal/ExemptModal";
import {
  disable25Api,
  enable25Api,
  getHasArrear,
  hasUpdate,
  pausePay,
  sendAddCardLink,
  showCharge20,
} from "../../_redux/order/orderCrud";
import SendBoxLabelModal from "../EditOrderComponents/Modal/SendBoxLabelModal";
import EmailCustomerModal from "../../order-modal/EmailCustomer";
import ReactivateSubModal from "../../order-modal/ReactivateSub";
import DynamicDialog from "../../../../../_metronic/_partials/widgets/alert/DynamicDialog";
import SnackBarTool from "../../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import PausePaymentModal from "../../order-modal/PausePaymentModal";
import {
  fetchBillingCardDetail,
  fetchMyOrderDetailCard,
  fetchMyOrderTimeline,
} from "../../_redux/order/orderAction";
import { useDispatch } from "react-redux";
import AddProductMainModal from "../EditOrderComponents/Modal/AddProductMainModal.js";
import { useContextState } from "../../reducer/editOrderStripeReducer";

const BillingActionDropdown = ({
  intl,
  billingCard,
  setDialog,
  orderDetail,
  setDialogRecur,
  dialogRecur,
  setEightCharge,
  cardDetailArray,
  openInvoice,
  setOpenInvoice,
  chargeBeeInvoiceList,
  hasArrear,
  setSnack,
  snack,
  setcallApiHas,
  loading,
  setLoading,
}) => {
  console.log("cardDetailArray", cardDetailArray);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [showHisInvoice, setHisInvoice] = useState(false);

  const [dialogAuto, setDialogAuto] = useState({ check: false });
  const [dialogs, setDialogs] = useState({ check: false });
  const [dialogsOneTime, setDialogsOneTime] = useState({ update: false });
  const [dialogEmail, setDialogEmail] = useState({ check: false });
  const [cardDialog, setCardDialog] = useState({ modal: false });
  // const [snack, setSnack] = useState({ call: false, type: "", message: "" });

  const [excempt, setExcempt] = useState(false);
  const [sendLabel, setSendLabel] = useState(false);
  const [charge, setCharge] = useState(true);
  const [reactivateModal, setReactivateModal] = useState(false);
  // const [hasArrear, setHasArrear] = useState(null);
  const [hasDialog, setHasDialog] = useState({ modal: false });
  const [dialogPause, setDialogPause] = useState({ check: false });
  const [resumeDialog, setResumeDialog] = useState({ modal: false });
  const [resumeLoading, setResumeLoading] = useState(false);
  const [disable25, setDisable25] = useState({ modal: false });
  const [enable25, setEnable25] = useState({ modal: false });
  const { state, dispatchReducer } = useContextState();

  useEffect(() => {
    if (orderDetail?.created_at) {
      let date = new Date("2023-05-18T09:15:16.000000Z").getTime();
      let orderDate = new Date(orderDetail?.created_at).getTime();
      if (orderDate <= date) {
        setHisInvoice(true);
      } else {
        setHisInvoice(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail?.created_at]);

  useEffect(() => {
    if (orderDetail?.id) {
      showCharge20(orderDetail?.id).then((res) => {
        if (
          res?.data?.data?.twenty_percent_status !== 1 &&
          res?.data?.data?.eighty_percent_status !== 1
        ) {
          setCharge(true);
        } else {
          setCharge(false);
        }
        if (res?.data?.data?.eighty_percent_status) {
          setEightCharge(true);
        }
      });
    }
  }, [orderDetail?.id]);

  // useEffect(() => {
  //   if (orderDetail?.customer_old_chargebee_id) {
  //     getHasArrear(orderDetail?.customer_old_chargebee_id).then((res) => {
  //       setHasArrear(res.data.data);
  //       console.log("helloop", res.data.data);
  //     });
  //   }
  // }, [orderDetail?.customer_old_chargebee_id]);
  const handleCardDialog = () => {
    setLoading(true);
    setSnack({ call: false, type: "", message: "" });
    setCardDialog({ modal: false });
    const payload = {
      order_id: orderDetail?.id,
    };
    sendAddCardLink(payload)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setcallApiHas(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };
  const handleHas = (data) => {
    setSnack({ call: false, type: "", message: "" });
    hasUpdate(data)
      .then((res) => {
        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setcallApiHas(true);
        setTimeout(() => {
          setHasDialog({ modal: false });
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleResume = (data) => {
    setResumeLoading(true);
    setSnack({ call: false, type: "", message: "" });
    pausePay(data)
      .then((res) => {
        dispatch(fetchMyOrderDetailCard(orderDetail?.id));
        dispatch(fetchMyOrderTimeline(orderDetail?.id));
        dispatch(
          fetchBillingCardDetail(
            orderDetail?.franchise_id,
            orderDetail?.subscription_data?.id,
            "",
            orderDetail?.customer_chargebee_id,
            orderDetail?.id
          )
        );
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setTimeout(() => {
          setResumeDialog({ modal: false });
        }, 2000);
      })
      .catch((error) => {
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleDisablePrice = () => {
    setSnack({ call: false, type: "", message: "" });
    const payload = {
      order_id: orderDetail?.id,
    };
    disable25Api(payload)
      .then((res) => {
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setTimeout(() => {
          setDisable25({ modal: false });
        }, 2000);
      })
      .catch((error) => {
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleEnablePrice = () => {
    setSnack({ call: false, type: "", message: "" });
    const payload = {
      order_id: orderDetail?.id,
    };
    enable25Api(payload)
      .then((res) => {
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "success",
          message: res.data.message,
        });
        setTimeout(() => {
          setEnable25({ modal: false });
        }, 2000);
      })
      .catch((error) => {
        setResumeLoading(false);

        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.data?.output,
        });
      });
  };

  return (
    <>
      <ul className="navi navi-hover">
        {chargeBeeInvoiceList && chargeBeeInvoiceList.length > 0 && (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setOpenInvoice({ open: true, check: "chargebee" })}
            >
              <span className="navi-text">ChargeBee Invoices</span>
            </span>
          </li>
        )}
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setOpenInvoice({ open: true, check: "invoice" })}
          >
            <span className="navi-text">
              {intl.formatMessage({ id: "Invoices" })}
            </span>
          </span>
        </li>
        {orderDetail?.subscription_id &&
          orderDetail?.subscription_id?.includes("sub_") && (
            <li className="navi-item pointer">
              <span className="navi-link" id="kt_EditNextBilling_panel_toggle">
                <span className="navi-text">
                  {intl.formatMessage({ id: "Edit.Next.Billing.Date" })}
                </span>
              </span>
            </li>
          )}
        <li className="navi-item pointer">
          <span className="navi-link" id="kt_EditAdhocCharge_panel_toggle">
            <span className="navi-text">
              {intl.formatMessage({ id: "Create.Hoc.Charge" })}
            </span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span className="navi-link">
            <span
              className="navi-text"
              onClick={() =>
                setDialogsOneTime({
                  check: true,
                  title: "Add Charge",
                  type: "charges",
                })
              }
            >
              One time charge
            </span>
          </span>
        </li>
        {/* {billingCard?.old_unpaid_invoice !== "" && (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_PaymentPlan_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Payment.Plan" })}
              </span>
            </span>
          </li>
        )} */}
        {(orderDetail?.payment_method === "bac" ||
          orderDetail?.payment_method === "cash") &&
          orderDetail?.order_details?.pod_plan_type != "Economy" &&
          orderDetail?.franchise_id == 6 && (
            <li className="navi-item pointer">
              <span className="navi-link" id="kt_Charge20_panel_toggle">
                <span className="navi-text"> Collect 10%</span>
              </span>
            </li>
          )}
        {/* {billingCard?.old_unpaid_invoice !== "" && (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_PartPayment_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Part.Payment" })}
              </span>
            </span>
          </li>
        )} */}
        {orderDetail?.subscription_data &&
        orderDetail.subscription_data.subscription ? (
          orderDetail.subscription_data.subscription?.pause_collection !==
          null ? (
            <span>
              <li className="navi-item pointer">
                <span className="navi-link" id="kt_PutOnHold_panel_toggle">
                  <span className="navi-text">Resume Subscription</span>
                </span>
              </li>
            </span>
          ) : (
            <span>
              <li className="navi-item pointer">
                <span className="navi-link" id="kt_PutOnHold_panel_toggle">
                  <span className="navi-text">
                    {intl.formatMessage({ id: "Put.Hold" })}
                  </span>
                </span>
              </li>
            </span>
          )
        ) : (
          ""
        )}

        {billingCard?.late_fee_status === 0 ||
        billingCard?.late_fee_status === 1 ? (
          <span>
            <li className="navi-item pointer">
              <span
                className="navi-link"
                onClick={() =>
                  setDialog({
                    type: true,
                    check:
                      billingCard?.late_fee_status === 1 ? "stop" : "activate",
                  })
                }
              >
                <span className="navi-text">
                  {billingCard?.late_fee_status === 1
                    ? "Disable Late fee"
                    : "Enable Late fee"}
                </span>
              </span>
            </li>
          </span>
        ) : (
          ""
        )}
        {orderDetail?.subscription_data?.status === "paused" ? (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setDialog({ type: true, check: "resume" })}
            >
              <span className="navi-text">
                {intl.formatMessage({ id: "Resume.Subscription" })}
              </span>
            </span>
          </li>
        ) : (
          ""
        )}
        {(orderDetail?.is_collection_completed !== 1 ||
          orderDetail?.subscription_data?.status !== "canceled") &&
        orderDetail?.is_cancel != 1 ? (
          <li className="navi-item pointer">
            <span className="navi-link" id="kt_CancelSubscription_panel_toggle">
              <span className="navi-text">
                {intl.formatMessage({ id: "Cancel.Subscription" })}
              </span>
            </span>
          </li>
        ) : (
          ""
        )}
        {showHisInvoice ? (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() =>
                setDialogRecur({
                  dialog: true,
                  orderId: orderDetail?.id,
                  bookRef: orderDetail?.booking_reference,
                  ordCreatedAt: orderDetail?.created_at,
                })
              }
            >
              <span className="navi-text">Historical Invoices</span>
            </span>
          </li>
        ) : (
          ""
        )}

        {/* {orderDetail?.subscription_id &&
        orderDetail?.payment_method === "card" &&
        cardDetailArray?.length > 0 ? (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setDialogAuto({ check: true })}
            >
              <span className="navi-text">Auto Collection</span>
            </span>
          </li>
        ) : null} */}

        {orderDetail?.subscription_id &&
          orderDetail?.subscription_id?.includes("sub") &&
          orderDetail?.subscription_data?.subscription?.pause_collection ==
            null && (
            <li className="navi-item pointer">
              <span
                className="navi-link"
                onClick={() => setDialogPause({ check: true })}
              >
                <span className="navi-text">Pause Payment Collection</span>
              </span>
            </li>
          )}
        {
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() =>
                setResumeDialog({
                  modal: true,
                  funData: {
                    order_id: orderDetail?.id || "",
                    auto_collection: "on",
                  },
                })
              }
            >
              <span className="navi-text">Resume Payment Collection</span>
            </span>
          </li>
        }

        {/* {orderDetail?.subscription_id && (<li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setDialogs({ check: true })}
          >
            <span className="navi-text">Email for payment</span>
          </span>
        </li>)} */}
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setDialogEmail({ check: true })}
          >
            <span className="navi-text">Resend Order Confirmation Email</span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span className="navi-link" onClick={() => setExcempt(true)}>
            <span className="navi-text">EXEMPT Automated Price Inc</span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() => setCardDialog({ modal: true })}
          >
            <span className="navi-text">Send Add Card Link</span>
          </span>
        </li>
        {orderDetail?.is_container == 3 && (
          <li className="navi-item pointer">
            <span className="navi-link" onClick={() => setSendLabel(true)}>
              <span className="navi-text">Email for Send Label</span>
            </span>
          </li>
        )}
        {orderDetail?.subscription_data?.status == "cancelled" && (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() => setReactivateModal({ check: true })}
            >
              <span className="navi-text">Reactivate Subscription</span>
            </span>
          </li>
        )}
        {hasArrear?.has_arrears && (
          <li className="navi-item pointer">
            <span
              className="navi-link"
              onClick={() =>
                setHasDialog({
                  modal: true,
                  funData: {
                    customer_id: orderDetail?.customer_old_chargebee_id,
                    is_arrear: 1,
                  },
                })
              }
            >
              <span className="navi-text">No longer in arrears</span>
            </span>
          </li>
        )}
        {/* <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() =>
              setDisable25({
                modal: true,
                funData: "",
              })
            }
          >
            <span className="navi-text">Disable 25% Price Increase</span>
          </span>
        </li>
        <li className="navi-item pointer">
          <span
            className="navi-link"
            onClick={() =>
              setEnable25({
                modal: true,
                funData: "",
              })
            }
          >
            <span className="navi-text">25% Price Increase</span>
          </span>
        </li> */}
      </ul>
      <AutoCollectModal
        orderDetail={orderDetail}
        dialog={dialogAuto}
        setDialog={setDialogAuto}
        billingCard={billingCard}
      />
      <PausePaymentModal
        orderDetail={orderDetail}
        dialog={dialogPause}
        setDialog={setDialogPause}
        billingCard={billingCard}
      />
      {dialogs.check && (
        <RequestPaymentModal
          dialog={dialogs}
          setDialog={setDialogs}
          orderDetail={orderDetail}
        />
      )}
      {dialogEmail.check && (
        <EmailCustomerModal
          dialog={dialogEmail}
          setDialog={setDialogEmail}
          orderDetail={orderDetail}
        />
      )}
      {excempt && (
        <ExemptDialog
          dialog={excempt}
          setDialog={setExcempt}
          orderId={orderDetail?.id}
        />
      )}
      {sendLabel && (
        <SendBoxLabelModal
          dialog={sendLabel}
          setDialog={setSendLabel}
          orderId={orderDetail?.id}
        />
      )}
      {reactivateModal.check && (
        <ReactivateSubModal
          dialog={reactivateModal}
          setDialog={setReactivateModal}
          orderId={orderDetail?.id}
          orderDetail={orderDetail}
        />
      )}
      {hasDialog.modal && (
        <DynamicDialog
          dialog={hasDialog}
          setDialog={setHasDialog}
          header={"No longer in arrears"}
          mainText={"Are you sure you want to perfome this action"}
          handleChange={handleHas}
        />
      )}
      {resumeDialog.modal && (
        <DynamicDialog
          dialog={resumeDialog}
          setDialog={setResumeDialog}
          header={"Resume payment collection"}
          mainText={
            "Are you sure you want to resume collecting payments? Any future invoices for this subscription will resume payment collection."
          }
          handleChange={handleResume}
          loading={resumeLoading}
        />
      )}
      {cardDialog.modal && (
        <DynamicDialog
          dialog={cardDialog}
          setDialog={setCardDialog}
          header={"Alert"}
          mainText={"Are you sure you want to perfome this action"}
          handleChange={handleCardDialog}
        />
      )}
      {disable25.modal && (
        <DynamicDialog
          dialog={disable25}
          setDialog={setDisable25}
          header={"Alert"}
          mainText={"Are you sure you want to perfome this action"}
          handleChange={handleDisablePrice}
        />
      )}
      {enable25.modal && (
        <DynamicDialog
          dialog={enable25}
          setDialog={setEnable25}
          header={"Alert"}
          mainText={"Are you sure you want to perfome this action"}
          handleChange={handleEnablePrice}
        />
      )}
      {dialogsOneTime?.check && (
        <AddProductMainModal
          dialog={dialogsOneTime}
          setDialog={setDialogsOneTime}
        />
      )}
      {/* {snack.call ? <SnackBarTool {...snack} /> : ""} */}
    </>
  );
};

export default BillingActionDropdown;
