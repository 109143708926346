/* eslint-disable no-restricted-imports */
/* eslint-disable no-unused-vars */
import React from "react";
import NewBreadCrumbs from "../../../../_metronic/layout/components/subheader/components/NewBreadCrumbs";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchEditOrderDetailCard,
  fetchEditOrderSummary,
  fetchOrderSummaryListStripe,
} from "../_redux/order/orderAction";
import { useEffect } from "react";
import { ApplyChangesCard } from "../components/EditOrderComponents/ApplyChangesCard";
import { useOrderUIContext } from "../OrderUIContext";
import { useState } from "react";
import UpdateSubscriptionModal from "../components/EditOrderComponents/Modal/UpdateSubscriptionModal";
import { useHistory } from "react-router-dom";
import { EditOrderList } from "../components/EditOrderComponents/EditOrderList";
import { EditBreakDownCard } from "../components/EditOrderComponents/EditBreakDownCard";
import { Table } from "react-bootstrap";
import { useContextState } from "../reducer/editOrderStripeReducer";
import moment from "moment";
import {
  Switch,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from "@material-ui/core";
import { deleteStateData, planProductId } from "../_mocks/dummyData";
import _ from "lodash";
import { editStripeOrder, getPeriod } from "../_redux/order/orderCrud";
import {
  UpdateData,
  removeCouponFromPhases,
  resetDiscounts,
  transformData,
} from "./editStripeUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import { LoadingSpinner } from "../../../../_metronic/_partials/controls/LoadingSpinner.js";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar.js";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { LoadingCustome } from "../../../../_metronic/_partials/controls/LoadingCustome.js";
import AddProductModal from "../components/EditOrderComponents/Modal/AddProoductModal.js";
import AddProductMainModal from "../components/EditOrderComponents/Modal/AddProductMainModal.js";
import Checkbox from "@material-ui/core/Checkbox";

export const EditOrderStripe = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { state, dispatchReducer } = useContextState();
  const history = useHistory();
  const [dialog, setDialog] = useState({ update: false });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [updateLoading, setUploadLoading] = useState(false);
  const [periodData, setPeriodData] = useState(null);

  const orderUIContext = useOrderUIContext();
  const {
    editOrderDetail,
    editOrderSummary,
    subLoading,
    callApi,
    orderSummaryListStripe,
  } = useSelector(
    (state) => ({
      editOrderDetail: state.order.editOrderDetail,
      editOrderSummary: state.order.editOrderSummary,
      subLoading: state.order.sub,
      callApi: state.order.callApi,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEditOrderDetailCard(props?.match?.params?.id));
    getPeriod(props?.match?.params?.id).then((res) => {
      setPeriodData(res.data.data);
    });
  }, [dispatch, props.match.params.id]);
  console.log("periodData", periodData);

  useEffect(() => {
    if (editOrderDetail?.subscription_data) {
      const insurance_cover = editOrderDetail?.order_summary?.find(
        (e) =>
          e?.stripe_product_id === process.env.REACT_APP_PRODUCT_POD_PROTECT ||
          e?.stripe_product_id === process.env.REACT_APP_PRODUCT_STORE_PROTECT
      );
      console.log("insurance_cover", insurance_cover);

      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          ...editOrderDetail?.subscription_data,
          proration_behavior: "none",
          insurance_cover:
            editOrderDetail?.insurance_cover || insurance_cover?.cover || "",
          insurance_price: insurance_cover?.total || "",
        },
      });
    }
  }, [editOrderDetail?.subscription_data]);

  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    const phaseArr = [...state.phases];
    const phase = phaseArr.find((e) => e.is_edit);
    phase.proration_behavior = event.target.value;

    dispatchReducer({
      type: "SET_DATA",
      data: {
        ...state,
        proration_behavior: event.target.value,
        phases: phaseArr,
        is_call_upcoming: true,
      },
    });
    setValue(event.target.value);
  };

  const handleUpdateOrder = () => {
    setUploadLoading(true);
    setSnack({ call: false, type: "", message: "" });
    const phase = state.phases.find((e) => e.is_edit);
    console.log("planProductId", planProductId, phase.items);

    const podQty = phase.items.find((item) =>
      planProductId.includes(item.product_id)
    )?.quantity;
    const cleanedData = UpdateData(
      state,
      props?.match?.params?.id,
      periodData,
      podQty,
      editOrderDetail
    );
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    copyCleanedData.schedule_data.phases.map((phase, index) => {
      phase.discounts = state.phases[index].discounts.map((discount) => {
        const { discount: _, promotion_code: __, ...rest } = discount;
        return rest;
      });
    });
    // copyCleanedData.schedule_data.phases = copyCleanedData.schedule_data.phases.map(
    //   (phase, index) => ({
    //     ...phase,
    //     discounts: state.phases[index].discounts
    //       .filter((discount) => discount.coupon !== null) // Remove discounts with null coupon
    //       .map(({ discount: _, promotion_code: __, ...rest }) => rest), // Remove discount & promotion_code keys
    //   })
    // );

    //
    const phasePro = state?.phases?.find((e) => e.is_edit);
    const phaseProIndex = state?.phases?.findIndex((e) => e.is_edit);

    const planPr = phasePro?.items?.filter((item) => {
      const match = orderSummaryListStripe?.allProducts?.find(
        (e) => e?.stripe_product_id == item.product_id
      );
      return match?.type === "plan";
    });
    const phaseProOld =
      editOrderDetail?.subscription_data?.phases[phaseProIndex];

    const planPrOld = phaseProOld?.items?.filter((item) => {
      const match = orderSummaryListStripe?.allProducts?.find(
        (e) => e?.stripe_product_id == item.product_id
      );
      return match?.type === "plan";
    });
    console.log("planPrOld", planPrOld, planPr);

    copyCleanedData.planOldQuantity = planPrOld?.length
      ? planPrOld[0]?.quantity
      : 0;
    copyCleanedData.planNewQuantity = planPr[0]?.quantity;
    copyCleanedData.liveTest = true;
    function removeExpiredPhases(data) {
      // Get today's date as a Unix timestamp
      const today = Math.floor(Date.now() / 1000);

      // Ensure there are phases to process
      if (
        !data?.schedule_data?.phases ||
        data.schedule_data.phases.length === 0
      ) {
        return data; // Return the original data if no phases exist
      }

      // Filter out the phases that have an end_date less than today, except the last one
      data.schedule_data.phases = data.schedule_data.phases.filter(
        (phase, index, array) => {
          // If it's the last phase, keep it regardless of its end_date
          if (index === array.length - 1) {
            return true;
          }

          // Otherwise, remove the phase if its end_date is less than today
          return phase.end_date >= today;
        }
      );

      // Return the modified data object
      return data;
    }
    editStripeOrder(removeExpiredPhases(copyCleanedData))
      .then((res) => {
        setUploadLoading(false);

        // history.push(`/order-details/${props?.match?.params?.id}`);
        setSnack({
          call: true,
          type: "success",
          message: "Subscription Updated Successfully ",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        setUploadLoading(false);
        setSnack({
          call: true,
          type: "error",
          message: error?.response?.data?.message,
        });
      });
  };

  const handleUpcomingApi = (summary) => {
    const cleanedData = UpdateData(
      state,
      props?.match?.params?.id,
      periodData,
      "",
      editOrderDetail
    );
    cleanedData.customer = cleanedData.customer_id;
    const copyCleanedData = JSON.parse(JSON.stringify(cleanedData));
    copyCleanedData.schedule_data.phases.map((phase, index) => {
      phase.discounts = state.phases[index].discounts.map((discount) => {
        const { discount: _, promotion_code: __, ...rest } = discount;
        return rest;
      });
    });
    // copyCleanedData.schedule_data.phases = copyCleanedData.schedule_data.phases.map(
    //   (phase, index) => ({
    //     ...phase,
    //     discounts: state.phases[index].discounts
    //       .filter((discount) => discount.coupon !== null) // Remove discounts with null coupon
    //       .map(({ discount: _, promotion_code: __, ...rest }) => rest), // Remove discount & promotion_code keys
    //   })
    // );
    copyCleanedData.customer = copyCleanedData.customer_id;
    copyCleanedData.liveTest = true;
    // const gog = resetDiscounts(copyCleanedData);
    console.log("copyCleanedData", copyCleanedData);
    function removeExpiredPhases(data) {
      // Get today's date as a Unix timestamp
      const today = Math.floor(Date.now() / 1000);

      // Ensure there are phases to process
      if (
        !data?.schedule_data?.phases ||
        data.schedule_data.phases.length === 0
      ) {
        return data; // Return the original data if no phases exist
      }

      // Filter out the phases that have an end_date less than today, except the last one
      data.schedule_data.phases = data.schedule_data.phases.filter(
        (phase, index, array) => {
          // If it's the last phase, keep it regardless of its end_date
          if (index === array.length - 1) {
            return true;
          }

          // Otherwise, remove the phase if its end_date is less than today
          return phase.end_date >= today;
        }
      );

      // Return the modified data object
      return data;
    }

    dispatch(fetchEditOrderSummary(removeExpiredPhases(copyCleanedData)));
  };

  const [callUpComing, setCallUpComing] = useState(false);
  useEffect(() => {
    if (state?.is_call_upcoming && periodData) {
      handleUpcomingApi(editOrderDetail);
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          is_call_upcoming: false,
        },
      });
    }
  }, [state?.is_call_upcoming, periodData]);
  const todayTimestamp = Math.floor(Date.now() / 1000); // Get current timestamp
  const hasFutureEditablePhase = state?.phases?.some(
    (phase) => phase.is_edit && phase.start_date > todayTimestamp
  );
  const hasActiveEditablePhase = state?.phases?.some(
    (phase) =>
      phase.start_date < todayTimestamp && phase.end_date > todayTimestamp
  );

  console.log("state-123123", state);
  return (
    <div>
      {/* {editOrderDetail?.id ? "" : <LoadingCustome />} */}
      <NewBreadCrumbs breadcrumb={true} title={"Edit Order"} />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          fontSize: "16px",
          marginBottom: "2%",
          color: "red",
        }}
      >
        {state?.phases?.some((e) => e.is_edit) ? (
          ""
        ) : (
          <span>
            When edit any phase, you also need to edit the subsequent phases to
            maintain consistency.
          </span>
        )}
      </div>
      <div className="row">
        {/* block of two btn update and dismiss*/}
        {state?.phases?.some((e) => e.is_edit) && (
          <div className="col-md-12">
            <div className="text-right mb-2">
              <button
                type="button"
                class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
                onClick={() => {
                  const updatedArray = state.phases
                    // Filter out the objects that have `is_add_phase` set to true
                    .filter((item) => !item.is_add_phase)
                    // Map over the filtered array to set `is_edit` to false if it's true
                    .map((item) => ({
                      ...item,
                      is_edit: item.is_edit ? false : item.is_edit,
                    }));
                  dispatchReducer({
                    type: "SET_DATA",
                    data: {
                      ...state,
                      phases: updatedArray,
                    },
                  });
                }}
              >
                Dismiss
              </button>
              {updateLoading ? (
                <LoadingCustome />
              ) : (
                <button
                  type="button"
                  class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 "
                  onClick={handleUpdateOrder}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        )}
        {/* this for showing all phases with edit btn */}
        {!state?.phases?.some((e) => e.is_edit) && (
          <div className="col-md-12">
            <div className="card card-custom gutter-b">
              <div className="card-body pt-0">
                <div
                  className="py-4"
                  style={{
                    marginBottom: "-24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">
                      Subscription detail
                    </span>
                  </h3>
                </div>
                <br />
                {!state?.phases && <LoadingSpinner />}
                {state?.phases?.length > 0 &&
                  state?.phases.map((e, i) => {
                    const productText = e.items
                      .map((item) => `${item.product_name} x ${item.quantity}`)
                      .join(", ");

                    function checkSinglePhaseEndDate(state, index) {
                      // Get today's date as a Unix timestamp
                      const today = Math.floor(Date.now() / 1000);

                      // Ensure state and phases are defined
                      if (!state?.phases || state.phases.length === 0)
                        return false;

                      // Get the current phase based on the provided index
                      const currentPhase = state.phases[index];

                      // If the phase does not exist, return false
                      if (!currentPhase) return false;

                      // If it's the last phase, return true
                      if (index === state.phases.length - 1) {
                        return true;
                      }

                      // Check if the current phase's end date is before today
                      if (currentPhase.end_date < today) {
                        return false;
                      }

                      // Return true if neither condition is met
                      return true;
                    }

                    console.log("erty", checkSinglePhaseEndDate(state, i));
                    if (!checkSinglePhaseEndDate(state, i)) return;
                    return (
                      <>
                        <div
                          className="d-flex w-100"
                          style={{ justifyContent: "space-between" }}
                          key={i}
                        >
                          <div>
                            <div>
                              <span style={{ fontSize: "16px" }}>
                                <strong>
                                  {moment(e.start_date * 1000).format(
                                    "MMMM D, YYYY"
                                  ) +
                                    " - " +
                                    // moment(e.end_date * 1000).format(
                                    //   "MMMM D, YYYY"
                                    // )
                                    (e?.cancel_at ||
                                    i === state?.phases?.length - 1
                                      ? "Forever"
                                      : moment(e?.end_date * 1000).format(
                                          "MMMM D, YYYY"
                                        ))}
                                </strong>
                              </span>
                            </div>
                            <div>
                              <span style={{ fontSize: "12px" }}>
                                <>{productText}</>
                              </span>
                            </div>
                          </div>
                          <div style={{ display: "flex" }}>
                            {checkSinglePhaseEndDate(state, i) && (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  console.log("popop", i, state);

                                  if (
                                    e.start_date < todayTimestamp &&
                                    e.end_date > todayTimestamp
                                  ) {
                                    const resetParam =
                                      (i === 0 && state.phases?.length > 1) ||
                                      state.phases?.length === 1
                                        ? ""
                                        : "";
                                    const queryParam = resetParam
                                      ? `?${resetParam}`
                                      : "";
                                    history.push(
                                      `/edit-order-release/${props?.match?.params?.id}${queryParam}`
                                    );
                                    return;
                                  }
                                  const phases = [...state.phases];
                                  const updatedPhase = {
                                    ...phases[i],
                                    is_edit: true,
                                  };
                                  // Replace the old phase object with the updated one
                                  phases[i] = updatedPhase;
                                  dispatchReducer({
                                    type: "SET_DATA",
                                    data: {
                                      ...state,
                                      phases,
                                    },
                                  });
                                  console.log(
                                    "editOrderDetail-123",
                                    editOrderDetail
                                  );

                                  handleUpcomingApi(editOrderDetail);
                                  dispatch(
                                    fetchOrderSummaryListStripe({
                                      franchise_id:
                                        editOrderDetail?.franchise_id,
                                      postcode:
                                        editOrderDetail?.postcode ||
                                        editOrderDetail?.collection_postcode,
                                      site_id:
                                        (editOrderDetail?.order_container_details &&
                                          editOrderDetail
                                            ?.order_container_details?.length >
                                            0 &&
                                          editOrderDetail
                                            ?.order_container_details[0]
                                            ?.site_id) ||
                                        "",
                                    })
                                  );
                                }}
                              >
                                Edit
                              </div>
                            )}
                            {state?.phases?.length > 2 && i == 0 ? "" : ""}
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                <div>
                  <button
                    type="button"
                    class="btn btn-primaryCus font-weight-bolder font-size-sm mr-3 w-100 "
                    onClick={() => {
                      const phases = [...state.phases];
                      phases.push(phases[phases.length - 1]);
                      const updatedPhase = {
                        ...phases[phases.length - 1],
                        is_add_phase: true,
                        is_edit: true,
                      };
                      // Replace the old phase object with the updated one
                      phases[phases.length - 1] = updatedPhase;
                      updatedPhase.start_date =
                        phases[phases.length - 1].end_date;
                      updatedPhase.end_date = null;

                      // Replace the old phase object with the updated one
                      dispatch(
                        fetchOrderSummaryListStripe({
                          franchise_id: editOrderDetail?.franchise_id,
                          postcode:
                            editOrderDetail?.postcode ||
                            editOrderDetail?.collection_postcode,
                          site_id:
                            (editOrderDetail?.order_container_details &&
                              editOrderDetail?.order_container_details?.length >
                                0 &&
                              editOrderDetail?.order_container_details[0]
                                ?.site_id) ||
                            "",
                        })
                      );
                      dispatchReducer({
                        type: "SET_DATA",
                        data: {
                          ...state,
                          phases,
                        },
                      });
                    }}
                  >
                    + Add Phase
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*  This block is for showing phase data when the edit page is clicked  */}
        {state?.phases?.some((e) => e.is_edit) && (
          <>
            <div className="col-md-8">
              <div className="card card-custom gutter-b">
                <EditOrderList
                  state={state}
                  setCallUpComing={setCallUpComing}
                  dispatchReducer={dispatchReducer}
                  phases={state.phases?.find((e) => e?.is_edit)}
                  editOrderDetail={editOrderDetail}
                  orderUIContext={orderUIContext}
                  orderSummaryListStripe={orderSummaryListStripe}
                />
                {state.status === "not_started" &&
                state?.phases?.findIndex((e) => e.is_edit) == 0 ? (
                  ""
                ) : (
                  <div className="card-body pt-0">
                    {(editOrderDetail?.subscription_data?.status === "active" ||
                      hasFutureEditablePhase) && (
                      <div className="py-4">
                        {/* {!hasFutureEditablePhase && (
                          <div className="mb-4">
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state?.phases?.some(
                                      (phase) =>
                                        phase.is_edit &&
                                        phase.billing_cycle_anchor === "now"
                                    )}
                                    onChange={(e) => {
                                      console.log("event", e.target.checked);

                                      const updatedPhases = state?.phases?.map(
                                        (phase) => {
                                          if (!phase.is_edit) return phase; // Keep unchanged phases

                                          const updatedPhase = { ...phase };

                                          if (e.target.checked) {
                                            updatedPhase.billing_cycle_anchor =
                                              "now";
                                          } else {
                                            delete updatedPhase.billing_cycle_anchor; // Remove the key when unchecked
                                          }

                                          return updatedPhase;
                                        }
                                      );

                                      dispatchReducer({
                                        type: "SET_DATA",
                                        data: {
                                          ...state,
                                          phases: updatedPhases,
                                          is_call_upcoming: true,
                                        },
                                      });
                                    }}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                              />
                              <div style={{ marginLeft: "-20px" }}>
                                <h6 style={{ marginBottom: "-1px" }}>
                                  Reset billing cycle
                                </h6>
                                <span>
                                  This will generate an invoice immediately
                                </span>
                              </div>
                            </FormGroup>
                          </div>
                        )} */}
                        {/* <div>
                          <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">
                              Proration behavior
                            </span>
                          </h3>
                        </div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  hasFutureEditablePhase
                                    ? state?.proration_behavior ===
                                      "create_prorations"
                                    : state?.proration_behavior !== "none"
                                }
                                onChange={(e) => {
                                  if (hasFutureEditablePhase) {
                                    dispatchReducer({
                                      type: "SET_DATA",
                                      data: {
                                        ...state,
                                        proration_behavior:
                                          state?.proration_behavior === "none"
                                            ? "create_prorations"
                                            : "none",
                                        is_call_upcoming: true,
                                      },
                                    });
                                    return;
                                  }
                                  dispatchReducer({
                                    type: "SET_DATA",
                                    data: {
                                      ...state,
                                      proration_behavior:
                                        state?.proration_behavior === "none"
                                          ? ""
                                          : "none",
                                    },
                                  });
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Prorate changes"
                          />
                        </FormGroup>

                        {!hasFutureEditablePhase &&
                        state?.proration_behavior !== "none" ? (
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="gender"
                              name="gender1"
                              value={state?.proration_behavior}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="create_prorations"
                                control={<Radio />}
                                label="Charge proration amount on next invoice"
                              />
                              <FormControlLabel
                                value="always_invoice"
                                control={<Radio />}
                                label="Charge proration amount immediately"
                              />
                            </RadioGroup>
                          </FormControl>
                        ) : (
                          ""
                        )} */}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-custom gutter-b">
                <div className="card-body pt-0">
                  <div className="py-4" style={{ marginBottom: "-24px" }}>
                    <h3 className="card-title align-items-start flex-column">
                      <span className="font-weight-bolder text-dark">
                        Summary
                      </span>
                    </h3>
                  </div>
                  {subLoading && <LoadingSpinner />}
                  {editOrderSummary.period_start && (
                    <List className={classes.root}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <CalendarTodayIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={moment(
                            editOrderSummary &&
                              editOrderSummary.period_start &&
                              editOrderSummary.period_start * 1000
                          ).format("MMMM D, YYYY")}
                          secondary="Subscription updates"
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ReceiptIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Next Invoice"
                          secondary={`Amount Due £${
                            editOrderSummary &&
                            editOrderSummary.amount_due &&
                            !hasFutureEditablePhase
                              ? Number(editOrderSummary.amount_due) / 100
                              : ""
                          } ${hasFutureEditablePhase ? "0." : ""} `}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ReceiptIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Billing"
                          secondary={`Bills on ${
                            editOrderSummary?.created
                              ? moment(editOrderSummary?.created * 1000).format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }`}
                        />
                      </ListItem>
                    </List>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-md-4">
          {Object.keys(orderUIContext?.state?.breakdown).length > 0 && (
            <div className="card card-custom gutter-b">
              <EditBreakDownCard orderUIContext={orderUIContext} />
            </div>
          )}
        </div>
        {orderUIContext?.state?.orderData?.status === "active" && (
          <div className="col-md-8">
            <div className="card card-custom gutter-b">
              <ApplyChangesCard orderUIContext={orderUIContext} />
            </div>
          </div>
        )}
      </div>
      {dialog.update && (
        <UpdateSubscriptionModal
          dialog={dialog}
          setDialog={setDialog}
          orderUIContext={orderUIContext}
        />
      )}
      {dialog?.check && (
        <AddProductMainModal
          dialog={dialog}
          setDialog={setDialog}
          state={state}
          dispatchReducer={dispatchReducer}
        />
      )}
      {snack.call ? <SnackBarTool {...snack} /> : ""}
    </div>
  );
};
