import React from "react";

const TOTAL_BAR_WIDTH = 600; // Fixed width for bars

const BarChart = ({ oNASiteData }) => {
  return (
    <div className="chart-container">
      {/* Legend */}
      <div className="legend">
        <div className="legend-item">
          <span className="box occupied"></span> Occupied
        </div>
        <div className="legend-item">
          <span className="box available"></span> Available
        </div>
        <div className="legend-item">
          <span className="box capacity"></span> Capacity
        </div>
        <div className="legend-item">
          <span className="box not-sold"></span> Not Sold
        </div>
      </div>

      {/* Bars */}
      {Object.entries(oNASiteData).map(
        ([key, { capacity, available, occupied }]) => {
          const total = capacity !== null ? capacity : 1;
          const occupiedWidth =
            occupied > 0 ? (occupied / total) * TOTAL_BAR_WIDTH : 0;
          const availableWidth =
            available > 0 ? (available / total) * TOTAL_BAR_WIDTH : 0;
          const capacityWidth =
            capacity !== null
              ? ((capacity - (occupied + available)) / total) * TOTAL_BAR_WIDTH
              : 0;
          const notSoldWidth = capacity === null ? TOTAL_BAR_WIDTH : 0;

          return (
            <div key={key} className="bar-group">
              <div className="barLabel">{key.replace("_", " ")}</div>
              <div className="bar">
                {occupied > 0 && (
                  <div
                    className="occupied"
                    style={{ width: `${occupiedWidth}px` }}
                  ></div>
                )}
                {available > 0 && (
                  <div
                    className="available"
                    style={{ width: `${availableWidth}px` }}
                  ></div>
                )}
                {capacity !== null && capacity - (occupied + available) > 0 && (
                  <div
                    className="capacity"
                    style={{ width: `${capacityWidth}px` }}
                  ></div>
                )}
                {capacity === null && (
                  <div
                    className="not-sold"
                    style={{ width: `${notSoldWidth}px` }}
                  ></div>
                )}
              </div>
              <div className="values w-100">
                {occupied > 0 && (
                  <span
                    className="occupiedT"
                    style={{ width: `${occupiedWidth}px`, textAlign: "start" }}
                  >
                    <strong>{occupied}</strong>
                  </span>
                )}
                {available > 0 && (
                  <span
                    className="availableT"
                    style={{
                      width: `${availableWidth}px`,
                      textAlign: "center",
                    }}
                  >
                    <strong>{available}</strong>
                  </span>
                )}
                {capacity !== null && capacity - (occupied + available) > 0 && (
                  <span
                    className="capacityT"
                    style={{ width: `${capacityWidth}px`, textAlign: "end" }}
                  >
                    <strong> {capacity}</strong>
                  </span>
                )}
                {capacity === null && (
                  <span
                    style={{ width: `${notSoldWidth}px`, textAlign: "center" }}
                  >
                    Not Sold
                  </span>
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default BarChart;
