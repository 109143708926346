/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  CustomerDetailCard,
  OrderSummary,
  SiteCard,
  NewOrderCollection,
} from "./components";
// import { CreateContactOffCanvas } from "../../../ContactDeals/canvas/CreateContactOffCanvas";
import {
  AddEditStorage,
  CustomerDetailEdit,
  NewDistanceDuration,
} from "../../Order/order-canva";
import { useContextState } from "../reducer/reducerStripe";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateFranchise } from "../_redux/order/orderCrud";
import {
  fetchNewCustomerCard,
  fetchSearchNewOrder,
  fetchOrderSummaryListStripe,
  fetchQuoteOrderData,
  fetchNearSite,
} from "../_redux/order/orderAction";
import { AutoCompleteComponent } from "./components/AutoCompleteComponent";
import { formValues } from "../orderUtils";
import {
  generateDefaultContainerProduct,
  generateDefaultPodProduct,
  multiplyCostByNumber,
} from "./NewOrderStripeUtils";
import { useLocation } from "react-router-dom";
import OrderDetailDistance from "../components/OrderDetailDistance";
import OrderDetailDistanceStripe from "../components/OrderDetailDistanceStripe";
import { ContainerConfirmModal } from "../NewOrder/modal/ContainerConfirmModal";
import { NearSiteModal } from "../NewOrder/modal/NearSiteModal";
import { orderSlice } from "../_redux/order/orderSlice";
import SnackBarTool from "../../../../_metronic/_partials/widgets/snack-bar/SnackBar";
import { OrderNotes } from "../components/OrderNotes";

export const NewOrderStripe = () => {
  // location?.search.includes("quote")
  const intl = useIntl();
  const {
    orderData,
    searchNewOrder,
    cardDetail,
    storage,
    quoteData,
    admin,
    permission,
    role,
    cardDetailArray,
    orderSummaryListStripe,
    orderListLoading,
    siteData,
    siteLoading,
  } = useSelector(
    (state) => ({
      orderData: state.order,
      searchNewOrder: state.order.searchNew,
      cardDetail: state.order.cardDetail,
      cardDetailArray: state.order.cardDetailArray,
      storage: state.auth.storageData?.customerData,
      quoteData: state.order.quoteData,
      admin: state.auth.user,
      permission: state.auth.permission,
      role: state.auth.user.roles,
      orderSummaryListStripe: state.order.orderSummaryListStripe,
      orderListLoading: state.order.callApi,
      siteData: state.order.siteData,
      siteLoading: state.order.siteLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const { actions } = orderSlice;

  const location = useLocation();
  const [openCanva, setOpenCanva] = useState(false);
  const { state, dispatchReducer } = useContextState();
  const [dialogType, setDialogType] = useState({ open: false });
  const [dialogSite, setDialogSite] = useState({ open: false });
  const [snack, setSnack] = useState({ call: false, type: "", message: "" });
  const [callOnceQuote, setCallOnceQuote] = useState(false);
  const [callOnceSite, setCallOnceSite] = useState(true);

  const [billingCall, setBillingCall] = useState(false);
  const [keyDis, setKeyDis] = useState("collection");
  const [oNASiteData, setONASiteData] = useState(null);

  const editValues = formValues(cardDetail, admin);

  useEffect(() => {
    console.log("cardDetail[0]?.postcode", cardDetail);
    if (
      cardDetail &&
      cardDetail.length > -1 &&
      cardDetail[0]?.contact?.franchise_id
    ) {
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          franchise_id: cardDetail[0]?.contact?.franchise_id,
          postcode: cardDetail[0]?.postcode,
        },
      });
      // setDialogType({ open: true });
    }
  }, [
    cardDetail.length,
    cardDetail[0]?.postcode,
    cardDetail[0]?.contact?.franchise_id,
  ]);

  //all-product
  useEffect(() => {
    if (state?.franchise_id) {
      console.log("state-123", state);

      dispatch(
        fetchOrderSummaryListStripe({
          franchise_id: state?.siteSelect?.franchise_id || state?.franchise_id,
          postcode: state?.postcode,
          site_id: state?.siteSelect?.id || "",
        })
      );
      // setSelectV(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state?.franchise_id, state?.postcode, state?.siteSelect?.id]);
  useEffect(() => {
    console.log("location", location);

    if (location?.search.includes("quote") && location.state?.quote) {
      setCallOnceQuote(true);
      dispatch(
        fetchQuoteOrderData(
          location.state?.franchise_id,
          location.state?.quote,
          setSnack
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location?.state?.id]);

  useEffect(() => {
    if (location?.state?.id) {
      dispatch(fetchSearchNewOrder(""));
      dispatch(fetchNewCustomerCard(location.state.id));
      // setSelectV(true);
    } else if (quoteData && quoteData?.customer_id) {
      let data = quoteData?.data_request;
      // console.log("9090",data);
      if (quoteData?.data_request?.containerTypeSelect == "container") {
        const site_id = data.siteSelect.id;
        data = { ...data, siteSelect: {} };
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, ...data, site_id },
        });
      } else {
        data = { ...data };
        dispatchReducer({
          type: "SET_DATA",
          data: { ...state, ...data },
        });
      }
      dispatch(fetchSearchNewOrder(""));
      dispatch(fetchNewCustomerCard(quoteData.customer_id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location?.state?.id, quoteData.customer_id]);
  useEffect(() => {
    if (
      cardDetail &&
      cardDetail.length &&
      cardDetail[0]?.contact?.franchise_id &&
      location?.search.includes("quote")
    ) {
      const data = {
        franchise_id: cardDetail[0]?.contact?.franchise_id,
        containerSize: state?.containerListSelect,
        type: state?.containerList?.find(
          (e) => e.id === state?.containerListSelect
        )?.type,
      };
      const address = cardDetail[0]?.address + cardDetail[0]?.postcode;
      dispatch(fetchNearSite(data, address));
    }
  }, [cardDetail.length]);
  useEffect(() => {
    if (
      location?.search.includes("quote") &&
      state.site_id &&
      !siteLoading &&
      callOnceSite &&
      siteData.length > 0
    ) {
      const siteata = siteData.find((e) => e.id == state.site_id);
      dispatchReducer({
        type: "SET_DATA",
        data: { ...state, siteSelect: {} },
      });
      setCallOnceSite(false);
    }
  }, [
    location?.search.includes("quote"),
    state.site_id,
    !siteLoading,
    callOnceSite,
    siteData.length,
  ]);

  useEffect(() => {
    if (
      orderSummaryListStripe &&
      // state?.product_array?.length < 1 &&
      state.containerTypeSelect &&
      !orderListLoading &&
      !state.collectionRetrun?.return_date &&
      !callOnceQuote
    ) {
      const product_array =
        state.containerTypeSelect === "pod"
          ? generateDefaultPodProduct(orderSummaryListStripe?.pod)
          : generateDefaultContainerProduct(orderSummaryListStripe?.container);
      console.log("product_array-12", orderSummaryListStripe);
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          product_array,
          is_billing_call: true,
          set: 1,
          defaultProduct: false,
        },
      });
    }
    if (!location?.search.includes("quote")) {
      setCallOnceQuote(false);
    }

    return () => {};
  }, [orderSummaryListStripe, state.containerTypeSelect, orderListLoading]);

  useEffect(() => {
    if (state?.defaultProduct) {
      const product_array =
        state.containerTypeSelect === "pod"
          ? generateDefaultPodProduct(orderSummaryListStripe?.pod)
          : generateDefaultContainerProduct(orderSummaryListStripe?.container);
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          product_array,
          is_billing_call: true,
          set: 1,
          defaultProduct: false,
          type_change: false,
        },
      });
    }

    return () => {};
  }, [state.defaultProduct]);

  useEffect(() => {
    return () => {
      dispatchReducer({
        type: "RESET_DATA",
      });
      dispatch(actions.blankCustomer({}));
      dispatchReducer({ type: "containerTypeSelect", value: "" });
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedUserItems");
    };
  }, []);

  useEffect(() => {
    if (location?.search.includes("customer")) {
      dispatchReducer({
        type: "SET_DATA",
        data: {
          ...state,
          containerTypeSelect: "pod",
          plan_type: ["container", "pod"],
          // defaultProduct: true,
        },
      });
    }
  }, [cardDetail?.length]);

  return (
    <>
      {snack.call ? <SnackBarTool {...snack} /> : ""}

      <div
        className="customer-search d-flex justify-content-end mt-2 row"
        style={{ width: "50%" }}
      >
        <div className="mr-2 mt-2">
          <AutoCompleteComponent
            dispatchReducer={dispatchReducer}
            state={state}
            role={role}
            setDialogType={setDialogType}
            cardDetail={cardDetail}
            searchNewOrder={searchNewOrder}
            intl={intl}
          />
        </div>
      </div>
      <div className="mt-8">
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <CustomerDetailCard
              detailData={cardDetail && cardDetail.length && cardDetail[0]}
              franchise={
                cardDetail &&
                cardDetail.length > -1 &&
                cardDetail[0]?.contact?.franchise?.name
              }
              agent={
                cardDetail &&
                cardDetail.length > -1 &&
                cardDetail[0]?.contact?.agent?.first_name
              }
              agentLast={
                cardDetail &&
                cardDetail.length > -1 &&
                cardDetail[0]?.contact?.agent?.last_name
                  ? cardDetail[0].contact.agent.last_name
                  : "-"
              }
              orderDataLoading={orderData?.detailLoading}
              openCanva={openCanva}
              setOpenCanva={setOpenCanva}
              type={"newOrder"}
              state={state}
              cardDetail={cardDetail}
              setBillingCall={setBillingCall}
              editValues={editValues}
              dispatchReducer={dispatchReducer}
              setDialogType={setDialogType}
              role={role}
            />
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12 col-lg-6">
          <OrderSummary
            state={state}
            dispatchReducer={dispatchReducer}
            orderSummaryListStripe={orderSummaryListStripe}
            cardDetail={cardDetail}
            // franchise={
            //    ''
            // }
            // location={location}
            // setCheckLocation={setCheckLocation}
            // checkLocation={checkLocation}
            // quoteData={quoteData}
            // dispatchReducer={dispatchReducer}
            // state={state}
            // setParamData={setParamData}
            // paramData={paramData}
            // billingCall={billingCall}
            // setBillingCall={setBillingCall}
            // orderDataLoading={orderData?.detailLoading}
          />
        </div>

        <div className="col-md-12 col-lg-6">
          {state.containerTypeSelect === "container" && (
            <SiteCard
              state={state}
              setDialogType={setDialogType}
              dialogType={dialogType}
              setDialogSite={setDialogSite}
              oNASiteData={oNASiteData}
            />
          )}
          <div className="mb-8">
            <NewOrderCollection
              setDialogType={setDialogType}
              franchise={state?.franchise_id}
              dispatchReducer={dispatchReducer}
              state={state}
              customerAddress={
                cardDetail && cardDetail.length > -1 && cardDetail[0]?.address
              }
              postcode={
                cardDetail && cardDetail.length > -1 && cardDetail[0]?.postcode
              }
              city={cardDetail && cardDetail.length > -1 && cardDetail[0]?.city}
              keyDis={keyDis}
              setKeyDis={setKeyDis}
              paramData={state?.billingParam}
            />
            {state.containerTypeSelect !== "container" && (
              <OrderDetailDistanceStripe
                orderDetail={
                  cardDetail && cardDetail.length > -1 && cardDetail[0]
                }
                type={"new_order"}
                customerAddress={
                  cardDetail && cardDetail.length > -1 && cardDetail[0]?.address
                }
                postcode={
                  cardDetail &&
                  cardDetail.length > -1 &&
                  cardDetail[0]?.postcode
                }
                state={state}
                franchise={state?.franchise_id}
                paramData={state?.billingParam}
                dispatchReducer={dispatchReducer}
                keyDis={keyDis}
                setKeyDis={setKeyDis}
              />
            )}
            <OrderNotes
              state={state}
              title={"Driver Note"}
              dispatchReducer={dispatchReducer}
              cardDetail={cardDetail}
              mainText={quoteData?.driverNotes}
              location={location && location}
            />
            {/* {location?.search.includes("quote") ? (
                            <OrderNotes
                                state={state}
                                title={"Notes"}
                                mainText={quoteData?.notes}
                                type={"quote"}
                                dispatchReducer={dispatchReducer}
                                cardDetail={cardDetail}
                            />
                        ) : (
                            ""
                        )} */}
          </div>
        </div>
      </div>

      {/* <NewDistanceDuration />
            <AddEditStorage /> */}
      {/* <ContactDealModal
                
            /> */}

      {dialogType.open && (
        <ContainerConfirmModal
          show={dialogType}
          setShow={setDialogType}
          dispatchReducer={dispatchReducer}
          state={state}
          setDialogSite={setDialogSite}
        />
      )}
      {dialogSite.open && (
        <NearSiteModal
          show={dialogSite}
          setShow={setDialogSite}
          dispatchReducer={dispatchReducer}
          state={state}
          cardDetail={cardDetail}
          setONASiteData={setONASiteData}
        />
      )}
    </>
  );
};
