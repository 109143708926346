export function transformData(data, summary) {
  // console.log(
  //   "data-890",
  //   data,
  //   summary?.order_summary.find((e) => e.type === "plan")
  // );
  // const period = summary?.order_summary.find((e) => e.type === "plan");
  // Helper function to transform each item to the desired structure
  const transformItem = (item) => {
    return {
      price_data: {
        currency: "gbp",
        product: item.product_id || item.price, // Use product_id or price as product
        recurring: {
          interval_count: summary?.interval_count || "", // Assuming this is fixed as per the example
          interval: summary?.interval || "", // Assuming monthly interval as per the example
        },
        tax_behavior: "unspecified",
        unit_amount_decimal: item.unit_price ? item.unit_price * 100 : 0,
      },
      quantity: item.quantity,
      tax_rates: item.tax_rates.length ? item.tax_rates : "",
    };
  };
  const transformItemCharge = (item) => {
    return {
      price_data: {
        currency: "gbp",
        product: item.product_id || item.price, // Use product_id or price as product

        tax_behavior: "unspecified",
        unit_amount_decimal: item.unit_price ? item.unit_price * 100 : 0,
      },
      quantity: item.quantity,
      tax_rates: item.tax_rates.length ? item.tax_rates : "",
    };
  };
  // If data is not an array, treat it as a single object
  if (!Array.isArray(data)) {
    data = [data]; // Wrap the object into an array
  }
  return data.map((entry) => {
    // if(entry.is_edit){
    // Transform the add_invoice_items and items arrays
    const transformedAddInvoiceItems = entry.add_invoice_items.map(
      transformItemCharge
    );
    const transformedItems = entry.items.map(transformItem);

    return {
      ...entry,
      add_invoice_items: [...transformedAddInvoiceItems],
      items: [...transformedItems, ...transformedAddInvoiceItems],
    };
    // }else{
    //   return{
    //     ...entry
    //   }
    // }
  });
}
export function removeMatchingItems(data) {
  // Iterate over each element in the data array
  return data.map((entry) => {
    // Extract add_invoice_items and items for comparison
    const { add_invoice_items, items } = entry;

    // Filter out items whose product matches any add_invoice_items product_id
    const filteredItems = items.filter((item) => {
      return !add_invoice_items.some(
        (invoiceItem) =>
          invoiceItem.price_data.product === item.price_data.product
      );
    });

    // Return the updated entry with filtered items
    return {
      ...entry,
      items: filteredItems,
    };
  });
}
export const UpdateData = (
  state,
  order_id,
  summary,
  podQty,
  editOrderDetail
) => {
  console.log("summary-4", editOrderDetail?.subscription_id);

  function updateItems(data) {
    return data.map((entry) => {
      // Separate the items with productType 'charge'

      const newAddInvoiceItems = entry.items.filter(
        (item) => item.productType === "charge"
      );

      // Filter out the 'charge' items from the original items array
      const updatedItems = entry.items.filter(
        (item) => item.productType !== "charge"
      );

      // Return the updated entry with modified items and add_invoice_items
      return {
        ...entry,
        items: [...updatedItems, ...newAddInvoiceItems], // Update items
        // add_invoice_items: [...entry.add_invoice_items, ...newAddInvoiceItems], // Add charge items to add_invoice_items
      };
    });
  }

  const updatedData = updateItems(state.phases);
  const transform = transformData(updatedData, summary);
  const testing = removeMatchingItems(transform);
  // state.phases = transform
  const va = {
    ...state,
    phases: testing,
  };
  const data = {
    order_id: order_id,
    planPodsQty: podQty || 1,
    customer_id: state?.customer,
    subscription_scheduled_id: state.id,
    subscription_id:
      //  editOrderDetail?.subscription_id
      //   ? editOrderDetail?.subscription_id
      //   :
      state.subscription?.id ? state.subscription?.id : "",
    schedule_data: va,
    insurance_cover: state.insurance_cover,
    insurance_cost: state.insurance_price,
  };
  delete data.schedule_data.insurance_cover;
  const removeNullValuesAndKeys = (obj, keysToRemove) => {
    if (Array.isArray(obj)) {
      return obj
        .map((item) => removeNullValuesAndKeys(item, keysToRemove)) // Recursively clean arrays
        .filter((item) => item !== null); // Filter out null items
    } else if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        if (obj?.trial_end) {
          delete obj.billing_cycle_anchor;
        }

        if (obj?.object == "tax_rate") {
          const gg = obj?.id;
          return gg;
        }
        if (!keysToRemove.includes(key)) {
          // Exclude specific keys
          const value = removeNullValuesAndKeys(obj[key], keysToRemove);
          if (value !== null) {
            acc[key] = value;
          }
        }
        return acc;
      }, {});
    }
    return obj;
  };

  // Add "coupon" to the list of keys to remove
  const keysToRemove = [
    "livemode",
    "subscription",
    "id",
    "object",
    "default_settings",
    "customer",
    "current_phase",
    "status",
    "created",
    "coupon",
    "unit_price",
    "product_name",
    "is_edit",
    "productType",
    "plan",
    "canceled_at",
    "completed_at",
    "is_call_upcoming",
    "is_add_phase",
    "add_invoice_items",
    "chargebee_subscription_id",
    "trial",
    "prebilling",
    "insurance_price",
    "pending_setup_intent",
  ];

  // Clean the data
  const cleanedData = removeNullValuesAndKeys(data, keysToRemove);
  return cleanedData;
};

// Example usage

export function resetDiscounts(data) {
  if (data && data.schedule_data && data.schedule_data.phases) {
    data.schedule_data.phases.forEach((phase) => {
      phase.discounts = []; // Set discounts to an empty array
    });
  }
  return data;
}
